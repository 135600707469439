interface routesType {
  [key: string]: {
    path: string
    name: string
    redirect?: string
    paramPath?: string
    alias?: string
  }
}

export const routePaths = {
  chooseATemplate: '/choose-a-template',
  aboutLandingPage: '/gen-ai/about-landing-page',
  selectIndustry: '/gen-ai/select-industry',
  landingPageGoal: '/gen-ai/landing-page-goal',
  selectBrand: '/gen-ai/select-brand',
  designPageId: 'design/:pageId',
  designPageIdV3: 'design/:pageId/v3',
  integrateBuilder: 'integrate-builder',
  signUp: '/signup',
  verifySigninCode: '/email-code-verification',
  onboardingInformation: '/onboarding/information',
}

export const cvtRouteNodes = {
  pages: 'pages',
  setup: 'setup',
  design: 'design',
  launch: 'launch',
  preview: 'preview',
  marketplace: 'marketplace',
  settings: 'settings',
  information: 'information',
  publish: 'publish',
  smb: 'smb',
  error: 'error',
  editor: 'editor',
  editorTour: 'editor-tour',
  sitePreview: 'site-preview',
  brandConfig: 'brand-config',
  editorSetup: 'editor:setup',
  editorLaunch: 'editor:launch',
  editorDesign: 'editor:design',
  editorDesignPage: 'editor:design:page',
  editorThankyouPage: 'editor:thankyou:page',
  chooseAtemplate: 'choose:a:template',
  aboutLandingPage: 'gen-ai-about-landing-page',
  selectIndustry: 'gen-ai-select-industry',
  landingPageGoal: 'gen-ai-landing-page-goal',
  popupEditorDesign: 'popup:editor:design',
  popupEditorPublish: 'popup:editor:publish',
  popupEditorSettings: 'popup:editor:settings',
  enterpriseOnboardingCollectMoreInfo:
    'enterprise:onboarding:collect:more:info',
  enterpriseOnboardingSmb: 'enterprise:onboarding:smb',
  enterpriseOnboardingBrandingPage: 'enterprise:onboarding:branding:page',
  enterpriseOnboardingError: 'enterprise:onboarding:error',
  enterpriseOnboardingEditorTour: 'enterprise:onboarding:editor:tour',
  enterpriseIntegrateBuilder: 'enterprise:ib',
  chooseATemplatePreview: 'choose:a:template:preview',
  enterpriseIntegrateBuilderSetCustomHost: 'enterprise:ib:set:custom:host',
  enterpriseIntegrateBuilderAuthCustomer: 'enterprise:ib:auth:customers',
  enterpriseIntegrateBuilderFeatureConfig: 'enterprise:ib:feat:config',
  enterpriseIntegrateBuilderConfigDns: 'enterprise:ib:config:dns',
  enterpriseIntegrateBuilderDnsAccess: 'enterprise:ib:dns:access',
  enterpriseIntegrateBuilderVerifyHostname:
    'enterprise:ib:verify:custom:hostnames',
  enterpriseIntegrateBuilderInviteIt: 'enterprise:ib:invite:it',
  enterpriseDashboardBranding: 'enterprise:dashboard:branding',
  genAIIntent: 'gen-ai:intent',
  genAISelectTemplate: 'gen-ai:select-template',
  wizardEditor: 'wizard:editor',
  wizardEditorDesign: 'wizard:editor:design',
  wizardEditorDesignPage: 'wizard-editor:design:page',
}

export const cvtRoutes: routesType = {
  campaign: {
    path: '/',
    name: 'campaign',
    redirect: routePaths.chooseATemplate,
  },
  playground: {
    path: '/playground',
    name: 'playground',
  },
  maintenance: {
    path: '/maintenance',
    name: 'maintenance',
  },
  campaignRedirect: {
    path: '/campaign-redirect',
    name: 'campaign-redirect',
  },
  editor: {
    path: '/editor',
    name: 'editor',
    redirect: routePaths.chooseATemplate,
    paramPath: '/editor/:siteId',
  },
  design: {
    name: cvtRouteNodes.editorDesignPage,
    path: 'design/:pageId',
    alias: 'design',
  },
  sitePreview: {
    name: cvtRouteNodes.sitePreview,
    path: '/site-preview/:siteId/page/:pageId',
  },
  aboutLandingPage: {
    path: routePaths.aboutLandingPage,
    name: cvtRouteNodes.aboutLandingPage,
  },
  selectIndustry: {
    path: routePaths.selectIndustry,
    name: cvtRouteNodes.selectIndustry,
  },
  landingPageGoal: {
    path: routePaths.landingPageGoal,
    name: cvtRouteNodes.landingPageGoal,
  },
  chooseATemplate: {
    name: 'choose:a:template',
    path: routePaths.chooseATemplate,
  },
  chooseATemplatePreview: {
    name: cvtRouteNodes.chooseATemplatePreview,
    path: `${routePaths.chooseATemplate}/preview/:templateID`,
    alias: `${routePaths.chooseATemplate}/:templateID/preview`,
  },
  templates: {
    name: 'Templates',
    path: '/admin/templates',
  },
  branding: {
    name: 'Branding',
    path: '/admin/branding',
  },
  adminDashboard: {
    name: 'AdminDashboard',
    path: '/admin/dashboard',
  },
  adminConfigurations: {
    name: 'Configurations',
    path: '/admin/configurations',
  },
  adminDocumentation: {
    name: 'Documentation',
    path: '/admin/documentation',
  },
  adminReleaseNotes: {
    name: 'ReleaseNotes',
    path: '/admin/release-notes',
  },
  stripeConnect: {
    name: 'StripeConnect',
    path: '/stripe/connect',
  },
  popup: {
    name: 'popup',
    path: '/popup',
    redirect: '/popup/choose-a-template',
  },
  popupChooseATemplate: {
    name: 'popup:choose:a:template',
    path: '/popup/choose-a-template',
  },
  popupEditor: {
    name: 'popup:editor',
    path: '/popup/editor/:popupId',
  },
  enterpriseSignup: {
    name: 'enterprise:sign:up',
    path: '/signup',
  },
  enterpriseCodeVerification: {
    name: 'enterprise:code:verification',
    path: '/email-code-verification',
  },
  enterpriseOnboarding: {
    name: 'enterprise:onboarding',
    path: '/onboarding',
  },
  enterpriseDashboard: {
    name: 'enterprise:dashboard:manager',
    path: '/enterprise',
  },
  enterpriseIntegrateBuilder: {
    name: cvtRouteNodes.enterpriseIntegrateBuilder,
    path: routePaths.integrateBuilder,
    redirect: 'integrate-builder/dns-access',
  },
  enterpriseDashboardRedirect: {
    name: 'enterprise:dashboard',
    path: 'dashboard',
    redirect: 'dashboard/branding',
  },
  enterpriseChooseATemplate: {
    name: 'enterprise:choose:a:template',
    path: 'choose-a-template',
  },
  enterpriseSetCustomHost: {
    name: cvtRouteNodes.enterpriseIntegrateBuilderSetCustomHost,
    path: 'set-custom-host',
  },
  enterpriseAuthCustomers: {
    name: cvtRouteNodes.enterpriseIntegrateBuilderAuthCustomer,
    path: 'authenticate-customers',
  },
  enterpriseFeatConfig: {
    name: cvtRouteNodes.enterpriseIntegrateBuilderFeatureConfig,
    path: 'feature-config',
  },
  enterpriseDnsAccess: {
    name: cvtRouteNodes.enterpriseIntegrateBuilderDnsAccess,
    path: 'dns-access',
  },
  enterpriseDnsConfig: {
    name: cvtRouteNodes.enterpriseIntegrateBuilderConfigDns,
    path: 'config-dns',
  },
  enterpriseVerifyCustomHostname: {
    name: cvtRouteNodes.enterpriseIntegrateBuilderVerifyHostname,
    path: 'verify-custom-hostnames',
  },
  enterpriseInviteIT: {
    name: cvtRouteNodes.enterpriseIntegrateBuilderInviteIt,
    path: 'invite-it',
  },
  enterpriseDashboardBranding: {
    name: cvtRouteNodes.enterpriseDashboardBranding,
    path: 'branding',
  },
  enterpriseDashboardUsers: {
    name: 'enterprise:dashboard:users',
    path: 'users',
  },
  enterpriseDashboardConfig: {
    name: 'enterprise:dashboard:configurations',
    path: 'configurations',
  },
  enterpriseDashboardIntegrations: {
    name: 'enterprise:dashboard:integrations',
    path: 'integrations',
  },
  enterpriseDashboardDocumentation: {
    name: 'enterprise:dashboard:documentation',
    path: 'documentation',
  },
  enterpriseDashboardTemplates: {
    name: 'enterprise:dashboard:templates',
    path: 'templates',
  },
  enterpriseDashboardReleaseNotes: {
    name: 'enterprise:dashboard:release-notes',
    path: 'release-notes',
  },
  checkoutPageDesign: {
    name: 'editor:checkout',
    path: 'checkout/design',
  },
  genAIIntent: {
    name: 'gen-ai:intent',
    path: '/gen-ai/select-intent',
  },
  genAISelectTemplate: {
    name: 'gen-ai:select-template',
    path: '/gen-ai/template',
  },
  genAISelectBrand: {
    name: 'gen-ai:select-brand',
    path: routePaths.selectBrand,
  },
  wizardEditor: {
    name: cvtRouteNodes.wizardEditor,
    path: '/wizard-editor',
    redirect: routePaths.chooseATemplate,
    paramPath: '/wizard-editor/:siteId',
  },
}
