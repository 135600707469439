
<GenAILayout>
  <div class="bp-container">
    <div class="bp-header">
      <p class="bp-title">
        {{ $t('gen_ai.brand.preference.title') }}
      </p>
      <div>
        <cvt-button
          class="magic-btn"
          :text="$t('gen_ai.brand.preference.create_btn')"
          icon="plus"
          :prepend="false"
          @click="() => (modalVisible = true)"
        />
      </div>
    </div>

    <div class="bp-cards-container">
      <template v-for="brand in getBrandingData">
        <div
          :key="brand.idx"
          class="bp-card"
          :class="{ 'bp-card-selected': selectedBrandID === brand.idx }"
          @click="handleSelectBrand(brand.idx)"
        >
          <div class="bp-card-header">
            <div class="bp-card-title">
              New Kit - {{ brand.brandingName }}
            </div>
            <div class="bp-dropdown-container">
              <div
                class="bp-dropdown-trigger"
                @click="() => toggleBrandDropdown(brand.idx)"
              >
                <cvt-icon class="bp-icon-small" icon="ellipsis-v" />
              </div>
              <div
                v-if="activeDropdown === brand.idx"
                class="bp-dropdown-menu"
                @mouseleave="activeDropdown = null"
              >
                <div class="dropdown-item" @click="handleEditKit(brand)">
                  <cvt-icon icon="edit" style="margin-right: 8px" />
                  Edit Kit
                </div>
                <div class="dropdown-item" @click="handleDuplicateKit(brand)">
                  <cvt-icon icon="copy" style="margin-right: 8px" />
                  Duplicate Kit
                </div>
                <div class="dropdown-item" @click="handleDeleteKit(brand)">
                  <cvt-icon
                    icon="trash"
                    style="margin-right: 8px; color: #dc3545"
                  />
                  Delete Kit
                </div>
              </div>
            </div>
          </div>
          <div class="bp-card-content">
            <!--            <div class="bp-preview-container">-->
            <!--              <div class="bp-preview-box">-->
            <!--                <div class="bp-preview-placeholder"></div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div>
              <div class="bp-colors-container">
                <div
                  v-for="(color, idx) in Object.values(
                    brand.globalStyles.colors,
                  ).slice(0, 3)"
                  :key="idx"
                  class="bp-color-box"
                  :style="colorToStyle(color)"
                ></div>
                <div v-if="Object.keys(brand.globalStyles.colors).length > 3">
                  <div
                    :id="`tooltip-${brand.idx}`"
                    class="bp-more-colors-btn"
                  >
                    <cvt-button class="mb-2" icon="ellipsis-h" />
                  </div>

                  <cvt-tooltip
                    :show="activeColorDropdown === brand.idx"
                    :delay="20"
                    :no-fade="true"
                    :placement="'left'"
                    :color="'light'"
                    :mode="'LIGHT'"
                    :boundary-padding="0"
                    :inner-padding="0"
                    :triggers="'click'"
                    :target="`tooltip-${brand.idx}`"
                    @show="toggleColorDropdown(brand.idx)"
                    @hide="activeColorDropdown = null"
                  >
                    <div class="bp-tooltip-wrapper">
                      <div
                        v-for="(color, idx) in Object.values(
                          brand.globalStyles.colors,
                        ).slice(3)"
                        :key="idx"
                        class="bp-theme-color"
                        :style="colorToStyle(color)"
                      ></div>
                    </div>
                  </cvt-tooltip>
                </div>
              </div>

              <div class="bp-typography-section">
                <div class="bp-typography-label">HEADING</div>
                <div class="bp-typography-heading">
                  {{ brand.theme.typography.heading.font }}
                </div>
              </div>

              <div class="bp-typography-section">
                <div class="bp-typography-label">BODY</div>
                <div class="bp-typography-body">
                  {{ brand.theme.typography.normalText.font }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <hr class="bp-divider" />

    <div class="bp-footer">
      <cvt-button
        :disabled="!selectedBrandID"
        :loading="loading"
        class="magic-btn"
        :text="$t('gen_ai.intent.continue_btn')"
        :icon="'cvt-icon-arrow-right-05'"
        :prepend="false"
        @click="handleLoadContent"
      />
    </div>
  </div>

  <cvt-dialog
    :show="modalVisible"
    :hide-borders="true"
    :width="'90%'"
    @close="onCloseModal"
  >
    <div class="integrations-wrapper">
      <marketplace-branding :on-brand-preference-view="true" />
    </div>
  </cvt-dialog>

  <DeleteBrandDialog
    :delete-dialog-visible="deleteDialogVisible"
    :branding-name="selectedBrandForDeletion?.brandingName"
    @cancel="toggleDeleteDialog"
    @delete="deleteBranding"
  />
</GenAILayout>
