<template>
  <GenAILayout class="intent-selection">
    <template #nav>
      <cvt-top-nav-bar
        mode="LIGHT"
        :route="genAISelectTemplate"
        variant="embedded"
        :class="'cvt-top-nav-bar'"
        template-name="Let's get started!"
        :on-genai-wizard="true"
        :fixed="true"
        :light-logo="logo"
        :dark-logo="logo"
        :type="orgName"
        @back="navigateToChooseATemplate"
      />
    </template>

    <!--    <cvt-left-side-bar class="marketplace-left-nav-bar intent-sidebar">-->
    <div class="intent-sidebar">
      <cvt-button
        :disabled="loading"
        class="back-btn"
        text=""
        icon="cvt-icon-arrow-left-05"
        @click="goBack"
      />

      <p class="template-p">
        Based on your selection, here is the best template for you.
      </p>

      <div class="d-flex align-content-center">
        <cvt-button
          v-if="config.GEN_AI_WIZARD_SWITCH_TEMPLATE_BUTTON"
          :loading="loading"
          class="mt-4 mb-5 mr-3 magic-btn"
          text="Next Template"
          :prepend="true"
          @click="switchTemplate"
        />
        <cvt-button
          :loading="loading"
          class="mt-4 mb-5 magic-btn"
          text="Continue"
          icon="cvt-icon-arrow-right-05"
          :prepend="true"
          @click="() => useTemplate()"
        />
      </div>
    </div>
    <!--    </cvt-left-side-bar>-->
    <div class="content-wrapper position-relative">
      <div v-if="loading" class="working-on-block">
        <div class="working-on-block__container">
          <h3 class="loader-message" style="font-family: 'Nunito'">
            {{ loadingMessage }}
          </h3>
        </div>
      </div>
      <div class="w-100, h-100">
        <template v-if="currentAPIData.explainerURL">
          <div
            :class="{
              content: true,
              'd-none': !currentAPIData.explainerURL,
              mobile: viewMode !== 'desktop',
            }"
          >
            <img
              class="desktop preview-info-active"
              :src="currentAPIData.explainerURL"
              alt="ExplainerURL"
            />
          </div>
        </template>
        <template v-else>
          <div
            :class="{
              content: true,
              'd-none': currentAPIData.previewURL === '',
              mobile: viewMode !== 'desktop',
            }"
          >
            <iframe
              :key="currentAPIData.previewURL"
              :src="currentAPIData.previewURL"
              frameborder="0"
              @load="iframeLoaded"
            />
          </div>
        </template>
      </div>
    </div>
  </GenAILayout>
</template>

<script>
import genAILoadCycle from '../../../../static/img/gen-ai-load-cycle.gif'
import { cvtRouteNodes, cvtRoutes } from '../../utils/routes'
import { getGenAITemplate, getSessionItemGenAIOnboarding } from './utils'
import { isNavigationFailure, NavigationFailureType } from 'vue-router'
import { mapActions, mapGetters, mapState } from 'vuex'
import * as actions from '@/js/store/modules/actions'
import { Notification } from 'element-ui'
import { V1DynamicContent, V1GenAI, V1Sites } from '@/js/store/services/api'
import color from 'color'
import * as getters from '@/js/store/modules/getters'

import GenAILayout from './baseLayout.vue'
// import MarketplaceBranding from '../marketplace/branding/index.vue'

const dcAPI = new V1DynamicContent()
const sites = new V1Sites()
const genAIApi = new V1GenAI()

export default {
  name: 'SelectTemplate',
  components: {
    GenAILayout,
    // MarketplaceBranding,
  },
  props: {
    // Your props here
  },
  data() {
    const { genAISelectTemplate } = cvtRouteNodes
    return {
      templates: [
        'This is a template',
        'This is another template',
        'Another template here',
      ],
      mode: 'LIGHT',
      activeTemplate: 0,
      loading: false,
      genAISelectTemplate,
      currentAPIData: {
        name: '',
        pageId: '',
        siteId: '',
        siteRef: '',
        state: '',
        thumbnail: '',
        previewId: '',
        previewURL: '',
      },
      viewMode: 'desktop',
      loadingMessage: 'Please Wait',
      genAiTemplateList: [],
      usedTemplates: [],
      brandThemeArray: [
        'Convrrt',
        'Infusionsoft',
        'Integrate',
        'Instamojo',
        'SendInBlue',
      ],
      selectedBrandTheme: '',
      selectedBrandThemeData: null,
      modalVisible: false,
      brandingThemeList: null,
      showDynamicContent: false,
      intervalId: null, // Storing the interval ID for cleanup
      genAILoadCycle,
      showCycleLoader: false,
    }
  },
  computed: {
    // Your computed properties here
    ...mapGetters({
      theme: getters.AUTH_GET_USER_BUILDER_THEME,
      brandingData: getters.MP_GET_BRANDS,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) => globalTheme.OrgName,
    }),
    navColor() {
      if (this.theme.color) {
        const colorPrefix = [3, 6].includes(this.theme.color.length) ? '#' : ''
        return color(`${colorPrefix}${this.theme.color}`)
      }
      return ''
    },
    logo() {
      return this.navColor && this.navColor.dark()
        ? this.theme.logoAlt
        : this.theme.logo
    },
    selectedThemeTypography() {
      if (
        !this.selectedBrandThemeData ||
        !this.selectedBrandThemeData.theme ||
        !this.selectedBrandThemeData.theme.typography
      ) {
        return null
      }
      const { typography } = this.selectedBrandThemeData.theme
      return {
        headerStyles: {
          'font-family': typography.heading.font,
          'font-size': '20px',
          color: typography.heading.defaultTextColor,
          'font-weight': typography.heading.weight,
          'text-transform': 'capitalize',
        },
        bodyStyles: {
          'font-family': typography.normalText.font,
          'font-size': '20px',
          color: typography.normalText.defaultTextColor,
          'font-weight': typography.normalText.weight,
          'text-transform': 'capitalize',
        },
      }
    },
  },
  watch: {
    brandingData: {
      handler(newBrandingData) {
        this.brandingThemeList = newBrandingData
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.initBrandManager()
    this.initialContentLoading()
    this.genAiTemplateList = []

    this.intervalId = setInterval(this.toggleHelperBoxText, 4000)
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    ...mapActions({
      mixpanelTrack: actions.MIXPANEL_TRACK,
      initBrandManager: actions.MP_INIT_BRAND_MANAGER,
    }),
    goBack() {
      this.$router.push(cvtRoutes.genAIIntent.path)
    },
    startLoading(value, message = 'Please Wait...') {
      const cycleLoaderAllowed = [
        'Regenerating Template...',
        'Rendering Template...',
      ]
      if (cycleLoaderAllowed.includes(message)) {
        this.showCycleLoader = value
      } else {
        this.showCycleLoader = false
      }

      this.loading = value
      this.loadingMessage = message
    },
    async switchTemplate() {
      try {
        this.startLoading(true, 'Regenerating Template...')

        if (!this.genAiTemplateList.length) {
          const selectedIntentGoal = getSessionItemGenAIOnboarding('intentGoal')
          const selectedIntent = getSessionItemGenAIOnboarding('intent')

          const params = {
            intent: [selectedIntent],
            intentGoal: [selectedIntentGoal],
          }
          this.genAiTemplateList = await genAIApi.genAiTemplates(params)
          this.usedTemplates = []
        }

        if (this.genAiTemplateList.length) {
          let availableTemplates = this.genAiTemplateList.filter(
            (template) => !this.usedTemplates.includes(template.pageId),
          )
          if (!availableTemplates.length) {
            // If all templates have been used, reset the usedTemplates list
            this.usedTemplates = []
            availableTemplates = [...this.genAiTemplateList]
          }

          let selectedTemplate =
            availableTemplates[
              Math.floor(Math.random() * availableTemplates.length)
            ]
          while (
            selectedTemplate.pageId === this.currentAPIData.pageId &&
            availableTemplates.length > 1
          ) {
            selectedTemplate =
              availableTemplates[
                Math.floor(Math.random() * availableTemplates.length)
              ]
          }

          this.usedTemplates.push(selectedTemplate.pageId)
          // const previewURL = `${window.location.origin}/api/v1/sites/${selectedTemplate.siteId}/preview/${selectedTemplate.pageId}`
          const previewURL = this.getPreviewUrl(
            selectedTemplate.pageId,
            selectedTemplate.siteId,
          )
          if (selectedTemplate.pageId === this.currentAPIData.pageId) {
            return this.startLoading(false)
          }

          // Update the currentAPIData
          this.currentAPIData = Object.assign(
            {},
            this.currentAPIData,
            selectedTemplate,
            {
              previewURL,
              explainerURL: selectedTemplate.imageUrl,
              previewId: selectedTemplate.siteKey,
            },
          )
          this.addBrandingToPreviewUrl(true)
        } else {
          this.startLoading(false)
          return Notification({
            title: 'No Template',
            message: 'No templates found for that intent',
            type: 'info',
            position: 'top-right',
          })
        }
      } catch (err) {
        this.startLoading(false)
        throw err
      }
    },
    async onRegenerate() {
      this.showCycleLoader = true
      await this.switchTemplate()
      this.showCycleLoader = true
    },
    iframeLoaded() {
      this.checkIframeLoaded()
    },
    checkIframeLoaded() {
      const iframe = this.$el.querySelector('iframe')
      if (
        iframe &&
        iframe.contentDocument &&
        iframe.contentDocument.readyState === 'complete'
      ) {
        this.startLoading(false)
      } else {
        setTimeout(() => {
          this.checkIframeLoaded()
        }, 2000)
      }
    },
    async createSiteFromPreviewID(previewId) {
      const req = dcAPI.selectTemplate({ previewId: previewId })
      // const { data } = resp
      // const { id } = data
      return await req.catch((err) => {
        console.error('failed to create site', err)
        const { response = {} } = err
        const { data } = response
        if (data && data.errors) {
          for (const e of data.errors) {
            Notification({
              title: e.code || 'woops',
              message: e.message,
              type: 'error',
              position: 'bottom-right',
            })
          }
        }
        throw err
      })
    },
    switchViewMode(currentSelected) {
      if (!this.currentAPIData.previewURL) return
      this.viewMode = currentSelected
      this.startLoading(true, 'Switching View Mode...')
      if (currentSelected === 'desktop') {
        this.currentAPIData = {
          ...this.currentAPIData,
          previewURL: dcAPI.getPreviewURLAlt(this.currentAPIData.previewURL),
        }
        this.startLoading(false)
      } else {
        this.currentAPIData = {
          ...this.currentAPIData,
          previewURL: dcAPI.getPreviewURLAlt(
            this.currentAPIData.previewURL,
            true,
          ),
        }
      }
    },
    printError(message) {
      Notification({
        title: 'woops',
        message: message,
        type: 'error',
        position: 'bottom-right',
      })
    },
    async useTemplate() {
      const selectedIntentGoal = getSessionItemGenAIOnboarding('intentGoal')
      const selectedIntent = getSessionItemGenAIOnboarding('intent')
      const description = getSessionItemGenAIOnboarding('businessDescription')
      const industry = getSessionItemGenAIOnboarding('industry')
      const brandingId = getSessionItemGenAIOnboarding('brandingId') || ""

      const params = {
        businessDescription: description,
        businessSummary: industry,
        intent: selectedIntent,
        intentGoal: selectedIntentGoal,
        brandingId,
      }

      if (this.currentAPIData.previewId !== '' && !this.loading) {
        this.startLoading(true, 'Switching to Content Builder...')

        try {
          const siteResp = await sites.fromGenAiTemplate(
            this.currentAPIData.siteId,
            params,
          )

          // Find the page with isIndex set to true
          // const indexPage = siteResp.pages.find((page) => page.isIndex)
          // const pageRef = indexPage ? indexPage.firebaseRef : null

          // await sites.generateSiteContent({
          //   intent: selectedIntent,
          //   intentGoal: selectedIntentGoal,
          //   pageRef: pageRef,
          // })
          // Move to the wizard-editor
          this.$router.push({
            name: cvtRouteNodes.wizardEditorDesign,
            params: {
              siteId: siteResp.id,
            },
          })
          this.mixpanelTrack({
            event: 'Generative AI Template',
            category: 'gen-ai-templates',
            data: { template_id: siteResp.id },
          })
          this.startLoading(false)
        } catch (e) {
          this.startLoading(false)
          if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
            throw e
          }
        }
      } else {
        if (this.loading) {
          return Notification({
            title: 'In Progress',
            message: 'We are processing the template.. Please Wait.',
            type: 'info',
            position: 'bottom-right',
          })
        }
        this.printError('Onboarding did not follow due process')
      }
    },
    async initialContentLoading() {
      const template = getGenAITemplate()
      if (template) {
        this.startLoading(true, 'Rendering Template...')
        const templatesArray = JSON.parse(atob(template))
        if (templatesArray.length) {
          const firstTemplate = templatesArray[0]
          const previewURL = this.getPreviewUrl(
            firstTemplate.pageId,
            firstTemplate.siteId,
          )
          this.currentAPIData = {
            ...firstTemplate,
            previewURL,
            previewId: firstTemplate.siteKey,
          }
          this.usedTemplates.push(firstTemplate.pageId)
          return
        } else {
          await this.onRegenerate()
          // this.startLoading(false)
          // return Notification({
          //   title: 'No Template',
          //   message: 'No templates found for that intent',
          //   type: 'info',
          //   position: 'top-right',
          // })
        }
      }
      this.printError('Onboarding did not follow due process')
    },
    navigateToChooseATemplate() {
      this.$router.push({
        name: cvtRouteNodes.chooseAtemplate,
      })
    },
    onSelectBrandTheme(value) {
      this.selectedBrandTheme = value
      if (this.selectedBrandTheme === '+ Create new theme') {
        return (this.modalVisible = true)
      }

      this.addBrandingToPreviewUrl()
      this.selectedBrandThemeData = this.brandingThemeList.find(
        (theme) => theme.idx === this.selectedBrandTheme,
      )
    },
    async onCloseModal() {
      await this.initBrandManager()
      this.modalVisible = false
    },
    colorToStyle(color) {
      return {
        backgroundColor: color.hex,
      }
    },
    toggleHelperBoxText() {
      this.showDynamicContent = !this.showDynamicContent
    },
    addBrandingToPreviewUrl(isRegenerate = false) {
      if (!this.selectedBrandTheme) return
      let url = new URL(this.currentAPIData.previewURL)
      url.searchParams.delete('brandingId')
      url.searchParams.append('brandingId', this.selectedBrandTheme)
      this.currentAPIData.previewURL = url.toString()

      !isRegenerate && this.startLoading(true, 'Applying Theme...')
    },
    getPreviewUrl(templateId, siteId) {
      return `${window.location.origin}/api/v1/marketplace/gen-ai/templates/${siteId}/pages/${templateId}/preview`
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/IntentSelection.style.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.select-template-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  width: 280px;
  margin-top: 200px;
  margin-bottom: 20px;
  color: #494c53;
  margin-left: -7px;

  .select-template-footer-box {
    background-color: #fff;
    border-radius: 16px;
    height: 200px;
    line-height: normal;
  }

  .select-template-footer-box > div {
    margin: 24px 20px;
  }

  .fade-text {
    animation-duration: 6s;
    animation-fill-mode: both;
  }

  .fade-in {
    animation-name: fadeIn;
  }

  .fade-out {
    animation-name: fadeOut;
  }
}
.template-p {
  font-size: 29px;
  margin: 0;
  font-weight: 400;
  padding: 2rem 2rem 2rem 10px;
}

</style>
