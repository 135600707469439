<template>
  <div class="dynamic-image" @mouseenter.stop="showOverlay" @mouseleave.stop="hideOverlay" @click="click">
    <fade-in-out :duration="250">
      <div v-show="overlayVisible" class="dynamic-image__overlay flex-column justify-content-center align-items-center">
        <i v-show="loading" class="fa fa-spinner loading-icon"></i>
        <small :style="msgStyle" v-text="overlayMsg"></small>
      </div>
    </fade-in-out>
      <img v-show="!failed" class="img-fluid" :src="src" :alt="alt" @load="endLoader" @error="failLoading">
  </div>
</template>

<script>
  import anime from 'animejs'
  import FadeInOut from '../../common/transitions/FadeInOut.vue'

  export default {
    components: {
      FadeInOut
    },
    props: {
      hoverMsg: {
        type: String,
        default: 'Click to use this image'
      },
      src: {
        type: String,
        required: true
      },
      timeout: {
        type: Number,
        default: 5000
      },
      alt: {
        type: String,
        default() {
          return ''
        }
      }
    },

    data () {
      return {
        loading: false,
        hovered: false,
        failed: false
      }
    },

    computed: {
      overlayVisible () {
        return this.failed || this.hovered || this.loading
      },
      overlayMsg () {
        if (this.failed) {
          return 'We couldnt load this image'
        }
        return this.loading ? 'Loading...' : this.hoverMsg
      },
      msgStyle () {
        return {
          color: this.failed ? 'red' : null
        }
      }
    },
    mounted () {
      this.loaderAnimation = anime({
        targets: this.$el.querySelector('.loading-icon'),
        rotate: [0, 360],
        loop: true,
        easing: 'linear',
        duration: 1000,
        autoplay: false
      })
      this.startLoader()
    },

    methods: {
      showOverlay () {
        this.hovered = true
      },

      hideOverlay () {
        this.hovered = false
      },

      startLoader () {
        this.loading = true
        this.loaderAnimation.play()
        // setTimeout(this.failLoading, this.timeout)
      },

      endLoader (e) {
        this.loading = false
        this.loaderAnimation.pause()
      },

      failLoading () {
        this.failed = true
        this.endLoader()
      },

      click () {
        this.$emit('click')
      }
    }
  }
</script>

<style lang="scss">
  .dynamic-image {
    position: relative;
    min-height: 150px;
    transition: all 150ms ease;
    transform: scale(1);
    user-select: none;

    &:active {
      transform: scale(0.9);
    }
  }

  .dynamic-image__overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: absolute;
    background: rgba(0, 0, 0, 0.73);
    cursor: pointer;
    display: flex;
  }

  .loading-icon {
    font-size: 25px;
    margin-bottom: 0.4rem;
  }

</style>
