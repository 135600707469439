<template>
  <div v-if="showMigration" class="status-container">
    <div class="backdrop"></div>
    <div class="status-box">
      <h3>Applying Data Migration</h3>
      <hr class="divider" />
      <div class="loading-indicator">
        <div class="spinner"></div>
        {{ status }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as getters from "@/js/store/modules/getters";
import * as actions from "@/js/store/modules/actions";
import { database } from "@/js/store/services/firebase.service";
import { V1PopupSite, V1Sites } from "@/js/store/services/api";

const db = database()

const siteAPI = new V1Sites()
const popupAPI = new V1PopupSite()

export default {
  data() {
    return {
      status: 'Initializing...',
      showMigration: false,
      modelStatusRef: null,
    }
  },
  computed: {
    ...mapGetters({
      siteData: getters.SITE_GET_DATA,
      popupData: getters.SDA_POPUP_SITE_DATA,
      config: getters.AUTH_GET_USER_CONFIG,
      currentProductVersion: getters.CURRENT_PRODUCT_VERSION_NUMBER,
      isGridBuilder: getters.IS_GRID_BUILDER,
      vdomReady: getters.DELTA_VDOM_READY,
    }),
    hasSiteData() {
      return this.siteData && this.siteData.id
    },
  },
  watch: {
    vdomReady(newVal) {
      if (newVal) {
        this.checkForMigration()
      }
    },
  },
  mounted() {
    console.debug('Migration Initialised')
  },
  destroyed() {
    if (this.modelStatusRef) {
      this.modelStatusRef.off('value')
    }
  },
  methods: {
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop',
    }),
    async reloadVDom() {
      await this.startLoader('Refreshing Builder...')
      if (this.popupData) {
        this.$store
          .dispatch(actions.POPUP_FETCH_DATA, this.$route.params.popupId)
          .then((_) => {
            // this.stopLoader('Builder is Ready')
            window.location.reload()
          })
      } else {
        this.$store
          .dispatch(actions.SITE_FETCH_DATA, this.$route.params.siteId)
          .then((_) => {
            window.location.reload()
            // this.stopLoader('Builder is Ready')
          })
      }
    },
    checkForMigration() {
      if (!this.hasSiteData) {
        this.showMigration = false
        return
      }

      this.initMigration()
    },
    async initMigration() {
      if (!this.config.MIGRATION_ENABLED) {
        return
      }
      const { PRODUCT_VERSION_NUMBER: productVersion } = this.config
      const userProductVersion = parseFloat(productVersion)

      if (this.popupData) {
        await this.initPopupMigration(userProductVersion)
      } else {
        await this.initSiteMigration(userProductVersion)
      }
    },
    async initSiteMigration(userProductVersion) {
      if (this.isGridBuilder) {
        if (this.currentProductVersion < userProductVersion) {
          this.showMigration = true
          const { completed, failed = false } = this.setDefaultStatus(
            this.siteData.firebaseRef,
          )

          if (completed || failed) {
            this.showMigration = false
            return
          }

          this.waitForMigrationStatus(this.siteData.firebaseRef)
          await siteAPI.runDataStructureMigration({
            modelRef: this.siteData.firebaseRef,
            fromVersion: this.currentProductVersion,
            toVersion: userProductVersion,
            siteId: this.siteData.id,
          })
        }
      }
    },
    async initPopupMigration(userProductVersion) {
      if (this.isGridBuilder) {
        if (this.currentProductVersion < userProductVersion) {
          this.showMigration = true
          const { completed, failed = false } = this.setDefaultStatus(
            this.popupData.firebaseRef,
          )

          if (completed || failed) {
            this.showMigration = false
            return
          }

          this.waitForMigrationStatus(this.popupData.firebaseRef)
          await popupAPI.runDataStructureMigration({
            modelRef: this.popupData.firebaseRef,
            fromVersion: this.currentProductVersion,
            toVersion: userProductVersion,
            popupId: this.popupData.id,
          })
        }
      }
    },
    async setDefaultStatus(firebaseRef) {
      const modelStatusRef = db.ref(firebaseRef).child('migrationStatus')

      const value = await modelStatusRef.once('value')
      if (!value.exists()) {
        return {
          log: [
            {
              message: 'Initializing...',
            },
          ],
          completed: false,
        }
      }
      return value.val()
    },
    waitForMigrationStatus(firebaseRef) {
      this.modelStatusRef = db.ref(firebaseRef).child('migrationStatus')
      this.$store.dispatch(actions.DELTA_IGNORE)

      this.modelStatusRef.on('value', (snap) => {
        if (snap.exists()) {
          const { completed, log } = snap.val()
          const lastLog = Object.values(log).pop()
          this.status = lastLog.message

          if (completed) {
            this.modelStatusRef.off('value')
            setTimeout(() => {
              this.showMigration = false
              this.modelStatusRef.remove()
              this.reloadVDom()
            }, 4000)
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);

  width: 100%;
  height: 100%;
  z-index: 1000;
}
.status-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.status-box {
  width: 400px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
  position: relative;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
