import { database } from '@/js/store/services/firebase.service'
import * as actions from '@/js/store/modules/actions'
import * as getters from '@/js/store/modules/getters'
import * as mutations from '@/js/store/modules/mutations'
import { FroalaFormat } from '@/js/lib/font'
import { DEFAULT_COLOR, rgbaToColor } from '@/js/lib/color-utils'
import * as _ from 'lodash'
import color from 'color'
import { Notification } from 'element-ui'
import { DEFAULT_TYPOGRAPHY } from '@/js/store/services/theme-management/v2'
import { generateGlobalStyleColors } from '@/js/store/services/theme-management/v2/color-generator'
let db = database()

const defaultState = {
  brandingName: 'New Brand',
  // for Vue components that need to access the colors
  computedColors: {},
  faviconUrl: '',
  brandLogo: null,
  typography: DEFAULT_TYPOGRAPHY, // TODO: change to themeManager DefaultTypography
  defaultColor: DEFAULT_COLOR,

  dumpObj: {
    // for firebase
    fbNodeColors: generateGlobalStyleColors(12),
  },
}

export default {
  state: {
    ...defaultState,
    idx: null,
    rootRef: null,
  },
  getters: {
    [getters.MP_GET_THEME_COLORS]({ computedColors }) {
      return computedColors
    },
    [getters.MP_GET_FONT_STYLES]({ typography }) {
      return [
        { style: 'title', label: 'Title', tag: 'h1' },
        { style: 'subtitle', label: 'Subtitle', tag: 'h2' },
        { style: 'heading', label: 'Heading', tag: 'h3' },
        {
          style: 'alternativeHeading',
          label: 'Alternative Heading',
          tag: 'h4',
        },
        { style: 'lead', label: 'Lead', tag: 'h5' },
        { style: 'normalText', label: 'Normal Text', tag: 'p' },
        { style: 'blockquote', label: 'Blockquote', tag: 'blockquote' },
      ].map((s) => {
        return {
          ...s,
          format: typography[s.style],
        }
      })
    },
    [getters.MP_GET_DEFAULT_COLOR]({ defaultColor }) {
      return defaultColor
    },
  },
  mutations: {
    [mutations.MP_RESET_STATE](state) {
      Object.assign(state, {
        ...defaultState,
        idx: null,
        typography: DEFAULT_TYPOGRAPHY,
        defaultColor: DEFAULT_COLOR,
      })
      this.commit(mutations.MP_UPDATE_THEME)
    },
    [mutations.MP_SET_BRANDING_DATA](state, data) {
      Object.assign(state, {
        ...defaultState,
        brandingName: data.brandingName,
        faviconUrl: data.faviconUrl,
        brandLogo: data.brandLogo || null,
        idx: data.idx,
        typography: _.mapValues(data.theme.typography, (v, k) =>
          FroalaFormat.fromStyle(k, v),
        ),
        defaultColor: DEFAULT_COLOR,
        dumpObj: {
          fbNodeColors: data.theme.colors,
        },
      })

      this.commit(mutations.MP_UPDATE_THEME)
    },
    [mutations.MP_UPDATE_THEME](state) {
      state.computedColors = state.dumpObj.fbNodeColors
        ? _.mapValues(state.dumpObj.fbNodeColors, (c) => {
            return color(c.hex)
          })
        : {}
    },
    [mutations.MP_SET_IDX](state, idx) {
      state.idx = idx
    },
    [mutations.MP_SET_BRAND_LOGO](state, logo) {
      state.brandLogo = logo
    },
    [mutations.MP_SET_FAVICON_URL](state, url) {
      state.faviconUrl = url
    },
    [mutations.MP_SET_TYPOGRAPHY](state, { key, value }) {
      if (state.typography) {
        state.typography[key] = value
      } else {
        state.typography = { [key]: value }
      }
    },
    [mutations.MP_SET_BRANDING_NAME](state, name) {
      state.brandingName = name
    },
  },
  actions: {
    async [actions.MP_INIT_CREATE_MANAGER](
      { state, commit, dispatch, rootGetters },
      brandData,
    ) {
      const { orgID, projectID } = rootGetters[getters.AUTH_GET_USER]
      const { MARKETPLACE_PATH } = rootGetters[getters.AUTH_GET_USER_CONFIG]

      state.rootRef = db.ref(MARKETPLACE_PATH).child(orgID).child(projectID)

      if (brandData) {
        commit(mutations.MP_SET_BRANDING_DATA, {
          brandingName: brandData.brandingName,
          faviconUrl: brandData.preferences?.favicon || brandData.faviconUrl,
          brandLogo: brandData.brandLogo,
          theme: {
            colors:
              brandData.globalStyles?.colors || generateGlobalStyleColors(12),
            typography: brandData.theme?.typography,
            buttonTypography: brandData.theme?.buttonTypography,
            froalaLinkTypography: brandData.theme?.froalaLinkTypography,
          },
          idx: brandData.idx,
        })
      } else {
        commit(mutations.MP_RESET_STATE)
      }
    },
    async [actions.MP_ADD_COLOR]({ state, commit, dispatch }, { color }) {
      const colorKey = state.rootRef.push().key

      state.dumpObj.fbNodeColors[colorKey] = {
        a: color.alpha(),
        hex: color.hex().toLowerCase(),
      }
      commit(mutations.MP_UPDATE_THEME)
      return colorKey
    },
    [actions.MP_REMOVE_COLOR]({ state, commit }, color) {
      delete state.dumpObj.fbNodeColors[color]
      commit(mutations.MP_UPDATE_THEME)
    },
    [actions.MP_UPDATE_COLOR]({ state, commit }, { key, color }) {
      state.dumpObj.fbNodeColors[key] = {
        a: color.alpha(),
        hex: color.hex().toLowerCase(),
      }
      commit(mutations.MP_UPDATE_THEME)
      return key
    },
    async [actions.MP_SAVE_BRANDING_DATA_TO_FIREBASE]({ state, commit }) {
      const brandingRef = state.rootRef.child('branding')

      const requiredFields = {
        brandingName: state.brandingName,
        faviconUrl: state.faviconUrl,
        colors:
          state.dumpObj.fbNodeColors &&
          Object.keys(state.dumpObj.fbNodeColors).length > 0,
      }

      const missingFields = Object.entries(requiredFields)
        .filter(([_, value]) => !value)
        .map(([key]) => key)

      if (missingFields.length > 0) {
        Notification.warning({
          title: 'Woops',
          message: `Missing required fields: ${missingFields.join(', ')}`,
          position: 'top-right',
        })
        return
      }

      // Remove undefined properties from typography directly
      Object.keys(state.typography).forEach((key) => {
        if (state.typography[key] === undefined) {
          delete state.typography[key]
        }
      })

      const brandingObject = {
        brandingName: state.brandingName,
        brandLogo: state.brandLogo,
        preferences: {
          favicon: state.faviconUrl,
        },
        theme: {
          typography: state.typography,
        },
        globalStyles: {
          colors: state.dumpObj.fbNodeColors,
        },
      }
      if (state.buttonTypography) {
        brandingObject.theme.buttonTypography = state.buttonTypography
      }
      if (state.froalaLinkTypography) {
        brandingObject.theme.froalaLinkTypography = state.froalaLinkTypography
      }

      try {
        if (state.idx) {
          await brandingRef.child(state.idx).update(brandingObject)
        } else {
          const newRef = await brandingRef.push(brandingObject)
          commit(mutations.MP_SET_IDX, newRef.key)
        }

        commit(mutations.MP_RESET_STATE)
        return true
      } catch (error) {
        console.error('Error saving branding data:', error)
        return false
      }
    },
  },
}
