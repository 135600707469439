export const SDA_POPUP_BUILDER_INSERT_BLOCK = 'sda:popup:builder:insert:block'
export const ACTIVATE_STANDALONE_BUILDER = 'sda:popup:builder:activate'
export const SDA_POPUP_FIREBASE_INIT = 'sda:popup:firebase:init'
export const SDA_POPUP_PROPS_REFRESH = 'sda:popup:props:refresh'
export const POPUP_PROPS_REFRESH = 'popup:props:refresh'
export const SDA_POPUP_FIREBASE_UPDATE_PROPERTIES =
  'sda:popup:firebase:update:properties'
export const SDA_POPUP_FIREBASE_GET_PROPERTY = 'sda:popup:firebase:get:property'
export const ADVANCED_MENU_TOGGLE = 'advanced:menu:toggle'
export const ANCHORS_INIT = 'anchors:init'
export const ANCHORS_UPDATE_BY_ID = 'anchors:update:by:id'
export const ADD_NEW_ANCHORS = 'anchors:add:new:anchors'
export const ASSETS_MANAGER_SEARCH_CRM_FILES = 'asset:manager:search:crm:files'
export const ASSET_MANAGER_CONFIRM_SELECTION = 'asset:manager:confirm:selection'
export const ASSET_MANAGER_INIT = 'asset:manager:init'
export const ASSET_MANAGER_TOGGLE = 'asset:manager:toggle'
export const AUTH_FIREBASE_LOGIN = 'auth:firebase:login'
export const AUTH_FIREBASE_WATCH_AUTH = 'auth:firebase:watch:auth'
export const AUTH_HEARTBEAT = 'auth:heartbeat'
export const AUTH_LOAD_USER = 'auth:load:user'
export const AVATAR_ORG_IMG = 'avatar:org:img'
export const CHANGE_VIEW_MODE = 'viewmode:change'
export const COMMAND_APPLY = 'command:apply'
export const COMMAND_CHANGE_VIEW_MODE = 'command:viewmode:change'
export const HISTORY_COMMAND_PUSH = 'history:command:push'
export const HISTORY_COMMAND_REDO = 'history:command:redo'
export const HISTORY_COMMAND_UNDO = 'history:command:undo'
export const CUSTOM_DOMAIN_CONNECT = 'custom:domain:connect'
export const CUSTOM_DOMAIN_DISCONNECT = 'custom:domains:disconnect'
export const CUSTOM_DOMAIN_LOAD_DOMAINS = 'custom:domains:load:domains'
export const DELTA_DELETE = 'delta:delete'
export const DELTA_IGNORE = 'delta:ignore'
export const DELTA_INIT = 'delta:init'
export const DELTA_INIT_STANDALONE_POPUP = 'delta:init:standalone:popup'
export const DELTA_INSERT = 'delta:insert'
export const DELTA_LISTEN = 'delta:listen'
export const DELTA_MANUAL_CHANGES_PUSH = 'delta:manual:change-push'
export const DELTA_RESET = 'delta:reset'
export const DELTA_SET_CURRENT_REV = 'delta:set:current:rev'
export const DROPZONE_ACTIVATE = 'dropzone:activate'
export const ELEMENT_LAUNCHER_INSERT = 'element:launcher:insert'
export const FORM_EDITOR_LOAD_FIELDS = 'form:editor:show'
export const FORM_EDITOR_UPDATE_FIELD = 'form:editor:update:field'
export const HISTORY_APPLY = 'history:apply'
export const HISTORY_GRIDBUILDER_POPUP = 'history:set:popup'
export const HISTORY_SET_INITIAL_REV = 'history:set:initial:rev'
export const HISTORY_SET_POPUP_MANAGER = 'history:set:popup:undo-redo:manger'
export const ICON_PICKER_SELECT = 'icon:picker:select'
export const IMAGE_SEARCH_QUERY = 'image:search:query'
export const IMAGE_SEARCH_SELECTED = 'image:search:selected'
export const INTERCOM_BOOT = 'intercom:boot'
export const INTERCOM_INIT_FROM_CONFIG = 'intercom:init:from-config'
export const INTERCOM_HIDE = 'intercom:hide'
export const INTERCOM_SHOW = 'intercom:show'
export const INTERCOM_TOGGLE = 'intercom:toggle'
export const INTERCOM_TRACK = 'intercom:track'
export const LAYOUT_INIT = 'layout:init'
export const LAYOUT_REFRESH = 'layout:refresh'
export const LEFT_SIDE_MENU_TOGGLE = 'left:side:menu:toggle'
export const MARKETPLACE_CREATE_SITE_FROM_TEMPLATE =
  'marketplace:create:site:from:template'
export const MARKETPLACE_LOAD_DOMAINS = 'marketplace:load:domains'
export const MARKETPLACE_LOAD_TEMPLATES = 'marketplace:load:templates'
export const MIXPANEL_BOOT = 'mixpanel:boot'
export const MIXPANEL_INIT_USER = 'mixpanel:init:user'
export const MIXPANEL_PEOPLE_SET_ONCE = 'mixpanel:people:set:once'
export const MIXPANEL_TRACK = 'mixpanel:track'
export const OPEN_GRAPH_INIT = 'open:graph:init'
export const OPEN_GRAPH_SAVE = 'open:graph:save'
export const PAGE_PREVIEW_TOGGLE = 'page:preview:toggle'
export const POPUP_BUILDER_INSERT_BLOCK = 'popup:insert:block'
export const POPUP_BUILDER_PUSH_NEW_BLOCK = 'popup:push:new:block'
export const POPUP_BUILDER_TOGGLE = 'popup:toggle'
export const POPUP_BUILDER_UNDO_REDO_MANAGER = 'popup:undo_redo:manager'
export const POPUP_BUILDER_DELETE_POPUP_DATA = 'popup:builder:delete:popup_data'
export const POPUP_BUILDER_UPDATE_CLOSE_BTN_POSITION =
  'popup:update:close:btn:position'
export const POPUP_BUILDER_UPDATE_WIDTH = 'popup:update:width'
export const POPUP_DELETE_PREMADE = 'popup:delete:premade'
export const POPUP_GET_CLOSE_BTN_POSITION = 'popup:get:close:btn:position'
export const POPUP_GET_LIST = 'popup:get:list'
export const POPUP_GET_PROPERTY = 'popup:get:property'
export const POPUP_GET_WIDTH = 'popup:get:width'
export const POPUP_MARKETPLACE_LOAD_TEMPLATES =
  'popup:marketplace:load:templates'
export const POPUP_MARKETPLACE_CREATE_SITE_FROM_TEMPLATE =
  'popup:marketplace:create:site:from:template'
export const POPUP_SET_PATH = 'popup:set:path'
export const POPUP_UPDATE_PROPERTY = 'popup:update:property'
export const SDA_POPUP_SITE_CLONE = 'sda:popup:site:clone'
export const SDA_POPUP_SITE_ARCHIVE = 'sda:popup:site:archive'
export const SDA_POPUP_SITE_RESTORE = 'sda:popup:site:restore'
export const SDA_POPUP_SITE_ENABLE_MARKETPLACE =
  'sda:popup:site:enable:marketplace'
export const SDA_POPUP_CONNECT_URL = 'sda:popup:connect-url'
export const SDA_POPUP_SITE_UPDATE_CATEGORIES =
  'sda:popup:site:update:categories'
export const QUICK_LAUNCH_BLOCK_INSERT = 'quick:launch:block:insert'
export const RIGHT_CLICK_MENU_RESET = 'right:click:menu:reset'
export const RIGHT_CLICK_MENU_SHOW = 'right:click:menu:show'
export const SAVE_BLOCK_CANCEL = 'save:block:cancel'
export const SAVE_BLOCK_CONFIRM = 'save:block:confirm'
export const BOOKMARK_BLOCK_CONFIRM = 'bookmark:block:confirm'
export const PAGES_BOOKMARK_BLOCK_CONFIRM = 'page:bookmark:block:confirm'
export const SAVE_POPUP_BLOCK_CONFIRM = 'save:popupblock:confirm'
export const SWAP_BLOCK_SHOW_DIALOG = 'swap:block:set:show:dialog'
export const SWAP_BLOCK_HIDE_DIALOG = 'swap:block:set:hide:dialog'
export const SWAP_BLOCK = 'swap:block'
export const SWAP_BLOCK_SET_CURRENT_BLOCK_DATA =
  'swap:block:set:current:block:data'
export const REARRANGING_BLOCK = 'block:action:rearranging-block'
export const SWAP_BLOCK_SET_PAGE_ENGINE = 'swap:block:set:page:engine'
export const SELECTOR_DEBOUNCED_SELECT = 'selector:debounced:select'
export const SELECTOR_DEBOUNCED_TARGET = 'selector:debounced:target'
export const SELECTOR_DEBOUNCED_TARGET_CANCEL =
  'selector:debounced:target:cancel'
export const SELECTOR_DESELECT = 'selector:deselect'
export const SELECTOR_REMOVE_SELECTED = 'selector:remove:selected'
export const SELECTOR_SELECT = 'selector:select'
export const SELECTOR_TARGET = 'selector:target'
export const SELECTOR_UPDATE_PARENT_PROP = 'selector:update:parent:prop'
export const SELECTOR_UPDATE_PROP = 'selector:update:prop'
export const SELECTOR_UPDATE_PROPS = 'selector:update:props'
export const SELECTOR_UPDATE_SELECTED = 'selector:update:selected'
export const SELECTOR_UPDATE_THEME_COLOR = 'selector:update:theme:color'
export const SET_LAYOUT_CHILDREN = 'set:layout:children'
export const SITES_FETCH_DATA = 'sites:fetch:data'
export const SITE_ARCHIVE = 'site:archive'
export const SITE_CAMPAIGN_REDIRECT = 'site:campaign:redirect'
export const SITE_CHECKOUT = 'SITE_CHECKOUT'
export const SITE_CHECKOUT_SAVE = 'SITE_CHECKOUT_SAVE'
export const SITE_CRM_REDIRECT = 'site:crm:redirect'
export const SITE_UPDATE_PROPERTIES = 'site:update:properties'
export const SITE_FETCH_DATA = 'site:fetch:data'
export const POPUP_FETCH_DATA = 'popup:fetch:data'
export const SITE_INIT_PULSE_PREFERENCE = 'site:init:pulse:preference'
export const SITE_JS_TRACKING = 'SITE_JS_TRACKING'
export const SITE_JS_TRACKING_SAVE = 'SITE_JS_TRACKING_SAVE'
export const SITE_LOAD_ANALYTICS = 'site:load:analytics'
export const SITE_PUBLISH = 'site:publish'
export const SDA_POPUP_PUBLISH = 'sda:popup:publish'
export const SDA_POPUP_UPDATE_THUMBNAIL = 'sda:popup:update:thumbnail'
export const SDA_POPUP_UPDATE_PROPERTIES = 'sda:popup:update:properties'
export const SITE_REGENERATE_FORMS = 'site:regenerate:forms'
export const SITE_RESTORE = 'site:restore'
export const SITE_SYNC_READY_STATE = 'site:sync:ready:state'
export const SITE_TOGGLE_READY_STATE = 'site:toggle:ready:state'
export const SITE_TRACK_OLD_PAGE = 'site:track:old:page'
export const SITE_UPDATE = 'site:update'
export const POPUP_UPDATE = 'popup:update'
export const SITE_UPDATE_CATEGORIES = 'site:update:categories'
export const SITE_UPDATE_THUMBNAIL = 'site:update:thumbnail'
export const STEPS_BACK = 'steps:back'
export const STEPS_INIT = 'steps:init'
export const STEPS_MARK = 'steps:mark'
export const STEPS_NEXT = 'steps:next'
export const SUPER_QUERY_APPS = 'super:query:apps'
export const SUPER_SUDO_AS = 'super:sudo:as'
export const SURVEY_TOGGLE = 'survey:toggle'
export const TEMPLATE_FETCH_DATA = 'template:fetch:data'
export const THEME_ADD_COLORS_FROM_BLOCK = 'theme:add:colors:from:block'
export const THEME_ADD_NEW_COLOR = 'theme:add:new:color'
export const THEME_ADD_NEW_COLORS = 'theme:add:new:colors'
export const THEME_INIT = 'theme:init'
export const THEME_REMOVE_COLOR = 'theme:remove:color'
export const THEME_SET_BRAND_LOGO = 'theme:set:brand:logo'
export const THEME_SET_BUTTON_DEFAULT_STYLES = 'theme:set:button:default:styles'
export const THEME_SET_FONT = 'theme:set:font'
export const THEME_SET_FROALA_LINK_DEFAULT_STYLES =
  'theme:set:froala:link:default:styles'
export const THEME_UPDATE_COLOR = 'theme:update:color'
export const SDA_POPUP_SETTINGS_INIT = 'sda:popup:settings:init'
export const SDA_POPUP_SETTINGS_UPDATE_PROP = 'sda:popup:settings:update:prop'
export const PENDO_BOOT = 'pendo:boot'
export const PENDO_HIDE = 'pendo:hide'
export const PENDO_SHOW = 'pendo:show'
export const PENDO_TOGGLE = 'pendo:toggle'
export const PENDO_TRACK = 'pendo:track'
export const PAGES_SESSION_COOKIE_DATA = 'pages:session:cookie:data'
export const APPCUES_BOOT = 'appcues:boot'
export const EVENT_TRACKING_BOOT = 'event:tracking:boot'
export const CHAMELEON_BOOT = 'chameleon:boot'
export const FROGED_BOOT = 'froged:boot'
export const DOPPLER_BOOT = 'doppler:boot'
export const PAGE_PREVIEW_INFO_TOGGLE = 'preview:page:info:toggle'
export const CUSTOM_TENANT_SCRIPTS_BOOT = 'custom:tenant:scripts:boot'
export const SET_SUBSCRIPTION_MODAL_VISIBILITY =
  'set:subscription:modal:visibility'
export const FETCH_ALL_CATEGORIES = 'fetch:all:categories'

export const HEADER_DELTA_IGNORE = 'header:delta:ignore'
export const HEADER_DELTA_INIT = 'header:delta:init'
export const HEADER_DELTA_LISTEN = 'header:delta:listen'
export const HEADER_DELTA_RESET = 'header:delta:reset'

export const FOOTER_DELTA_IGNORE = 'footer:delta:ignore'
export const FOOTER_DELTA_INIT = 'footer:delta:init'
export const FOOTER_DELTA_LISTEN = 'footer:delta:listen'
export const FOOTER_DELTA_RESET = 'footer:delta:reset'
export const HEADER_DELTA_READY = 'delta:ready:header'
export const FOOTER_DELTA_READY = 'delta:ready:footer'
export const FOOTER_DELTA_DISABLED = 'delta:ready:footer:disable'
export const FOOTER_DELTA_DISABLED_CHECK = 'delta:ready:footer:disable:check'
export const HEADER_CREATE_VDOM = 'header:delta:create'

export const HEADER_DELTA_DISABLED = 'delta:ready:header:disable'
export const HEADER_DELTA_DISABLED_CHECK = 'delta:ready:header:disable:check'
export const FETCH_PREFERENCES = 'site:preferences:fetch'
export const SAVE_PREFERENCE = 'site:preference:save'
export const UPDATE_PREFERENCE = 'site:preference:update'

export const UPDATE_DNS_RECORD = 'dns:record:update'
export const LOAD_CUSTOM_HOST_NAMES = 'load:custom:host:names'
export const LOAD_ENV_JWT_TOKEN = 'load:env:jwt:token'
export const VERIFY_CUSTOM_HOST_NAMES = 'verify:custom:host:names'
export const FETCH_DNS_RECORD = 'fetch:dns:record'
export const ADD_OIDC_CONFIG = 'add:oidc:config'
export const FETCH_OIDC_CONFIG = 'fetch:oidc:config'
export const CREATE_OIDC_CONFIG = 'create:iodc:config'

export const UPDATE_BUILDER_INTEGRATIONS_DATA =
  'update:builder:integrations:data'
export const SELECT_INTEGRATION = 'select:integration'
export const CHECKOUT_CONFIG_INIT = 'checkout:conf:init'
export const CHECKOUT_PAGE_OPTION_INIT = 'checkout:page:option:init'
export const CHECKOUT_THANK_YOU_PAGE_INIT =
  'checkout:thank-you:page:option:init'
export const VERIFY_MFA_CODE = 'verify:mfa:code'
export const SEND_MFA_CODE = 'send:mfa:code'

export const FETCH_IT_TEAM = 'fecth:it:team'
export const CREATE_IT_MEMBER = 'create:it:member'
export const DELETE_IT_MEMBER = 'delete:it:member'
export const RESEND_IT_INVITE = 'resend:it:invite'
export const UPDATE_CHAT_WIDGET_CONFIG = 'update:chat:widget:config'

export const FETCH_CONFIG = 'fetch:config'
export const UPSERT_CONFIG = 'upsert:config'
export const SAVE_GEN_AI_SITE = 'save:gen:ai:site'
export const DELETE_GEN_AI_SITE = 'delete:gen:ai:site'

export const MP_SAVE_BRANDING_DATA_TO_FIREBASE =
  'mp:save:branding:data:to:firebase'
export const MP_INIT_CREATE_MANAGER = 'mp:init:create:manager'
export const MP_ADD_COLOR = 'mp:add:color'
export const MP_UPDATE_COLOR = 'mp:update:color'
export const MP_REMOVE_COLOR = 'mp:remove:color'
export const MP_SET_MANAGER_MODE = 'mp:set:manager:mode'
export const MP_INIT_BRAND_MANAGER = 'mp:init:brand:manager'
export const MP_DELETE_BRAND = 'mp:delete:brand'
export const MP_DUPLICATE_BRAND = 'mp:duplicate:brand'
export const GET_PRODUCT_VERSION_NUMBER = 'get:product:version:number'
export const SET_CURRENT_PRODUCT_VERSION_NUMBER =
  'set:current:product:version:number'
