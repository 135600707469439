<template>
  <div class="app" :class="appClasses">
    <router-view />
    <loader />
    <migration-process v-if="isEditorAndDesignEditor" />
  </div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/component-definition-name-casing */
import ActionButtons from '@/js/components/editor/nodes/ActionButtons.vue'
import BrandLogo from '@/js/components/editor/nodes/BrandLogo.vue'
import BuyNowButton from '@/js/components/editor/nodes/BuyNowButton.vue'
import CButton from '@/js/components/editor/nodes/CButton.vue'
import CForm from '@/js/components/editor/nodes/CForm.vue'
import CImage from '@/js/components/editor/nodes/CImage.vue'
import CSurvey from '@/js/components/editor/nodes/CSurvey.vue'
import CVideo from '@/js/components/editor/nodes/CVideo.vue'
import Divider from '@/js/components/editor/nodes/Divider.vue'
import Draft from '@/js/components/editor/nodes/DraftFroala.vue'
import CvtIcon from '@/js/components/editor/nodes/icon/Icon.vue'
import SubGridContainer from '@/js/components/editor/nodes/SubGridContainer.vue'
import Vue from 'vue'
import AssetManager from './components/asset-manager/Manager.vue'
import Anime from './components/common/Anime.vue'
import AnimeGroup from './components/common/AnimeGroup.vue'
import Icon from './components/common/Icon.vue'
import MainNav from './components/common/MainNav.vue'
import FadeInOut from './components/common/transitions/FadeInOut.vue'
import UrlInput from './components/common/UrlInput.vue'
import AdvancedSideMenu from './components/editor/advanced-side-menu/AdvancedSideMenu.vue'
import FormEditor from './components/editor/forms/FormEditor.vue'
import IconPicker from './components/editor/IconPicker.vue'
import CPopupBuilder from './components/editor/nodes/CPopupBuilder.vue'
import DragAndDropZone from './components/editor/nodes/DragAndDropZone.vue'
import DragItems from './components/editor/nodes/DragItems.vue'
import VNodeBtn from './components/editor/nodes/VNodeBtn.vue'
import VNodeBtnGroup from './components/editor/nodes/VNodeBtnGroup.vue'
import VNodeDragHandle from './components/editor/nodes/VNodeDragHandle.vue'
import VNodeLabel from './components/editor/nodes/VNodeLabel.vue'
import VNodeOverlay from './components/editor/nodes/VNodeOverlay.vue'
import VNodePush from './components/editor/nodes/VNodePush.vue'
import QuickLaunchBlock from './components/editor/quick-launch/QuickLaunchBlockFull.vue'
import SaveBlock from './components/editor/SaveBlock.vue'
import SwapBlock from './components/editor/SwapBlock.vue'
import SiteSettings from './components/editor/SiteSettings.vue'
import ImageSearch from './components/ImageSearch.vue'
import Loader from './components/Loader.vue'
import './components/v3'

Vue.component('Anime', Anime)
Vue.component('AnimeGroup', AnimeGroup)
Vue.component('Icon', Icon)
Vue.component('UrlInput', UrlInput)
Vue.component('MainNav', MainNav)
Vue.component('VnodeLabel', VNodeLabel)
Vue.component('VnodeDragHandle', VNodeDragHandle)
Vue.component('VnodePush', VNodePush)
Vue.component('VnodeOverlay', VNodeOverlay)
Vue.component('VnodeBtn', VNodeBtn)
Vue.component('VnodeBtnGroup', VNodeBtnGroup)
Vue.component('AdvancedSideMenu', AdvancedSideMenu)
Vue.component('FormEditor', FormEditor)
Vue.component('IconPicker', IconPicker)
Vue.component('QuickLaunchBlock', QuickLaunchBlock)
Vue.component('DragAndDropZone', DragAndDropZone)
Vue.component('SwapBlock', SwapBlock)
Vue.component('SaveBlock', SaveBlock)
Vue.component('Loader', Loader)
Vue.component('ImageSearch', ImageSearch)
Vue.component('SiteSettings', SiteSettings)
Vue.component('AssetManager', AssetManager)
Vue.component('FadeInOut', FadeInOut)
Vue.component('CButton', CButton)
Vue.component('Draft', Draft)
Vue.component('CImage', CImage)
Vue.component('CSurvey', CSurvey)
Vue.component('Divider', Divider)
Vue.component('CVideo', CVideo)
Vue.component('CForm', CForm)
Vue.component('Icon', CvtIcon)
Vue.component('BrandLogo', BrandLogo)
Vue.component('BuyNowButton', BuyNowButton)
Vue.component('ActionButtons', ActionButtons)
Vue.component('CPopupBuilder', CPopupBuilder)
Vue.component('SubGridContainer', SubGridContainer)
Vue.component('DragItems', DragItems)

Vue.directive('intersected-once', {
  inserted(el, { value }) {
    const { onIntersected, shouldObserve } = value

    if (shouldObserve === false) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      if (entry && entry.isIntersecting) {
        observer.disconnect()
        onIntersected()
      }
    })
    observer.observe(el)
  },
})
export default {
  name: 'App',
  computed: {
    appClasses() {
      return {
        'app--header': true,
        'app--header--editor': this.isEditor,
      }
    },
    isEditor() {
      return this.$route.matched.some((m) => m.meta.editor)
    },
    isEditorAndDesignEditor() {
      return this.isEditor && this.$route.name === 'editor:design:page'
    },
  },
}
</script>

<style lang="scss">
@import 'bootstrap';
@import '@/scss/utils';
@import 'vue2-animate/dist/vue2-animate.min';
@import '@/scss/breakpoint';

.el-upload__input {
  display: none !important;
}

body,
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
}

.app-bg {
  background-color: $dark-gray;
  overflow-y: auto;
  overflow-x: hidden;
}

.bank-state {
  width: 40vw;
  height: 30vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $medium-gray;
  border-radius: 7px;
  border: 2px dashed $info;
  padding: 10em 0;
  cursor: pointer;
}

.bank-state-welcome-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app--header {
  margin-top: 0;
}

.dot-grid {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  background-size: 20px 20px;
  background-position: -233px -2px;
  background-image: linear-gradient(
      transparent 0px,
      transparent 2px,
      rgb(255, 255, 255) 2px,
      rgb(255, 255, 255) 20px
    ),
    linear-gradient(
      to right,
      rgb(200, 195, 199) 0px,
      rgb(200, 195, 199) 2px,
      rgb(255, 255, 255) 2px,
      rgb(255, 255, 255) 20px
    );
}

.page-engine {
  display: flex;
  flex-direction: column;
  position: relative;
}
.page-engin-margin-top {
  margin-top: 4em;
}

.page-engine-wrapper {
  background: $white;
  margin: auto;
  user-select: none;
  width: 100%;
  box-shadow: 0 0 0 0.75pt #d1d1d1, 0 0 3pt 0.75pt #ccc;
  transition: all 0.3s cubic-bezier(0.73, 0, 0.23, 0.99);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;

  &--editor-active {
    // margin-top: 0;
    margin-top: 145px;
    height: 116vh;
    position: absolute;
    transform: scale(0.78);
    transform-origin: top center;
    // padding: 20px;
  }

  &--editor-active-small-scale {
    margin-top: 145px;
    height: 116vh;
    position: absolute;
    transform: scale(0.68);
    transform-origin: top center;
  }

  &--editor-active-grid-small-scale {
    transform: scale(0.78);
    margin-top: 80px;
  }
  &--editor-active-grid-tiny-scale {
    //transform: scale(0.7);
    margin-top: 80px;
    margin-right: 0.8rem;
    height: 119vh;
  }

  &--advanced-menu-open {
    // transform: scale(0.82);
    transform-origin: 20% top;
    // height: 108vh;
    // margin-top: 0;
  }

  &--advanced-menu-open-small-shift {
    transform-origin: 5% top;
  }

  &--advanced-menu-open-grid {
    transform-origin: 25% top;
  }
  &--left-side-menu-open {
    // transform: scale(0.82);
    transform-origin: 95% top;
    // margin-right: 10rem;
    // height: 108vh;
    // margin-top: 0;
  }

  &--right-side-menu-open {
    transform-origin: 5% top;
  }

  .container {
    position: relative;
  }
}


.page-engine-viewmode--sm.page-engine-wrapper {
  &--editor-active-grid-tiny-scale {
    margin-right: auto;
  }
}

.tooltip {
  display: none;
  opacity: 0;
  transition: opacity 0.15s;
  pointer-events: none;
  padding: 4px;
  z-index: 10000;

  .tooltip-content {
    background: $tooltip-bg;
    color: $tooltip-color;
    border-radius: 5px;
    padding: 3px 10px 3px;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &.tooltip-open-transitionend {
    display: block;
  }

  &.tooltip-after-open {
    opacity: 1;
  }
}

button {
  cursor: pointer;
}

// custom scroll bars
.fancy-scroll {
  overflow-y: scroll;
}

/* customize scrollbar css */
.fancy-scroll::-webkit-scrollbar {
  width: 10px;
  background-color: #454b60;
}
.fancy-scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}
.fancy-scroll::-webkit-scrollbar-track {
  border: 1px #454b60 solid;
  border-radius: 10px;
  box-shadow: 0 0 6px #454b60 inset;
  -webkit-box-shadow: 0 0 6px #454b60 inset;
}
.fancy-scroll::-webkit-scrollbar-thumb {
  background-color: #313442;
  border: 1px solid #696a70;
  border-radius: 16px;
}
.fancy-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #505873;
  border: 1px solid #333333;
}
.fancy-scroll::-webkit-scrollbar-thumb:active {
  background-color: #3b4154;
  border: 1px solid #333333;
}
.backdrop {
  position: inherit;
  top: 0;
  left: 0;
  z-index: 2010;
  width: 100vw;
  height: 130vh;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0.5;
}
.change-z-index {
  z-index: 3 !important;
}
.subdiv-border {
  border-left: 1px solid #cfd7df;
}
.subdiv-spacing-left {
  margin-left: 20px;
}
.divider {
  width: 100%;
  border-top: 1px solid #eee;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.divider-no-border {
  width: 100%;
  border-top: unset;
  margin-top: 15px !important;
  margin-bottom: 0rem !important;
}
.divider-with-legend {
  display: flex;
  flex-direction: row;
  color: #8A8E97;
    
  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #E2E6ED;
    margin: auto 5px;
  }
}
.divider-spacing {
  width: 100%;
  border-top: unset;
  margin-top: 24px !important;
  margin-bottom: 0rem !important;
}
.alt-buttons {
  display: flex;
  position: absolute;
  right: 24px;
  bottom: -54px;
  z-index: 20;
}
.selected-mark {
  outline: 2px #2a8cd0 solid;
}
.unselected-mark {
  outline: 2px transparent solid;
}
</style>
