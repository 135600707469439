export const SDA_POPUP_SITE_DATA = 'get:sda:popup:site:data'
export const SDA_POPUP_FIREBASE_DATA = 'get:popupbuilder:firebase:data'
export const SDA_POPUP_ROOTPATH = 'get:popupbuilder:firebase:rootpath'
export const SDA_POPUP_FIREBASE_BLOCK = 'get:popupbuilder:firebase:block'
export const SDA_POPUP_FIREBASE_PATH = 'get:popupbuilder:firebase:path'
export const SDA_POPUP_SETTINGS = 'sda:popup:settings'
export const SDA_POPUP_SETTINGS_AUDIENCE = 'sda:popup:settings:audience'
export const SDA_POPUP_SETTINGS_RULES = 'sda:popup:settings:rules'
export const DELTA_VDOM = 'delta:get:vdom'
export const DELTA_KEY = 'delta:get:key'
export const DELTA_SOURCE = 'delta:get:source'
export const DELTA_REF = 'delta:get:ref'
export const DELTA_PATH = 'delta:get:path'
export const SELECTOR_SELECTED = 'selector:get:selected'
export const SELECTOR_TARGETED = 'selector:get:targeted'
export const SELECTOR_GET_HIERARCHY = 'selector:get:hierarchy'
export const SELECTOR_SELECTED_VNODE_ID = 'selector:get:selected:vnodeId'
export const SELECTOR_GET_SELECTED_PROP = 'selector:get:selected:prop'
export const SELECTOR_GET_SELECTED_PROPS = 'selector:get:selected:props'
export const SELECTOR_GET_TARGET_ROW = 'selector:get:target:row'
export const SELECTOR_GET_SELECTED_ROW = 'selector:get:selected:row'
export const SELECTOR_GET_TARGET_BLOCK = 'selector:get:target:block'
export const SELECTOR_GET_SELECTED_BLOCK = 'selector:get:selected:block'
export const DROPZONE_ACTIVE = 'dropzone:get:active'
export const DROPZONE_DATA_TRANSFER = 'dropzone:get:data:transfer'
export const FORM_EDITOR_VISIBLE = 'form:editor:get:visible'
export const FORM_EDITOR_FIELD_GROUPS = 'form:editor:field:groups'
export const QUICK_LAUNCH_BLOCK_VISIBLE = 'quick:launch:block:get:visible'
export const SAVE_BLOCK_VISIBLE = 'save:block:get:visible'
export const SAVE_BLOCK_DATA = 'save:block:get:data'
export const SAVE_BLOCK_VNODE = 'save:block:get:vnode'
export const SWAP_BLOCK_VISIBLE = 'swap:block:get:visible'
export const SWAP_BLOCK_GET_CURRENT_BLOCK = 'swap:block:get:current:block'
export const SWAP_BLOCK_GET_SAVED_BLOCK = 'swap:block:get:saved:block'
export const GET_REARRANGING_BLOCK = 'block:get:rearranging-block'
export const SWAP_BLOCK_IN_PROCESS = 'swap:block:in:process'
export const SITE_GET_DATA = 'site:get:data'
export const SITE_GET_INDEX = 'site:get:index'
export const SITE_GET_THANKYOU = 'site:get:thankyou'
export const SITES_GET_LIST = 'sites:get:list'
export const SITE_GET_ACTIVE_PAGE = 'site:get:active:page'
export const SITE_GET_SETTINGS_VISIBLE = 'site:get:settings:visible'
export const SITE_SETTINGS_ACTIVE_TAB = 'site:settings:active:tab'
export const SDA_POPUP_SETTINGS_ACTIVE_TAB = 'popup:settings:active:tab'
export const SITE_READY = 'site:ready'
export const SITE_PULSE_PREFERENCE = 'site:pulse:preference'
export const SITE_DOMAINS = 'site:domains'
export const SITE_ANALYTICS = 'site:analytics'
export const SITE_TRACKING = 'SITE_TRACKING'
export const SITE_CHECKOUT = 'SITE_CHECKOUT'
export const PRODUCT_VERSION_NUMBER = 'product:version:number'
export const CURRENT_PRODUCT_VERSION_NUMBER = 'current:product:version:number'
export const USER_PRODUCT_VERSION_NUMBER = 'user:product:version:number'
export const AUTH_GET_USER = 'auth:get:user'
export const AUTH_GET_USER_CONFIG = 'auth:get:user:config'
export const AUTH_GET_USER_BUILDER_THEME = 'auth:get:user:builder:theme'
export const AUTH_GET_USER_IS_ADMIN = 'auth:get:user:is:admin'
export const AUTH_GET_FIREBASE_USER = 'auth:get:firebase:user'
export const THEME = 'theme'
export const IMAGE_SEARCH_RESULTS = 'image:search:results'
export const IMAGE_SEARCH_VISIBLE = 'image:search:visible'
export const IMAGE_SEARCH_SEARCHING = 'image:search:searching'
export const THEME_GET_COLORS = 'theme:get:colors'
export const THEME_GET_BLOCK_COLORS = 'theme:get:block:colors'
export const THEME_DEFAULT_COLOR = 'theme:get:defaultColors'
export const THEME_AVAILABLE_FONTS = 'theme:available:fonts'
export const THEME_TYPOGRAPHY = 'theme:typography'
export const THEME_TYPOGRAPHY_CLASS = 'theme:typography:class'
export const THEME_FONT_STYLES = 'theme:font:styles'
export const THEME_BUTTON_DEFAULT_STYLES = 'theme:button:default:styles'
export const THEME_FROALA_LINK_DEFAULT_STYLES =
  'theme:froala:link:default:styles'
export const THEME_BRAND_LOGO = 'theme:brand:logo'
export const THEME_CAN_ADD_NEW_COLORS = 'theme:can:add:new:colors'
export const THEME_CAN_EDIT_COLORS = 'theme:can:edit:colors'
export const THEME_MANAGER_VERSION = 'theme:manager:version'
export const THEME_MANAGER = 'theme:manager:instance'
export const PREVIEW_VISIBLE = 'preview:get:visibility'
export const ICON_PICKER_VISIBLE = 'icon:picker:visible'
export const HISTORY_CAN_UNDO = 'history:can:undo'
export const HISTORY_CAN_REDO = 'history:can:redo'
export const HISTORY_INITIALIZED = 'history:initialized'
export const HISTORY_MANAGER = 'history:manager'
export const OPEN_GRAPH_SETTINGS = 'open:graph:settings'
export const SUPER_GET_APPS = 'super:get:apps'
export const SUPER_SEARCHING = 'super:searching'
export const ELEMENT_LAUNCHER_VISISBLE = 'element:launcher:visible'
export const ELEMENT_LAUNCHER_TARGET = 'element:launcher:target'
export const ADVANCED_MENU_OPEN = 'advanced:menu:open'
export const ADVANCED_MENU_TABS = 'ADVANCED_MENU_TABS'
export const RIGHT_CLICK_MENU_OPEN = 'right:click:menu:open'
export const RIGHT_CLICK_MENU_POSITION = 'right:click:menu:position'
export const RIGHT_CLICK_MENU_TARGET = 'right:click:menu:target'
export const ASSET_MANAGER_OPEN = 'asset:manager:open'
export const ASSET_MANAGER_ACTIVE_TAB = 'asset:manager:active:tab'
export const ASSET_MANAGER_CRM_FILES = 'asset:manager:crm:files'
export const MARKETPLACE_TEMPLATES = 'marketplace:templates'
export const MARKETPLACE_SITE_DOMAINS = 'marketplace:site:domains'
export const POPUP_MARKETPLACE_TEMPLATES = 'popup:marketplace:templates'
export const PAGE_PREVIEW_OPEN = 'page:preview:open'
export const STEPS = 'steps'
export const STEPS_CURRENT_STEP = 'steps:current:step'
export const STEPS_PREV_STEP = 'steps:prev:step'
export const STEPS_NEXT_STEP = 'steps:next:step'
export const STEPS_CURRENT_STEP_IDX = 'steps:current:step:index'
export const DRAFT_IS_EDITING = 'draft:is:editing'
export const DRAFT_RANGE = 'draft:range'
export const DRAFT_RANGE_FORMAT = 'draft:range:format'
export const LEFT_SIDE_MENU_OPEN = 'left:side:menu:open'
export const LEFT_SIDE_MENU_ACTIVE_OPTION = 'left:side:menu:active:option'
export const LEFT_SIDE_PAGE_OPEN = 'left:side:page:open'
export const LEFT_SIDE_SECTION_OPEN = 'left:side:section:open'
export const LEFT_SIDE_BAR_CONTEXT = 'left:side:section:open-context'
export const LAYOUT_PAGE_ENGINE = 'layout:page:engine'
export const LAYOUT_PATH = 'layout:path'
export const LAYOUT_CHILDREN = 'layout:children'
export const LAYOUT_SECTIONS = 'layout:sections'
export const LAYOUT_PARENT = 'layout:parent'
export const INTERCOM_VISIBLE = 'intercom:visible'
export const INTERCOM_UNREAD = 'intercom:unread'
export const ANCHORS = 'anchor'
export const START_OVER_DIALOG_SHOW = 'start-over:show:dialog'
export const VIEW_MODE = 'view:mode'
export const VIEW_MODE_CLASS = 'view:mode:class'
export const GLOBAL_STYLE_COLOR = 'global:style:color'
export const GLOBAL_STYLE_BACKGROUND_COLOR = 'global:style:background:color'
export const GLOBAL_STYLE_BORDER_COLOR = 'global:style:border:color'
export const GLOBAL_STYLE_ELEVATION = 'global:style:elevation'
export const GLOBAL_STYLE_FONT_SIZE = 'global:style:font:size'
export const GLOBAL_STYLE_FONT_WEIGHT = 'global:style:font:weight'
export const GLOBAL_STYLE_LINE_HEIGHT = 'global:style:line:height'
export const EDITOR_NAME_DIALOG = 'editor:name:dialog'
export const SITE_SETTINGS = 'site:settings'
export const SITE_SETTINGS_MESSAGE = 'site:settings:message'
export const SURVEY_OPEN = 'survey:open'
export const POPUP_BUILDER_OPEN = 'popup:open'
export const STANDALONE_POPUP_ACTIVE = 'standalone:popup:active'
export const POPUP_IDX = 'popup:idx'
export const POPUP_EMPTY = 'popup:empty'
export const POPUP_PATH = 'popup:path'
export const TEMPLATE_PAGE_ID = 'template:page:id'
export const SITE_PREVIEW_PAGE_ID = 'site:preview:page:id'
export const SITE_PREVIEW_ROUTE_VISITED = 'site:preview:route:visited'
export const ADDITIONAL_FORM_DEFAULTS = 'additional:form:defaults'
export const CHECK_FOR_SITE_PUBLISH_PERMISSION =
  'check:for:site:publish:permission'
export const PAGE_PREVIEW_INFO_OPEN = 'page:preview:info:open'

export const COMMAND_CAN_UNDO = 'command:can:undo'
export const COMMAND_CAN_REDO = 'command:can:redo'
export const AVATAR_IMG_URL = 'avatar:img:url'

export const PAGES_SESSION_DATA = 'get:pages:session:cookie:data'
export const SUBSCRIPTION_MODAL_VISIBLE = 'get:subscription:modal:visibility'

export const GET_ALL_CATEGORIES = 'get:all:categories'
export const GET_MODAL = 'get:modal'
export const GET_PREFERENCES = 'get:site:preferences'
export const GET_FB_SITE_PREFERENCES = 'get:fb:site:preferences'
export const SITE_PREFERENCES_INTEGRATION = 'get:site:integration:preferences'

export const HEADER_DELTA_VDOM = 'header:delta:get:vdom'
export const HEADER_DISABLED = 'header:delta:disable'
export const FOOTER_DISABLED = 'footer:delta:disable'
export const HEADER_DELTA_VDOM_READY = 'header:delta:get:vdom:ready'
export const HEADER_DELTA_KEY = 'header:delta:get:key'
export const HEADER_DELTA_SOURCE = 'header:delta:get:source'
export const HEADER_DELTA_REF = 'header:delta:get:ref'
export const HEADER_DELTA_PATH = 'header:delta:get:path'

export const FOOTER_DELTA_VDOM = 'footer:delta:get:vdom'
export const FOOTER_DELTA_VDOM_READY = 'footer:delta:get:vdom:ready'
export const FOOTER_DELTA_KEY = 'footer:delta:get:key'
export const FOOTER_DELTA_SOURCE = 'footer:delta:get:source'
export const FOOTER_DELTA_REF = 'footer:delta:get:ref'
export const FOOTER_DELTA_PATH = 'footer:delta:get:path'

export const DNS_RECORDS = 'dns:record'
export const CUSTOM_HOST_NAMES = 'custom:host:names'
export const ENV_JWT_TOKEN = 'env:jwt:token'
export const OIDC_CONFIG = 'oidc:config'

export const GET_BUILDER_INTEGRATIONS_DATA = 'get:builder:integrations:data'
export const SELECTED_INTEGRATION = 'selected:integration'
export const GET_PROVIDER_API_SERVICE = 'provider:api:service'
export const GET_BLOCK_ADD_STATUS = 'block:add:status'
export const CHECKOUT_PAGE_OPTIONS = 'checkout:page:options'
export const CHECKOUT_THANK_YOU_PAGE_OPTIONS = 'checkout:thank-you:page:options'
export const IT_TEAM = 'it:team'

export const SIGNIN_USER_EMAIL = 'signin:user:email'
export const CHAT_WIDGET_CONFIG = 'chat:widget:config'

export const USER_CONFIG = 'user:config'

export const INTEGRATIONS_DIALOG_ENABLED = 'integrations:dialog:enabled'
export const BOOKMARK_BLOCK_VISIBLE = 'bookmark:block:visible'
export const PAGES_BOOKMARK_DIALOG_VISIBLE = 'pages:bookmark:dialog:visible'
export const PAGES_BOOKMARK_PAGE_REF = 'pages:bookmark:page:ref'


export const MP_GET_THEME_COLORS = 'mp:get:theme:colors'
export const MP_GET_FONT_STYLES = 'mp:get:font:styles'
export const MP_GET_DEFAULT_COLOR = 'mp:get:default:color'
export const MP_GET_BRANDS = 'mp:get:brands'
export const MP_GET_MANAGER_MODE = 'mp:get:manager:mode'
export const IS_GRID_BUILDER = 'is:grid:builder'
