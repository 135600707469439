
<GenAILayout class="intent-selection">
  <template #nav>
    <cvt-top-nav-bar
      mode="LIGHT"
      :route="genAISelectTemplate"
      variant="embedded"
      :class="'cvt-top-nav-bar'"
      template-name="Let's get started!"
      :on-genai-wizard="true"
      :fixed="true"
      :light-logo="logo"
      :dark-logo="logo"
      :type="orgName"
      @back="navigateToChooseATemplate"
    />
  </template>

  <!--    <cvt-left-side-bar class="marketplace-left-nav-bar intent-sidebar">-->
  <div class="intent-sidebar">
    <cvt-button
      :disabled="loading"
      class="back-btn"
      text=""
      icon="cvt-icon-arrow-left-05"
      @click="goBack"
    />

    <p class="template-p">
      Based on your selection, here is the best template for you.
    </p>

    <div class="d-flex align-content-center">
      <cvt-button
        v-if="config.GEN_AI_WIZARD_SWITCH_TEMPLATE_BUTTON"
        :loading="loading"
        class="mt-4 mb-5 mr-3 magic-btn"
        text="Next Template"
        :prepend="true"
        @click="switchTemplate"
      />
      <cvt-button
        :loading="loading"
        class="mt-4 mb-5 magic-btn"
        text="Continue"
        icon="cvt-icon-arrow-right-05"
        :prepend="true"
        @click="() => useTemplate()"
      />
    </div>
  </div>
  <!--    </cvt-left-side-bar>-->
  <div class="content-wrapper position-relative">
    <div v-if="loading" class="working-on-block">
      <div class="working-on-block__container">
        <h3 class="loader-message" style="font-family: 'Nunito'">
          {{ loadingMessage }}
        </h3>
      </div>
    </div>
    <div class="w-100, h-100">
      <template v-if="currentAPIData.explainerURL">
        <div
          :class="{
            content: true,
            'd-none': !currentAPIData.explainerURL,
            mobile: viewMode !== 'desktop',
          }"
        >
          <img
            class="desktop preview-info-active"
            :src="currentAPIData.explainerURL"
            alt="ExplainerURL"
          />
        </div>
      </template>
      <template v-else>
        <div
          :class="{
            content: true,
            'd-none': currentAPIData.previewURL === '',
            mobile: viewMode !== 'desktop',
          }"
        >
          <iframe
            :key="currentAPIData.previewURL"
            :src="currentAPIData.previewURL"
            frameborder="0"
            @load="iframeLoaded"
          />
        </div>
      </template>
    </div>
  </div>
</GenAILayout>
