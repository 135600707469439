import * as actions from '@/js/store/modules/actions'
import * as getters from '@/js/store/modules/getters'
import * as mutations from '@/js/store/modules/mutations'
import { cvtRouteNodes, cvtRoutes } from '@/js/utils/routes'
import { styled } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { CookieHelper } from '../../../builder/src/js/store/services/cookieHelper'
import DarkLogo from '../../assets/logo/convrrt-dark.svg'
import LightLogo from '../../assets/logo/convrrt-light.svg'
import Vue from '../../lib/utils/vue'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
  LIONDESK,
} from '../constants'
import UserAvatar from '../molecules/Avatar'
import CvtButton from '../molecules/Button'
import CvtInput from '../molecules/Input'
import CvtSwitch from '../molecules/Switch'
import CvtDropdown from '../molecules/dropdown/Dropdown'
import CvtRadioButton from '../molecules/radio-group/RadioButton'
import CvtRadioGroup from '../molecules/radio-group/RadioGroup'
import { CvtTooltip } from '../molecules/tooltip/CvtTooltip'

Vue.use(VueI18n)

let Spacing,
  FontFamily,
  Colors,
  Border,
  FontSize,
  LineHeight,
  FontWeight,
  textFontSize,
  textFontWeight,
  backgroundColor,
  color

const getTopNav = () => styled('nav')`
  display: flex;
  top: 0;
  justify-content: space-between;
  z-index: 4;
  ${(props) => {
    let weight = 'WHITE'
    weight = props.mode === 'DARK' ? 700 : 'WHITE'
    let style = backgroundColor({ themeing: props.backgroundColor }, weight)
    if (props.hexBackgroundColor !== null) {
      style = `background-color: ${props.hexBackgroundColor};`
    }
    style += `border-bottom: ${Border.SIZE_1}px solid ${Colors.BASIC[200]};`
    style += `height: ${props.height}px;`
    style += `padding: ${props.padding}px;`
    return style
  }}

  &.__fixed {
    position: fixed;
    width: 100%;
  }
`

const getDropDown = () => styled(CvtDropdown)`
  height: 32px;
`

const getNavLabel = () => styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`

const getLabelBtnWrapper = () => styled('div')``

const getActionsBtnWrapper = () => styled('div')`
  margin-right: ${Spacing.SPACING_8}px;

  &:last-child {
    margin-right: ${Spacing.SPACING_16}px;
  }
`

const getCompanyLogo = () => styled('img')`
  margin-left: ${Spacing.SPACING_16}px;
  height: 100%;
  cursor: pointer;
`

const getExitPreview = () => styled(CvtButton)`
  i {
    font-size: ${FontSize.FONT_SIZE_24}px;
    font-weight: ${FontWeight.LIGHT};
    line-height: ${LineHeight.LINE_HEIGHT_24}px;
  }
`

const ExitButton = styled(CvtButton)``

const getTemplateName = () => styled('div')`
  margin: 0px ${Spacing.SPACING_32}px;
  ${textFontSize('xl')}
  ${textFontWeight('LIGHT')}
  font-family: ${FontFamily.DEFAULT};
  line-height: ${Spacing.SPACING_24}px;
  ${(props) => {
    let weight = 'WHITE'
    weight = props.mode === 'DARK' ? 'WHITE' : 500
    return color({ themeing: props.color }, weight)
  }}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
  display: inline;
`

const getViewMode = () => styled('div')`
  display: flex;
  flex-grow: 0;
  ${(props) => {
    return props.rightAlign
      ? 'flex-grow:2;justify-content: flex-end;'
      : 'justify-content: center;'
  }}

  ${(props) =>
    props.centered ? 'justify-content:flex-start;flex-grow:1;' : ''}

  padding-right: ${Spacing.SPACING_12}px;

  & .radio-group {
    margin: 0;
  }
`

const SwitchMode = styled(CvtSwitch)`
  margin-left: auto;
  margin-right: auto;
`

const getNavActions = () => styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0.5;
`

const Undo = styled(CvtButton)``

const Redo = styled(CvtButton)``

const VersionHistory = styled(CvtButton)``

const Help = styled(CvtButton)``

const Launch = styled(CvtButton)``

const ScheduleDemo = styled(CvtButton)``

const getEntDashboardBtn = () => styled(CvtButton)``

const Preview = styled(CvtButton)``

const UseTemplate = styled(CvtButton)``

const Exit = styled(CvtButton)``

const getTooltip = () => styled(CvtTooltip)``

const getTopBar = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  display: flex;
  gap: ${Spacing.SPACING_16}px;
  align-items: center;
`

const getOrgAndProjectWrapper = () => styled('div')`
  display: flex;
  gap: ${Spacing.SPACING_8}px;
  ${textFontWeight('REGULAR')};
  font-size: ${FontSize.FONT_SIZE_20}px;
  ${(props) => {
    let weight = 'WHITE'
    weight = props.mode === 'DARK' ? 'WHITE' : 500
    return color({ themeing: props.color }, weight)
  }}
`

const getTitles = () => styled('div')`
  width: max-content;
`

const getOrgProjectName = () => styled('span')`
  ${textFontWeight('BOLD')};
`

const getVerticalDivider = () => styled('div')`
  border-right: 1px solid ${Colors.SMOKE ? Colors.SMOKE[100] : 'grey'};
  margin-right: 8px;
`

const getUserAvatar = () => styled(UserAvatar)``

export const CvtTopNavBar = {
  name: 'CvtTopNavBar',
  data() {
    return {
      lightLogoPath: LightLogo,
      darkLogoPath: DarkLogo,
      inputOrgName: '',
      inputProjectID: '',
    }
  },
  computed: {
    ...mapState('globalTheme', {
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      iconConfig: ({ iconConfig }) => iconConfig,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      activeCategory: ({ activeCategory }) => activeCategory,
      LineHeight: ({ globalTheme }) => globalTheme.LineHeight,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      versionHistoryBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.topNavBarComponent.locations
          .versionHistoryBtn,
      redoUndoBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.topNavBarComponent.locations.redoUndoBtn,
      popupDoneBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations.popupTopnavBar.locations.doneBtn.props
          ? globalButtonConfig?.locations.popupTopnavBar.locations.doneBtn.props
          : {},
    }),
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      surveyOpen: getters.SURVEY_OPEN,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      viewMode: getters.VIEW_MODE,
      sitePreviewRouteVisited: getters.SITE_PREVIEW_ROUTE_VISITED,
      userSessionData: getters.PAGES_SESSION_DATA,
      isPagePreviewOpen: getters.PAGE_PREVIEW_INFO_OPEN,
      showScheduleDemoModal: getters.GET_MODAL,
    }),
    topNavConfigForCurrentRoute() {
      return this.topNavConfig?.[this.route] || {}
    },
    navText() {
      if (this.route === cvtRouteNodes.marketplace) {
        return this.$t('editor.header.marketplace.title')
      }
      if (this.topNavConfigForCurrentRoute.title === true) {
        return this.templateName
      } else {
        return this.$t(this.topNavConfigForCurrentRoute.title)
      }
    },
    previewTopNavButtonText() {
      return this.activeCategory === 'your-templates'
        ? this.$t(this.topNavConfigForCurrentRoute.copyTemplateText) ||
            'Copy template'
        : this.activeCategory === 'archived-templates'
        ? this.$t(this.topNavConfigForCurrentRoute.restoreTemplateText) ||
          'Restore archived template'
        : this.$t(this.topNavConfigForCurrentRoute.useThisTemplateText)
    },
    showUserAvatar() {
      return this.topNavConfigForCurrentRoute['USER_AVATAR'] || false
    },
    getCookie() {
      return CookieHelper('_pages_session=')
    },
    isMac() {
      return navigator.userAgent.indexOf('Mac') >= 0
    },
    isWindows() {
      // for windows
      return navigator.userAgent.indexOf('Win') >= 0
    },
    isLinux() {
      // for linux
      return navigator.userAgent.indexOf('Linux') >= 0
    },
    UserNameInitials() {
      if (this.getCookie?.['firstName'] && this.getCookie?.['lastName']) {
        return (
          this.getCookie?.['firstName'][0] + this.getCookie?.['lastName'][0]
        )
      }
      return ''
    },
    showViewModeRadioBtns() {
      return (
        this.topNavConfigForCurrentRoute.showViewModeRadioBtns &&
        this.variant !== 'admin' &&
        !this.surveyOpen &&
        !this.isPagePreviewOpen
      )
    },
    showLaunchBtn() {
      return (
        this.topNavConfigForCurrentRoute.showLaunchBtn &&
        !this.popupBuilderOpen &&
        this.topNavConfigForCurrentRoute.launch
      )
    },
    isOnTrialMode() {
      if (!this.config) return
      return this.config.TRIAL_MODE
    },
    showScheduleDemoBtn() {
      return (
        this.isOnTrialMode &&
        this.topNavConfigForCurrentRoute.showScheduleDemoBtn
      )
    },
    showEntDashboardBtn() {
      return (
        this.isOnTrialMode &&
        this.topNavConfigForCurrentRoute.showEntDashboardBtn &&
        this.config.CURRENT_STAGE === 'enterprise_dashboard'
      )
    },
    showSitePreviewBtn() {
      return (
        this.topNavConfigForCurrentRoute.showSitePreviewBtn &&
        !this.popupBuilderOpen
      )
    },
    showSettingsBtn() {
      return (
        this.topNavConfigForCurrentRoute.editorActions === true &&
        this.topNavConfigForCurrentRoute.setting
      )
    },
    showAddpageBtn() {
      return (
        this.variant !== 'admin' &&
        !this.topNavConfigForCurrentRoute.logo &&
        this.topNavConfigForCurrentRoute.showPages
      )
    },
  },
  created() {
    FontFamily = this.FontFamily
    FontSize = this.FontSize
    Spacing = this.Spacing
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    color = this.color
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    LineHeight = this.LineHeight
    FontWeight = this.FontWeight
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    orgId: {
      type: String,
      default: '',
    },
    projectId: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'embedded',
    },
    route: {
      type: String,
      default: 'preview',
    },
    templateName: {
      type: String,
      default: 'Template XYZ',
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    lightLogo: {
      type: String,
      default: '',
    },
    darkLogo: {
      type: String,
      default: '',
    },
    undoDisabled: {
      type: Boolean,
      default: false,
    },
    redoDisabled: {
      type: Boolean,
      default: false,
    },
    revisionLoading: {
      type: Boolean,
      default: false,
    },
    disableExit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: LEFT_SIDEBAR_STANDARD,
    },
    inputData: {
      type: Array,
      default: () => [],
    },
    inputProjectData: {
      type: Array,
      default: () => [],
    },
    disablePublish: {
      type: Boolean,
      default: false,
    },
    onGenaiWizard: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    inputProjectData(suggestions) {
      this.projectIdCB &&
        this.projectIdCB(
          suggestions.map((suggestion) => {
            return {
              value: suggestion,
              label: suggestion,
            }
          }),
        )
    },
  },
  methods: {
    ...mapActions({
      setSubscriptionModalVisibility: actions.SET_SUBSCRIPTION_MODAL_VISIBILITY,
    }),
    ...mapMutations({
      toggleScheduleDemoModal: mutations.SET_MODAL,
    }),
    fetchSuggestions(term, cb) {
      clearTimeout(this.timeout)
      let results = term
        ? this.inputData.filter((item) => {
            return item.value.toLowerCase().indexOf(term.toLowerCase()) === 0
          })
        : this.inputData

      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        cb(results)
      }, 1000 * Math.random())
    },
    fetchProjectSuggestions(term, cb) {
      this.projectIdCB = cb
    },
    async click(eventName, e) {
      // Help button behavior.
      if (eventName === 'help') {
        this.$emit(eventName, e)
        return
      }

      // Logo behavior.
      if (eventName === 'logo') {
        // Condition for similar logo behaviors in different routes.
        // TODO: this should be refactored to not pull directly from this.config
        if (this.config?.MARKETPLACE_LOGO_REDIRECT !== undefined) {
          this.$emit('backToCrm', {
            url: this.config.MARKETPLACE_LOGO_REDIRECT,
          })
          return
        } else {
          /*
            Swap logo eventName to back, this will allow for intended
            original default behavior within local dev.
          */
          eventName = 'back'
          this.$emit(eventName, e)
          return
        }
      }

      // Exit behavior.
      if (eventName === 'exit') {
        this.$emit(eventName, e)
        return
      }

      // Exit preview behavior.
      if (eventName === 'exit-preview') {
        console.log(this.route)
        if (this.route === cvtRouteNodes.launch) {
          this.$emit('back', e)
          return
        } else if (this.route === cvtRouteNodes.sitePreview) {
          this.$emit('one-step-back', e)
          return
        } else if (this.route && this.route.includes('gen-ai')) {
          this.$emit('one-step-back', e)
          return
        }
      }

      if (eventName === 'back') {
        // this takes the user back from 'site-preview' route to 'editor' route
        if (this.route === cvtRouteNodes.sitePreview) {
          // Back one step
          this.$emit('one-step-back', e)
          return
        }

        // this takes the user back from 'launch' route to 'site-preview' route
        if (this.sitePreviewRouteVisited) {
          // Back one step
          this.$emit('one-step-back', e)
          return
        }
      }

      // TODO: this should be refactored to not pull directly from this.config
      if (
        this.config?.MARKETPLACE_LOGO_REDIRECT !== undefined &&
        this.route === cvtRouteNodes.marketplace
      ) {
        // Configurable tenant provided address used for the logo link.
        this.$emit('backToCrm', { url: this.config.MARKETPLACE_LOGO_REDIRECT })
      }
      // Catch all behavior to emit(pass) anything up into MainNav.vue
      else {
        // Home, Back, Switch-preview,
        // Currently being used to emit multiple values.
        // Note that the value "home" is redundant to the value "back"
        // as both are running the same code segment in MainNav.vue.
        // This has been purposely left intact because at this time
        // I do not know if the usage changes elsewhere in the codebase.
        this.$emit(eventName, e)
      }
    },
    goToSitePreview(eventName, event) {
      if (this.config.SUBSCRIPTION_ENABLED) {
        // user wont navigate onto next screen when he is not subscribed
        if (!this.userSessionData.user_subscribed) {
          // this opens the subscription modal
          this.setSubscriptionModalVisibility(true)
          return
        }
      }
      this.$emit(eventName, event)
    },
    scheduleDemo() {
      this.toggleScheduleDemoModal(true)
    },
    gotoEntDashboard() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseDashboardBranding,
      })
    },
  },
  render: function (h) {
    const TopNav = getTopNav()
    const NavLabel = getNavLabel()
    const LabelBtnWrapper = getLabelBtnWrapper()
    const ActionsBtnWrapper = getActionsBtnWrapper()
    const CompanyLogo = getCompanyLogo()
    const TemplateName = getTemplateName()
    const ViewMode = getViewMode()
    const NavActions = getNavActions()
    const ExitPreview = getExitPreview()
    const DropDown = getDropDown()
    const TopBar = getTopBar()
    const OrgAndProjectWrapper = getOrgAndProjectWrapper()
    const Titles = getTitles()
    const OrgProjectName = getOrgProjectName()
    const VerticalDivider = getVerticalDivider()
    const UserAvatar = getUserAvatar()
    const Tooltip = getTooltip()
    const EntDashboardBtn = getEntDashboardBtn()

    return (
      <TopNav
        mode={this.mode}
        class={{ __fixed: this.fixed }}
        backgroundColor={this.topNavConfig.theme.backgroundColor}
        hexBackgroundColor={this.topNavConfig.theme.hexBackgroundColor}
        height={
          this.topNavConfig.theme?.height
            ? this.topNavConfig.theme?.height
            : this.Spacing.SPACING_64
        }
        padding={
          this.topNavConfig.theme?.padding
            ? this.topNavConfig.theme?.padding
            : this.Spacing.SPACING_16
        }
      >
        <NavLabel>
          {this.variant !== 'admin' &&
            this.variant === 'embedded' &&
            this.topNavConfigForCurrentRoute.logo &&
            !this.popupBuilderOpen && (
              <CompanyLogo
                src={
                  this.mode == 'LIGHT'
                    ? this.lightLogo || this.lightLogoPath
                    : this.darkLogo || this.darkLogoPath
                }
                onClick={(e) => this.click('logo', e)}
              ></CompanyLogo>
            )}

          {this.variant === 'admin' && (
            <TopBar mode={this.mode}>
              <div>
                <CompanyLogo
                  src={
                    this.mode == 'LIGHT'
                      ? this.lightLogo || this.lightLogoPath
                      : this.darkLogo || this.darkLogoPath
                  }
                  onClick={(e) => this.click('logo', e)}
                ></CompanyLogo>
              </div>

              <CvtInput
                type="search"
                suffix-icon="search"
                // label='SELECT ORGANIZATION'
                clearable={false}
                // floatingLabel={false}
                usage="search"
                onInput={(e) => {
                  this.inputOrgName = e.target.value
                }}
                onSelect={(e) => {
                  this.inputOrgName = e
                  this.$emit('inputOrgName', this.inputOrgName)
                }}
                size="small"
                mode={this.mode}
                color="black"
                id="selectOrganization"
                placeholder="Select Org"
                value={this.inputOrgName}
                fetchSuggestions={this.fetchSuggestions}
                actionButton={true}
                onCreateNewOrg={() => this.$emit('createNewOrg')}
              ></CvtInput>

              <CvtInput
                type="search"
                suffix-icon="search"
                clearable={false}
                label=""
                floatingLabel={false}
                usage="search"
                onInput={(e) => {
                  this.inputProjectID = e.target.value
                  this.$emit('change', e.target.value)
                }}
                onSelect={(e) => {
                  this.inputProjectID = e
                  this.$emit('inputProjectID', this.inputProjectID)
                }}
                size="small"
                mode={this.mode}
                color="black"
                placeholder="Project ID"
                value={this.inputProjectID}
                fetchSuggestions={this.fetchProjectSuggestions}
              ></CvtInput>
              <OrgAndProjectWrapper
                mode={this.mode}
                color={this.topNavConfig.theme.textColor}
              >
                <Titles> Active Org: </Titles>{' '}
                <OrgProjectName>{this.orgId}</OrgProjectName> &gt;
                <Titles> Project: </Titles>{' '}
                <OrgProjectName>{this.projectId}</OrgProjectName>
              </OrgAndProjectWrapper>
            </TopBar>
          )}

          {this.variant !== 'admin' &&
            this.route !== 'checkout' &&
            !this.popupBuilderOpen &&
            !this.topNavConfigForCurrentRoute.logo &&
            !this.topNavConfigForCurrentRoute.showPages &&
            !this.topNavConfigForCurrentRoute.removeBackButtonActionIcon && (
              <LabelBtnWrapper>
                <ExitPreview
                  color="light"
                  shape="pill"
                  size="md"
                  identity="TopNavBackButton2"
                  icon={this.iconConfig?.topNavbar?.back || 'arrow-left'}
                  disabled={false}
                  actionIcon={
                    this.topNavConfigForCurrentRoute.backButtonActionIcon !==
                    undefined
                      ? this.topNavConfigForCurrentRoute.backButtonActionIcon
                      : true
                  }
                  colorOfActionIcon={
                    this.topNavConfigForCurrentRoute
                      .backButtonActionIconColor || ''
                  }
                  modeOfActionIcon={this.topNavConfig?.theme?.actionMode || ''}
                  onClick={(e) => this.click('exit-preview', e)}
                  removeBackground={
                    this.topNavConfig.theme?.actionIconRemoveBG !== undefined
                      ? this.topNavConfig.theme?.actionIconRemoveBG
                      : false
                  }
                  overrideColor={
                    this.topNavConfigForCurrentRoute.overrideColor || ''
                  }
                  overrideHoverBgColor={
                    this.topNavConfigForCurrentRoute.overrideHoverBgColor || ''
                  }
                ></ExitPreview>
              </LabelBtnWrapper>
            )}

          {this.showAddpageBtn && (
            <LabelBtnWrapper>
              <CvtButton
                color="light"
                outlined={true}
                size="sm"
                icon={this.iconConfig?.topNavbar?.page || 'file'}
                onClick={(e) => {
                  e.stopPropagation()
                  this.$emit('top-toggle', 'pages')
                }}
              ></CvtButton>
            </LabelBtnWrapper>
          )}

          {this.route === 'checkout' && (
            <LabelBtnWrapper>
              <ExitButton
                color="light"
                mode={this.mode}
                size="sm"
                icon="chevron-left"
                onClick={() => {
                  this.$emit('checkout-to-pages')
                }}
              ></ExitButton>
            </LabelBtnWrapper>
          )}

          {this.variant !== 'admin' && (
            <div>
              <TemplateName
                mode={this.mode}
                color={this.topNavConfig.theme.textColor}
                title={this.navText}
              >
                {this.navText}
              </TemplateName>
              {this.surveyOpen && (
                <span>
                  &gt;
                  <TemplateName
                    mode={this.mode}
                    color={this.topNavConfig.theme.textColor}
                    title={'Survey Builder'}
                  >
                    Survey Builder
                  </TemplateName>
                </span>
              )}
              {this.popupBuilderOpen && (
                <span>
                  &gt;
                  <TemplateName
                    mode={this.mode}
                    color={this.topNavConfig.theme.textColor}
                    title={'Popup Builder'}
                  >
                    Popup Builder
                  </TemplateName>
                </span>
              )}
              {this.route === 'checkout' && (
                <span>
                  <TemplateName
                    mode={this.mode}
                    color={this.topNavConfig.theme.textColor}
                    title={'Checkout Configuration'}
                  >
                    Checkout Configuration
                  </TemplateName>
                </span>
              )}
            </div>
          )}
        </NavLabel>

        {this.showViewModeRadioBtns && (
          <ViewMode
            rightAlign={
              this.topNavConfigForCurrentRoute.mobileDesktopSwitchRightAlign ||
              false
            }
            centered={
              this.topNavConfigForCurrentRoute.viewModeCenter
            }
          >
            <CvtRadioGroup
              class={'radio-group'}
              orientation="horizontal"
              initialValue={this.viewMode}
            >
              <CvtRadioButton
                onradioClick={() => {
                  this.viewMode = 'desktop'
                  this.click('switch-preview', 'desktop')
                }}
                size="sm"
                value="desktop"
                color="primary"
                // TODO: Need to be refactored in the future using a spanner config
                inActiveSwitchColor={this.type === LIONDESK ? 'WHITE' : ''}
              >
                {this.$t('marketplace.preview.desktop')}
              </CvtRadioButton>
              <CvtRadioButton
                onradioClick={() => {
                  this.viewMode = 'phone'
                  this.click('switch-preview', 'phone')
                }}
                size="sm"
                value="phone"
                color="primary"
                // TODO: Need to be refactored in the future using a spanner config
                inActiveSwitchColor={this.type === LIONDESK ? 'WHITE' : ''}
              >
                {this.$t('marketplace.preview.phone')}
              </CvtRadioButton>
            </CvtRadioGroup>
          </ViewMode>
        )}

        {this.variant !== 'admin' && !this.surveyOpen && (
          <NavActions>
            {this.topNavConfigForCurrentRoute.editorActions === true && (
              <ActionsBtnWrapper>
                <Undo
                  id={'tooltip-topnav-undo-btn'}
                  color="light"
                  mode={this.mode}
                  size="sm"
                  icon={this.iconConfig?.topNavbar?.undo || 'undo'}
                  text={
                    this.topNavConfigForCurrentRoute.noUndoRedoLabel
                      ? ''
                      : this.$t('editor.header.undo')
                  }
                  disabled={this.undoDisabled}
                  onClick={(e) => this.click('undo', e)}
                  outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}
                  btnState={this.redoUndoBtnConfig.state}
                ></Undo>
                {!this.undoDisabled && (
                  <Tooltip
                    key={'tooltip-topnav-undo-btn-key'}
                    target={'tooltip-topnav-undo-btn'}
                    noFade={true}
                  >
                    <strong>
                      {this.isWindows || this.isLinux
                        ? this.$t('shortcut.key.win.undo.full_label')
                        : this.$t('shortcut.key.mac.undo.full_label')}
                    </strong>
                  </Tooltip>
                )}
              </ActionsBtnWrapper>
            )}
            {this.topNavConfigForCurrentRoute.editorActions === true && (
              <ActionsBtnWrapper>
                <Redo
                  id={'tooltip-topnav-redo-btn'}
                  color="light"
                  mode={this.mode}
                  size="sm"
                  icon={this.iconConfig?.topNavbar?.redo || 'redo'}
                  prepend={false}
                  text={
                    this.topNavConfigForCurrentRoute.noUndoRedoLabel
                      ? ''
                      : this.$t('editor.header.redo')
                  }
                  disabled={this.redoDisabled}
                  onClick={(e) => this.click('redo', e)}
                  outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}
                  btnState={this.redoUndoBtnConfig.state}
                ></Redo>
                {!this.redoDisabled && (
                  <Tooltip
                    key={'tooltip-topnav-redo-btn-key'}
                    target={'tooltip-topnav-redo-btn'}
                    disabled={this.redoDisabled}
                    noFade={true}
                  >
                    <strong>
                      {this.isWindows || this.isLinux
                        ? this.$t('shortcut.key.win.redo.full_label')
                        : this.$t('shortcut.key.mac.redo.full_label')}
                    </strong>
                  </Tooltip>
                )}
              </ActionsBtnWrapper>
            )}

            {this.topNavConfigForCurrentRoute.mobileDesktopSwitchRightAlign ===
              true && <VerticalDivider />}

            {this.topNavConfigForCurrentRoute.editorActions === true && (
              <ActionsBtnWrapper>
                {this.topNavConfigForCurrentRoute.mobileDesktopSwitchRightAlign === true ? (
                  <DropDown
                    mode={this.mode}
                    bgColor="light"
                    title="Save snapshot"
                    size="xs"
                    clickAction={true}
                    outlined={true}
                    ondropDownClickAction={(e) =>
                      e === 'vh-save'
                        ? this.click('version-history-save', e)
                        : this.click('version-history', e)
                    }
                  >
                    <option id="vh-save" icon="" value="vh-save">
                      {this.$t(
                        'element.properties.revision.button.dropdown.option.save',
                      )}
                    </option>
                    <option id="vh" icon="" value="vh">
                      {this.$t('element.properties.revision.button.label')}
                    </option>
                  </DropDown>
                ) : (
                  this.topNavConfigForCurrentRoute.versionHistory && (
                    <VersionHistory
                      color="light"
                      mode={this.mode}
                      size="sm"
                      icon={
                        this.iconConfig?.topNavbar?.versionHistory || 'history'
                      }
                      text={
                        this.topNavConfigForCurrentRoute.noVersionHistoryLabel
                          ? ''
                          : this.$t('element.properties.revision.button.label')
                      }
                      loading={this.revisionLoading}
                      onClick={(e) => this.click('version-history', e)}
                      outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}
                      btnState={this.versionHistoryBtnConfig.state}
                    ></VersionHistory>
                  )
                )}
              </ActionsBtnWrapper>
            )}

            {this.topNavConfigForCurrentRoute.mobileDesktopSwitchRightAlign ===
              true && <VerticalDivider />}

            {this.topNavConfigForCurrentRoute.help && (
              <ActionsBtnWrapper>
                <Help
                  color="light"
                  actionIcon={
                    this.topNavConfigForCurrentRoute.helpButtonActionIcon
                      ? true
                      : false
                  }
                  colorOfActionIcon={
                    this.topNavConfigForCurrentRoute.helpButtonIconColor ||
                    'primary'
                  }
                  modeOfActionIcon={this.topNavConfig?.theme?.actionMode || ''}
                  removeBackground={
                    this.topNavConfig.theme.helpActionIconRemoveBG
                      ? true
                      : false
                  }
                  mode={this.mode}
                  size={
                    this.topNavConfigForCurrentRoute.helpButtonIconSize || 'sm'
                  }
                  icon={this.iconConfig?.topNavbar?.help || 'question-circle'}
                  text=""
                  onClick={(e) => this.click('help', e)}
                  btnState={this.redoUndoBtnConfig.state}
                ></Help>
              </ActionsBtnWrapper>
            )}

            {this.showSettingsBtn && (
              <ActionsBtnWrapper>
                <Launch
                  color={
                    this.topNavConfigForCurrentRoute.settingBtnStyle || 'light'
                  }
                  mode={this.mode}
                  size="sm"
                  icon={
                    this.topNavConfigForCurrentRoute
                      .mobileDesktopSwitchRightAlign === true
                      ? this.iconConfig?.leftSidebar?.root?.settings || 'cog'
                      : ''
                  }
                  text={
                    this.topNavConfigForCurrentRoute
                      .mobileDesktopSwitchRightAlign === true
                      ? ''
                      : this.$t(this.topNavConfigForCurrentRoute.settingText)
                  }
                  onClick={(e) => {
                    this.click('top-settings', 'settings')
                  }}
                  outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}
                ></Launch>
              </ActionsBtnWrapper>
            )}

            {this.showSitePreviewBtn && (
              <ActionsBtnWrapper>
                {this.type !== LEFT_SIDEBAR_FRESHWORKS && (
                  <Preview
                    color={this.topNavConfigForCurrentRoute.previewBtnStyle}
                    mode={this.mode}
                    size="sm"
                    icon={this.iconConfig?.topNavbar?.preview || 'eye'}
                    prepend={true}
                    text={this.$t(this.topNavConfigForCurrentRoute.previewText)}
                    onClick={(e) => this.goToSitePreview('site-preview', e)}
                    outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}
                  ></Preview>
                )}
                {this.type === LEFT_SIDEBAR_FRESHWORKS && (
                  <Preview
                    specialStyle="dark"
                    mode={this.mode}
                    size="sm"
                    icon={this.iconConfig?.topNavbar?.preview || 'eye'}
                    text="Preview"
                    prepend={true}
                    onClick={(e) => this.goToSitePreview('site-preview', e)}
                    outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}
                  ></Preview>
                )}
              </ActionsBtnWrapper>
            )}

            {this.showLaunchBtn && (
              // TODO: Need to be refactored in the future using a spanner config
              <ActionsBtnWrapper>
                {this.type !== LEFT_SIDEBAR_FRESHWORKS &&
                  !this.disablePublish && (
                    <Launch
                      color={this.topNavConfigForCurrentRoute.launchBtnStyle}
                      mode={this.mode}
                      size="sm"
                      icon={
                        this.topNavConfigForCurrentRoute.launchIcon
                          ? this.iconConfig?.topNavbar?.launch ||
                            'external-link-alt'
                          : ''
                      }
                      prepend={false}
                      text={this.$t(
                        this.topNavConfigForCurrentRoute.launchText,
                      )}
                      onClick={(e) => this.click('launch', e)}
                      outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}
                    ></Launch>
                  )}
                {this.type === LEFT_SIDEBAR_FRESHWORKS && (
                  // TODO: Need to be refactored in the future using a spanner config
                  <Launch
                    specialStyle="dark"
                    mode={this.mode}
                    size="sm"
                    text="Publish"
                    onClick={(e) => this.click('launch', e)}
                    outlined={this.topNavConfig.editor?.topNavButtonBorder ?? false}

                  ></Launch>
                )}
              </ActionsBtnWrapper>
            )}

            {this.topNavConfigForCurrentRoute.useThisTemplate && (
              <ActionsBtnWrapper>
                <UseTemplate
                  specialStyle={
                    this.type === LEFT_SIDEBAR_FRESHWORKS ? 'dark' : ''
                  }
                  color={this.topNavConfigForCurrentRoute.useThisTemplate}
                  mode={this.mode}
                  size="sm"
                  icon={
                    this.topNavConfigForCurrentRoute.useThisTemplateIcon
                      ? this.iconConfig?.topNavbar?.next || 'arrow-right'
                      : this.onGenaiWizard ? 'external-link' : ''
                  }
                  prepend={false}
                  text={this.previewTopNavButtonText}
                  onClick={(e) => this.click('use-template', e)}
                ></UseTemplate>
              </ActionsBtnWrapper>
            )}

            {this.topNavConfigForCurrentRoute.exit && (
              <ActionsBtnWrapper>
                <Exit
                  color="light"
                  mode={this.mode}
                  size="sm"
                  icon={
                    this.topNavConfigForCurrentRoute.exitIcon
                      ? this.iconConfig?.topNavbar?.exit || 'times'
                      : ''
                  }
                  prepend={false}
                  text={this.$t(this.topNavConfigForCurrentRoute.exitText)}
                  outlined={
                    this.topNavConfigForCurrentRoute.exitOutlined || false
                  }
                  onClick={(e) => this.click('exit', e)}
                  disabled={this.disableExit}
                ></Exit>
              </ActionsBtnWrapper>
            )}

            {this.showScheduleDemoBtn && (
              <ActionsBtnWrapper>
                <ScheduleDemo
                  mode="LIGHT"
                  color="light"
                  icon="phone"
                  text={this.$t('enterprise.header.top_nav.demo_btn_text')}
                  prepend={false}
                  rotateIcon="90"
                  onClick={this.scheduleDemo}
                ></ScheduleDemo>
              </ActionsBtnWrapper>
            )}

            {this.showEntDashboardBtn && (
              <ActionsBtnWrapper>
                <EntDashboardBtn
                  mode="LIGHT"
                  color="light"
                  icon="dashboard"
                  prepend={false}
                  text="Go To Dashboard"
                  onClick={this.gotoEntDashboard}
                ></EntDashboardBtn>
              </ActionsBtnWrapper>
            )}

            {this.showUserAvatar && (
              <UserAvatar
                mode={this.mode}
                backgroundColor={this.topNavConfig.theme.backgroundColor}
                height={
                  this.topNavConfig.theme?.height
                    ? this.topNavConfig.theme?.height
                    : this.Spacing.SPACING_64
                }
                userNameInitials={this.UserNameInitials}
              ></UserAvatar>
            )}
          </NavActions>
        )}

        {this.surveyOpen && (
          <ActionsBtnWrapper>
            <Launch
              color={this.topNavConfigForCurrentRoute.launch}
              mode={this.mode}
              size="sm"
              icon={
                this.topNavConfigForCurrentRoute.launchIcon
                  ? this.iconConfig?.topNavbar?.launch || 'external-link-alt'
                  : ''
              }
              prepend={false}
              text="Done"
              onClick={() => {
                this.$emit('toggle-survey')
              }}
            ></Launch>
          </ActionsBtnWrapper>
        )}

        {this.popupBuilderOpen && (
          <ActionsBtnWrapper>
            <Launch
              color={this.topNavConfigForCurrentRoute.launch}
              specialStyle={this.popupDoneBtnConfig.specialStyle}
              mode={this.mode}
              size="sm"
              icon={
                this.topNavConfigForCurrentRoute.launchIcon
                  ? this.iconConfig?.topNavbar?.launch || 'external-link-alt'
                  : ''
              }
              prepend={false}
              text="Done"
              onClick={() => {
                this.$emit('toggle-popup')
              }}
            ></Launch>
          </ActionsBtnWrapper>
        )}
      </TopNav>
    )
  },
}
