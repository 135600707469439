import Vue from 'vue'
import GridItemWrapper from './editor/nodes/GridItemWrapper.vue'
import CCard from "./editor/nodes/card/CCard.vue";
import CEmbeddedForm from "./editor/nodes/CEmbeddedForm.vue";
import MigrationProcess from "./MigrationProcess.vue";

Vue.component('GridItemV3', GridItemWrapper)
Vue.component('CCard', CCard)
Vue.component('CEmbeddedForm', CEmbeddedForm)
Vue.component('MigrationProcess', MigrationProcess)
