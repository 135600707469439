<template>
  <div>
    <div class="d-flex mb-5">
      <cvt-input
        class="marketplace-branding-search flex-grow-1"
        type="search"
        :mode="mode"
        :value="searchTerm"
        :no-label="true"
        suffix-icon="search"
        :show-input-border="false"
        :fetch-suggestions="fetchSuggestions"
        @input="
          ({ target: { value } }) => {
            searchTerm = value
          }
        "
        @select="onSelect"
      ></cvt-input>

      <div
        class="d-flex justify-content-end flex-grow-0"
        style="flex-basis: 25%"
      >
        <cvt-button
          class="rounded-lg"
          color="dark"
          :text="$t('marketplace.branding.list.create.new.branding')"
          @click="onCreateNewBranding"
        />
      </div>
    </div>

    <div v-if="searchSelected" class="mb-5">
      <div
        class="mb-3 font-weight-bold"
        style="font-size: 14px; font-family: 'Nunito'; font-style: italic"
      >
        {{ $t('marketplace.branding.list.search.result') }}
      </div>
      <div
        class="d-flex w-100 px-4 py-3 align-items-center justify-content-between brand-block"
      >
        <div class="brand-block-name">
          {{ selectedSearchItem.brandingName }}
        </div>
        <div class="d-flex">
          <cvt-button
            class="rounded-lg my-2"
            style="color: #494c53; border: 1px solid #494c53"
            color="light"
            icon="cvt-icon-pencil"
            :prepend="true"
            :outlined="true"
            :text="'Edit'"
            @click="() => editBranding(selectedSearchItem)"
          />

          <cvt-button
            class="rounded-lg ml-3 my-2"
            style="color: #494c53; border: 1px solid #494c53"
            color="light"
            icon="trash"
            :prepend="true"
            :outlined="true"
            :text="'Delete'"
            @click="() => toggleDeleteDialog(selectedSearchItem)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="searchSelected"
      class="mb-2 font-weight-bold"
      style="font-size: 14px; font-family: 'Nunito'; font-style: italic"
    >
      {{ $t('marketplace.branding.list.other.branding.themes') }}
    </div>
    <div
      v-for="brand in brandingData"
      :key="brand.idx"
      class="d-flex w-100 px-4 py-3 align-items-center justify-content-between brand-block"
    >
      <div class="brand-block-name">{{ brand.brandingName }}</div>
      <div class="d-flex">
        <cvt-button
          class="rounded-lg my-2"
          style="color: #494c53; border: 1px solid #494c53"
          color="light"
          icon="cvt-icon-pencil"
          :prepend="true"
          :outlined="true"
          :text="'Edit'"
          @click="() => editBranding(brand)"
        />

        <cvt-button
          class="rounded-lg ml-3 my-2"
          style="color: #494c53; border: 1px solid #494c53"
          color="light"
          icon="trash"
          :prepend="true"
          :outlined="true"
          :text="'Delete'"
          @click="() => toggleDeleteDialog(brand)"
        />
      </div>
    </div>
    <div v-if="!brandingData.length" class="d-flex justify-content-center">
      <p style="font-size: 13px; opacity: 70%">
        {{ $t('marketplace.branding.list.no.data') }}
        <span
          style="text-decoration: underline; cursor: pointer"
          @click="onCreateNewBranding"
        >
          {{ $t('marketplace.branding.list.create.new.branding') }}
        </span>
      </p>
    </div>

    <DeleteBrandDialog
      :delete-dialog-visible="deleteDialogVisible"
      :branding-name="selectedBrandForDeletion?.brandingName"
      @cancel="toggleDeleteDialog"
      @delete="deleteBranding"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BRAND_MODE_CREATE } from '@/js/common/constants/constants'
import DeleteBrandDialog from './DeleteBrandDialog.vue'

import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'

export default {
  components: {
    DeleteBrandDialog,
  },
  data() {
    return {
      mode: 'LIGHT',
      loadingBrandingData: false,
      deleteDialogVisible: false,
      selectedBrandForDeletion: null,
      searchTerm: '',
      selectedSearchItem: null,
      searchSelected: false,
    }
  },
  computed: {
    ...mapGetters({
      brandingData: getters.MP_GET_BRANDS,
    }),

    inputData() {
      return this.brandingData.map((brand) => ({
        idx: brand.idx,
        value: brand.brandingName,
        label: brand.brandingName,
      }))
    },
  },
  methods: {
    ...mapActions({
      setViewMode: actions.MP_SET_MANAGER_MODE,
      initCreateManager: actions.MP_INIT_CREATE_MANAGER,
      deleteBrand: actions.MP_DELETE_BRAND,
    }),
    onCreateNewBranding() {
      this.initCreateManager()
      this.setViewMode(BRAND_MODE_CREATE)
    },
    toggleDeleteDialog(brand = null) {
      this.selectedBrandForDeletion = brand
      this.deleteDialogVisible = !this.deleteDialogVisible
    },
    deleteBranding() {
      if (this.selectedBrandForDeletion) {
        this.deleteBrand(this.selectedBrandForDeletion)
          .then(() => {
            this.toggleDeleteDialog()
            this.$message({
              type: 'info',
              message: 'Branding deleted successfully',
            })

            if (this.searchSelected) {
              this.searchSelected = false
              this.selectedSearchItem = null
            }
          })
          .catch((error) => {
            console.error('Error deleting brand:', error)
          })
      }
    },
    editBranding(brand) {
      this.initCreateManager(brand)
      this.setViewMode(BRAND_MODE_CREATE)
    },
    fetchSuggestions(term, cb) {
      clearTimeout(this.timeout)
      let results = term
        ? this.inputData.filter((item) => {
            return item.value.toLowerCase().indexOf(term.toLowerCase()) === 0
          })
        : this.inputData

      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        cb(results)
      }, 1000 * Math.random())
    },
    onSelect(item, idx) {
      this.searchTerm = item.value
      const selectedItem = this.brandingData.find((brand) => brand.idx === idx)

      if (selectedItem) {
        this.selectedSearchItem = selectedItem
        this.searchSelected = true
      }
    },
  },
}
</script>

<style scoped></style>
