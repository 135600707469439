
<div class="colors">
  <div
    v-if="swatchVisible"
    class="color-swatch py-3 d-flex flex-wrap mx-auto"
  >
    <div
      v-if="canAddNewColors"
      class="theme-color theme-color--new d-flex flex-column align-items-center justify-content-around"
      data-identity="add-new-theme-color"
      @click.stop.prevent="addNewColorShowPicker"
    >
      <i class="material-icons">add</i>
      <small>{{ $t('elements.properties.color.add') }}</small>
    </div>

    <div
      v-for="(color, key) in colors"
      :key="key"
      class="theme-color theme-color--editable"
      :class="isActiveColor(key)"
      :style="colorToStyle(color)"
      @click.stop="applyColorsAll({ colorId: key, toBg: toBg })"
    >
      <div
        v-if="canEditColors"
        class="theme-color__edit__btn"
        @click.stop="enableColorPicker(color, key)"
      >
        {{ $t('editor.right_nav.theme.color_picker.edit.btn') }}
      </div>
    </div>
  </div>

  <div v-else-if="colorPickerVisible" class="color-picker">
    <div class="row no-gutters">
      <div class="col-auto">
        <color-picker
          ref="picker"
          :current-color="currentColor.color"
          :valpha="currentColor.valpha"
          @change="updateCurrentColor"
        />
      </div>
    </div>

    <div class="row">
      <div class="col d-flex mt-1">
        <cvt-button
          class="site-settings-btns mr-1"
          :color="defaultSiteSettingsColorsCompBtnConfig.props.save.color"
          :special-style="
            defaultSiteSettingsColorsCompBtnConfig.props.save.specialStyle
          "
          :outlined="
            defaultSiteSettingsColorsCompBtnConfig.props.save.outlined
          "
          :text="$t('editor.right_nav.theme.color_picker.save.btn')"
          @click.prevent.stop="disableColorPicker"
        >
        </cvt-button>
        <cvt-button
          class="px-3 mr-1"
          mode="dark"
          color="light"
          :outlined="true"
          btn-text-color="#000"
          :text="
            $t(
              'editor.left_nav.page_manager.layout_dialog.template.footer.cancel.btn',
            )
          "
          @click.prevent.stop="resetToCurrentColor"
        >
        </cvt-button>
        <cvt-button
          class="px-3"
          :color="defaultSiteSettingsColorsCompBtnConfig.props.delete.color"
          :special-style="
            defaultSiteSettingsColorsCompBtnConfig.props.delete.specialStyle
          "
          :outlined="
            defaultSiteSettingsColorsCompBtnConfig.props.delete.outlined
          "
          icon="trash"
          @click.prevent.stop="toggleRemoveColorDialog"
        >
        </cvt-button>
      </div>
    </div>
  </div>

  <cvt-dialog
    :show="removeColorVisisble"
    :height-auto="true"
    :border-radius="'8px'"
    size="sm"
    bg-color="#fff"
    :show-close="false"
  >
    <template #title>
      <h3 class="del-color-warn-title">
        {{ $t('marketplace.branding.list.delete.color.title') }}
      </h3>
    </template>

    <template #default>
      <h5 class="del-color-warn-subtitle">
        {{ $t('marketplace.branding.list.delete.color.subtitle') }}
      </h5>
    </template>

    <template #modalFooter>
      <div class="d-flex">
        <cvt-button
          class="mr-2"
          :text="$t('marketplace.dialog.archive.cta.cancel')"
          v-bind="cancelBtnProps"
          @click.stop="toggleRemoveColorDialog"
        />
        <cvt-button
          :text="$t('marketplace.dialog.archive.cta.submit')"
          v-bind="submitBtnProps"
          class="mr-2"
          @click.stop="deleteColorFromPalette"
        />
      </div>
    </template>
  </cvt-dialog>
</div>
