
<div class="dynamic-image" @mouseenter.stop="showOverlay" @mouseleave.stop="hideOverlay" @click="click">
  <fade-in-out :duration="250">
    <div v-show="overlayVisible" class="dynamic-image__overlay flex-column justify-content-center align-items-center">
      <i v-show="loading" class="fa fa-spinner loading-icon"></i>
      <small :style="msgStyle" v-text="overlayMsg"></small>
    </div>
  </fade-in-out>
    <img v-show="!failed" class="img-fluid" :src="src" :alt="alt" @load="endLoader" @error="failLoading">
</div>
