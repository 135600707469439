<template>
  <GenAILayout>
    <div class="landingpage-goal-container">
      <div class="landingpage-goal-inner-container">
        <div class="landingpage-goal-wrapper">
          <h1 class="landingpage-goal-header">
            {{ $t('gen_ai.intent.description') }}
          </h1>

          <div
            v-for="(intentGoal, index) in intentGoals"
            :key="index"
            class="landingpage-goal-card-wrapper"
          >
            <cvt-goal-card
              :header-text="intentGoal.label"
              :icon="''"
              :is-active="intentGoal.value === selectedIntentGoal?.value"
              :value="intentGoal.value"
              @click="() => handleChange(intentGoal)"
            >
            </cvt-goal-card>
          </div>
        </div>
      </div>
      <hr />
      <div class="landingpage-goal-wrap">
        <div class="landingpage-goal-inner-btn-wrap">
          <cvt-button
            class="landingpage-goal-btn skip"
            :text="$t('editor.footer.back')"
            shape="round"
            color="light"
            :prepend="false"
            @click="handleBack"
          ></cvt-button>

          <cvt-button
            class="landingpage-goal-btn continue"
            :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
            shape="round"
            color="primary"
            icon="arrow-right"
            :prepend="false"
            :loading="loading"
            :disabled="!selectedIntentGoal?.value"
            @click="goToBrandPreference"
          ></cvt-button>
        </div>
      </div>
    </div>
  </GenAILayout>
</template>

<script>
import { cvtRouteNodes, cvtRoutes } from '../../utils/routes'
import {
  GenAIIntent,
  getSessionGenAIOnboarding,
  getSessionItemGenAIOnboarding,
  setGenAITemplate,
  setSessionGenAiOnboarding,
} from './utils'
import { Notification } from 'element-ui'
import { V1GenAI } from '@/js/store/services/api'

import GenAILayout from './baseLayout.vue'
const genAIApi = new V1GenAI()
export default {
  name: 'IntentGoalSelection',
  components: {
    GenAILayout,
  },
  props: {
    // Your props here
  },
  data() {
    return {
      intentGoals: [],
      selectedIntentGoal: null,
      loading: false,
    }
  },
  computed: {
    // Your computed properties here
  },
  mounted() {
    const onBoardingData = getSessionGenAIOnboarding() || []
    const intentFilter = GenAIIntent.filter(
      (x) => x.value === onBoardingData.intent,
    )

    if (intentFilter.length > 0) {
      this.intentGoals = [...intentFilter[0].goals]

      const selectedIntentGoal = getSessionItemGenAIOnboarding('intentGoal')
      const selectedIntentGoalFilter = this.intentGoals.filter(
        (x) => x.value === selectedIntentGoal,
      )

      if (selectedIntentGoalFilter.length > 0) {
        this.selectedIntentGoal = selectedIntentGoalFilter[0]
      }
    } else {
      Notification({
        title: 'woops',
        message: 'Onboarding did not follow due process',
        type: 'error',
        position: 'bottom-right',
      })
    }
  },
  methods: {
    handleChange(intentGoal) {
      this.selectedIntentGoal = intentGoal
      setSessionGenAiOnboarding('intentGoal', this.selectedIntentGoal.value)
    },
    handleBack() {
      this.$router.push({
        name: cvtRouteNodes.landingPageGoal,
      })
    },
    goToBrandPreference() {
      this.$router.push({
        name: cvtRoutes.genAISelectBrand.name,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/IntentSelection.style.scss';
@import './styles/LandingPageGoal.style.scss';
</style>
