import firebase from 'firebase/app'
import {
  updateTypographyCSSX,
  updateFrolaLinkTypographyCSSX,
  updateThemeColorsCSSX,
  addFontClassesToSheetCSSX,
  addFontSizesToSheetCSSX,
} from '../utils'

import _ from 'lodash'
import color, { Color } from 'color'
import { DEFAULT_COLOR, rgbaToColor } from '../../../../lib/color-utils'
import {
  FroalaFormat,
  ButtonFormat,
  FroalaLinkFormat,
  GOOGLE_FONTS,
  FONT_SIZES,
} from '../../../../lib/font'
import {
  DEFAULT_TYPOGRAPHY,
  DEFAULT_BUTTON_TYPOGRAPHY,
  DEFAULT_FROALA_LINK_TYPOGRAPHY,
} from './fonts'
import * as webfont from 'webfontloader'
import { ThemeData, ThemeState } from 'types'
import { database } from '../../firebase.service'
import {
  ButtonTypography,
  FroalaLinkTypography,
  IThemeManager,
  Typography,
} from '../types'

webfont.load({
  google: {
    families: GOOGLE_FONTS.filter((f) => f.source === 'google').map((f) =>
      f.webfontName(),
    ),
  },
})

const DEFAULT_PALETTE_COLORS: { a: number; hex: string }[] = [
  {
    a: 1,
    hex: '#e64a19',
  },
  {
    a: 1,
    hex: '#ffa000',
  },
  {
    a: 1,
    hex: '#43a047',
  },
  {
    a: 1,
    hex: '#384cad',
  },
  {
    a: 1,
    hex: '#ffffff',
  },
  {
    a: 1,
    hex: '#212121',
  },
]

export class ThemeManagerV1 implements IThemeManager {
  defaultState: { [key: string]: any } = {
    colors: {},
    blockColors: {},
    typography: DEFAULT_TYPOGRAPHY,
    buttonTypography: DEFAULT_BUTTON_TYPOGRAPHY,
    froalaLinkTypography: DEFAULT_FROALA_LINK_TYPOGRAPHY,
    availableFonts: [...GOOGLE_FONTS],
    defaultColor: DEFAULT_COLOR,
  }

  protected db: firebase.database.Database
  public themeRef: firebase.database.Reference
  public fbSitePath: string

  constructor(fbSitePath: string) {
    this.db = database()
    this.themeRef = this.db.ref(fbSitePath).child('theme')
    this.fbSitePath = fbSitePath
    console.debug('THEME_V1: Initializing Theme Manager V1')
  }

  get version() {
    return 1
  }

  public canAddNewColors() {
    return true
  }

  public canEditColors() {
    return true
  }

  public async loadDefaults() {
    console.debug('Using Theme Manager V1')
  }

  public async initThemeLoad(): Promise<ThemeState> {
    // Load defaults
    await this.loadDefaults()

    return await new Promise((resolve) => {
      const siteRef = this.db.ref(this.fbSitePath)
      siteRef.child('theme').once('value', (snapshot) => {
        const value = snapshot.val()

        this.themeSetup(this.defaultState as ThemeState, value).then(
          (newState: ThemeState) => {
            resolve(newState)
          },
        )
      })
    })
  }

  public getDefaultState() {
    return this.defaultState
  }

  public cloneState(state: ThemeState) {
    return Object.assign({}, state)
  }

  public toColor(colorId: string, colors: { [key: string]: Color }) {
    return colorId
  }

  // Mutations
  public updateThemeData(state: ThemeState, theme: ThemeData) {
    console.debug('THEME_V1: Updating theme data')
    let _state = this.cloneState(state)

    _state.colors = theme.colors
      ? _.mapValues(theme.colors, (c: any) => {
          return c.rgba ? rgbaToColor(c) : color(c.hex)
        })
      : {}

    _state.blockColors = theme.blockColors
      ? _.mapValues(theme.blockColors, (c) => {
          return color(c.hex)
        })
      : {}

    _state.typography =
      theme.typography &&
      theme.typography.title &&
      theme.typography.title.defaultTextColor &&
      theme.typography.title.defaultAltTextColor
        ? _.mapValues(theme.typography, (v) => new FroalaFormat(v))
        : DEFAULT_TYPOGRAPHY

    _state.buttonTypography = theme.buttonTypography
      ? _.mapValues(theme.buttonTypography, (v) => {
          if (v instanceof ButtonFormat) {
            return v
          }
          return new ButtonFormat(v)
        })
      : DEFAULT_BUTTON_TYPOGRAPHY

    _state.froalaLinkTypography =
      theme.froalaLinkTypography &&
      theme.froalaLinkTypography.link &&
      theme.froalaLinkTypography.link.defaultTextColor
        ? _.mapValues(theme.froalaLinkTypography, (v) => {
            if (v instanceof FroalaLinkFormat) {
              return v
            }
            return new FroalaLinkFormat(v)
          })
        : DEFAULT_FROALA_LINK_TYPOGRAPHY

    _state.brandLogo = theme.brandLogo

    updateTypographyCSSX(_state.typography)
    updateFrolaLinkTypographyCSSX(_state.froalaLinkTypography)
    updateThemeColorsCSSX(_state.colors)
    updateThemeColorsCSSX(_state.blockColors)
    addFontClassesToSheetCSSX(GOOGLE_FONTS)
    addFontSizesToSheetCSSX(FONT_SIZES)

    return _state
  }

  public setFont(
    state: ThemeState,
    { style, format }: { style: string; format: FroalaFormat },
  ) {
    console.debug('THEME_V1: Setting font', { style })
    state.typography[style as keyof Typography] = new FroalaFormat(format)
    updateTypographyCSSX(state.typography)
    return { typography: state.typography }
  }

  public setButtonDefaultStyles(
    state: ThemeState,
    { item, format }: { item: string; format: ButtonFormat },
  ) {
    console.debug('THEME_V1: Setting button default styles', { item })
    state.buttonTypography[item as keyof ButtonTypography] = new ButtonFormat(
      format,
    )
    return { buttonTypography: state.buttonTypography }
  }

  public setFroalaLinkDefaultStyles(
    state: ThemeState,
    { item, format }: { item: string; format: FroalaLinkFormat },
  ) {
    console.debug('THEME_V1: Setting Froala link default styles', { item })
    state.froalaLinkTypography[item as keyof FroalaLinkTypography] =
      new FroalaLinkFormat(format)
    updateFrolaLinkTypographyCSSX(state.froalaLinkTypography)
    return { froalaLinkTypography: state.froalaLinkTypography }
  }

  public async themeSetup(state: ThemeState, theme: ThemeData) {
    console.debug('THEME_V1: Setting up theme')
    let clonedState = this.cloneState(state)
    if (
      theme &&
      theme.typography &&
      Object.keys(theme.typography).includes('h1')
    ) {
      // @ts-expect-error
      theme.typography = null
    }

    if (theme) {
      if (!theme.colors) {
        const newColors = await Promise.all(
          DEFAULT_PALETTE_COLORS.map((defaultColor) =>
            this.addNewColor(clonedState, color(defaultColor.hex)),
          ),
        )
        clonedState.colors = Object.assign(clonedState.colors, ...newColors)
      }
      clonedState = Object.assign({}, this.updateThemeData(clonedState, theme))
    }

    if (!theme || !theme.typography) {
      await this.themeRef.child('typography').set(clonedState.typography)
    }

    // update newly added typography
    let typographyKeys = Object.keys(DEFAULT_TYPOGRAPHY)
    let newKeysExist = typographyKeys.some(
      (key) => !clonedState.typography[key as keyof Typography],
    )

    if (newKeysExist) {
      for (let key of typographyKeys) {
        if (!clonedState.typography[key as keyof Typography]) {
          clonedState.typography[key as keyof Typography] =
            DEFAULT_TYPOGRAPHY[key as keyof Typography]
        }
      }
      await this.themeRef.child('typography').set(clonedState.typography)
    }

    if (
      !theme ||
      !theme.buttonTypography ||
      !theme.buttonTypography.button ||
      !theme.buttonTypography.button.fontWeight ||
      !theme.buttonTypography.button.subtextStyle
    ) {
      await this.themeRef
        .child('buttonTypography')
        .set(this.defaultState.buttonTypography)
    }

    if (!theme || !theme.froalaLinkTypography) {
      await this.themeRef
        .child('froalaLinkTypography')
        .set(this.defaultState.froalaLinkTypography)
    }
    return clonedState
  }

  // Actions
  // @ts-expect-error
  public async updateColor(
    state: ThemeState,
    { color, key }: { color: Color; key: string },
  ) {
    console.debug('THEME_V1: Updating color', { key, color: color.hex() })
    let colorRef = this.themeRef.child('colors').child(key)

    await colorRef.set({
      a: color.alpha(),
      hex: color.hex().toLowerCase(),
    })
    const newColors = {
      ...state.colors,
      ...{ [colorRef.key as string]: color },
    }
    updateThemeColorsCSSX(newColors)
    return [colorRef.key, { colors: newColors }]
  }

  // @ts-expect-error
  public async addNewColor(state: ThemeState, color: Color) {
    console.debug('THEME_V1: Adding new color', { color: color.hex() })
    let colorRef = this.themeRef.child('colors').push()

    await colorRef.set({
      a: color.alpha(),
      hex: color.hex().toLowerCase(),
    })
    const newColors = {
      ...state.colors,
      ...{ [colorRef.key as string]: color },
    }
    updateThemeColorsCSSX(newColors)
    return [colorRef.key, { colors: newColors }]
  }

  // @ts-expect-error
  public async addColorsFromBlock(
    state: ThemeState,
    colors: { [key: string]: Color },
  ) {
    console.debug('THEME_V1: Adding colors from block', {
      colorCount: Object.keys(colors).length,
    })
    let chain = _.chain(colors)
      .keysIn()
      .map((colorId) =>
        this.themeRef.child('blockColors').child(colorId).set(colors[colorId]),
      )

    await Promise.all(chain as unknown as Promise<any>[])
    return [
      {
        blockColors: {
          ...state.blockColors,
          ..._.mapValues(colors, (colorData) => color(colorData.hex)),
        },
      },
    ]
  }

  public async addFont(state: ThemeState, typography: Typography) {
    console.debug('THEME_V1: Adding font')
    return await this.themeRef.child('typography').set(typography)
  }

  public async addBrandLogo(state: ThemeState, logo: string) {
    console.debug('THEME_V1: Adding brand logo')
    return await this.themeRef.child('brandLogo').set(logo)
  }

  public async removeColor(state: ThemeState, colorId: string) {
    console.debug('THEME_V1: Removing color', { colorId })
    await this.themeRef.child('colors').child(colorId).remove()
  }

  public async addButtonDefaultStyles(
    state: ThemeState,
    buttonTypography: ButtonTypography,
  ) {
    return await this.themeRef.child('buttonTypography').set(buttonTypography)
  }

  public async addFroalaLinkDefaultStyles(
    state: ThemeState,
    froalaLinkTypography: FroalaLinkTypography,
  ) {
    return await this.themeRef
      .child('froalaLinkTypography')
      .set(froalaLinkTypography)
  }

  destroy() {
    this.themeRef.off('value')
  }
}
