<template>
  <GenAILayout>
    <div class="bp-container">
      <div class="bp-header">
        <p class="bp-title">
          {{ $t('gen_ai.brand.preference.title') }}
        </p>
        <div>
          <cvt-button
            class="magic-btn"
            :text="$t('gen_ai.brand.preference.create_btn')"
            icon="plus"
            :prepend="false"
            @click="() => (modalVisible = true)"
          />
        </div>
      </div>

      <div class="bp-cards-container">
        <template v-for="brand in getBrandingData">
          <div
            :key="brand.idx"
            class="bp-card"
            :class="{ 'bp-card-selected': selectedBrandID === brand.idx }"
            @click="handleSelectBrand(brand.idx)"
          >
            <div class="bp-card-header">
              <div class="bp-card-title">
                New Kit - {{ brand.brandingName }}
              </div>
              <div class="bp-dropdown-container">
                <div
                  class="bp-dropdown-trigger"
                  @click="() => toggleBrandDropdown(brand.idx)"
                >
                  <cvt-icon class="bp-icon-small" icon="ellipsis-v" />
                </div>
                <div
                  v-if="activeDropdown === brand.idx"
                  class="bp-dropdown-menu"
                  @mouseleave="activeDropdown = null"
                >
                  <div class="dropdown-item" @click="handleEditKit(brand)">
                    <cvt-icon icon="edit" style="margin-right: 8px" />
                    Edit Kit
                  </div>
                  <div class="dropdown-item" @click="handleDuplicateKit(brand)">
                    <cvt-icon icon="copy" style="margin-right: 8px" />
                    Duplicate Kit
                  </div>
                  <div class="dropdown-item" @click="handleDeleteKit(brand)">
                    <cvt-icon
                      icon="trash"
                      style="margin-right: 8px; color: #dc3545"
                    />
                    Delete Kit
                  </div>
                </div>
              </div>
            </div>
            <div class="bp-card-content">
              <!--            <div class="bp-preview-container">-->
              <!--              <div class="bp-preview-box">-->
              <!--                <div class="bp-preview-placeholder"></div>-->
              <!--              </div>-->
              <!--            </div>-->
              <div>
                <div class="bp-colors-container">
                  <div
                    v-for="(color, idx) in Object.values(
                      brand.globalStyles.colors,
                    ).slice(0, 3)"
                    :key="idx"
                    class="bp-color-box"
                    :style="colorToStyle(color)"
                  ></div>
                  <div v-if="Object.keys(brand.globalStyles.colors).length > 3">
                    <div
                      :id="`tooltip-${brand.idx}`"
                      class="bp-more-colors-btn"
                    >
                      <cvt-button class="mb-2" icon="ellipsis-h" />
                    </div>

                    <cvt-tooltip
                      :show="activeColorDropdown === brand.idx"
                      :delay="20"
                      :no-fade="true"
                      :placement="'left'"
                      :color="'light'"
                      :mode="'LIGHT'"
                      :boundary-padding="0"
                      :inner-padding="0"
                      :triggers="'click'"
                      :target="`tooltip-${brand.idx}`"
                      @show="toggleColorDropdown(brand.idx)"
                      @hide="activeColorDropdown = null"
                    >
                      <div class="bp-tooltip-wrapper">
                        <div
                          v-for="(color, idx) in Object.values(
                            brand.globalStyles.colors,
                          ).slice(3)"
                          :key="idx"
                          class="bp-theme-color"
                          :style="colorToStyle(color)"
                        ></div>
                      </div>
                    </cvt-tooltip>
                  </div>
                </div>

                <div class="bp-typography-section">
                  <div class="bp-typography-label">HEADING</div>
                  <div class="bp-typography-heading">
                    {{ brand.theme.typography.heading.font }}
                  </div>
                </div>

                <div class="bp-typography-section">
                  <div class="bp-typography-label">BODY</div>
                  <div class="bp-typography-body">
                    {{ brand.theme.typography.normalText.font }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <hr class="bp-divider" />

      <div class="bp-footer">
        <cvt-button
          :disabled="!selectedBrandID"
          :loading="loading"
          class="magic-btn"
          :text="$t('gen_ai.intent.continue_btn')"
          :icon="'cvt-icon-arrow-right-05'"
          :prepend="false"
          @click="handleLoadContent"
        />
      </div>
    </div>

    <cvt-dialog
      :show="modalVisible"
      :hide-borders="true"
      :width="'90%'"
      @close="onCloseModal"
    >
      <div class="integrations-wrapper">
        <marketplace-branding :on-brand-preference-view="true" />
      </div>
    </cvt-dialog>

    <DeleteBrandDialog
      :delete-dialog-visible="deleteDialogVisible"
      :branding-name="selectedBrandForDeletion?.brandingName"
      @cancel="toggleDeleteDialog"
      @delete="deleteBranding"
    />
  </GenAILayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cvtRouteNodes, cvtRoutes } from '../../utils/routes'
import {
  getSessionGenAIOnboarding,
  getSessionItemGenAIOnboarding,
  setGenAITemplate,
  setSessionGenAiOnboarding,
} from './utils'
import { Notification } from 'element-ui'
import { V1GenAI } from '@/js/store/services/api'
import * as getters from '@/js/store/modules/getters'
import * as actions from '@/js/store/modules/actions'
import MarketplaceBranding from '../marketplace/branding/index.vue'
import DeleteBrandDialog from '../marketplace/branding/DeleteBrandDialog.vue'
import { BRAND_MODE_CREATE } from '@/js/common/constants/constants'

import GenAILayout from './baseLayout.vue'
const genAIApi = new V1GenAI()
export default {
  name: 'GenAIBrandPreference',
  components: {
    GenAILayout,
    MarketplaceBranding,
    DeleteBrandDialog,
  },
  props: {
    // Your props here
  },
  data() {
    return {
      loading: false,
      modalVisible: false,
      activeDropdown: null,
      selectedBrandForDeletion: null,
      deleteDialogVisible: false,
      activeColorDropdown: null,
      selectedBrandID: null,
    }
  },
  computed: {
    ...mapGetters({
      brandingData: getters.MP_GET_BRANDS,
    }),
    getBrandingData() {
      return this.brandingData || []
    },
  },
  async mounted() {
    console.log('brandingData', this.brandingData)
    await this.initBrandManager()
    const selectedBrandID = getSessionItemGenAIOnboarding('selectedBrandID')
    if (selectedBrandID) {
      if (this.brandingData.find((x) => x.idx === selectedBrandID)) {
        this.selectedBrandID = selectedBrandID
      }
    }
  },
  methods: {
    ...mapActions({
      setViewMode: actions.MP_SET_MANAGER_MODE,
      initCreateManager: actions.MP_INIT_CREATE_MANAGER,
      initBrandManager: actions.MP_INIT_BRAND_MANAGER,
      deleteBrand: actions.MP_DELETE_BRAND,
      duplicateBrand: actions.MP_DUPLICATE_BRAND,
    }),
    handleChange(intentGoal) {
      this.selectedIntentGoal = intentGoal
      setSessionGenAiOnboarding('intentGoal', this.selectedIntentGoal.value)
    },
    handleBack() {
      this.$router.push({
        name: cvtRouteNodes.landingPageGoal,
      })
    },
    async onCloseModal() {
      await this.initBrandManager()
      this.modalVisible = false
    },
    colorToStyle(color) {
      return {
        backgroundColor: color.hex,
      }
    },
    handleLoadContent() {
      this.loading = true
      this.fetchLandingPage()
        .then(() => {
          this.$router.push(cvtRoutes.genAISelectTemplate.path)
        })
        .catch((err) => {
          this.loading = false
        })
    },

    async fetchLandingPage() {
      const onBoardingData = getSessionGenAIOnboarding()
      if (onBoardingData) {
        const params = {
          intent: [onBoardingData.intent],
          intentGoal: [onBoardingData.intentGoal],
        }
        const resp = await genAIApi.genAiTemplates(params)

        setGenAITemplate(btoa(JSON.stringify(resp)))
        return
      }
      Notification({
        title: 'woops',
        message: 'Onboarding did not follow due process',
        type: 'error',
        position: 'bottom-right',
      })
    },
    handleEditKit(brand) {
      this.activeDropdown = null
      this.initCreateManager(brand)
      this.setViewMode(BRAND_MODE_CREATE)
      this.modalVisible = true
    },
    handleDuplicateKit(brand) {
      this.activeDropdown = null
      this.duplicateBrand(brand)
    },
    handleDeleteKit(brand) {
      this.activeDropdown = null
      this.toggleDeleteDialog(brand)
    },
    toggleDeleteDialog(brand = null) {
      this.selectedBrandForDeletion = brand
      this.deleteDialogVisible = !this.deleteDialogVisible
    },
    deleteBranding() {
      if (this.selectedBrandForDeletion) {
        this.deleteBrand(this.selectedBrandForDeletion)
          .then(() => {
            this.toggleDeleteDialog()
            this.$message({
              type: 'info',
              message: 'Branding deleted successfully',
            })
            this.selectedBrandID = null
          })
          .catch((error) => {
            console.error('Error deleting brand:', error)
          })
      }
    },
    toggleColorDropdown(brandIdx) {
      this.activeColorDropdown =
        this.activeColorDropdown === brandIdx ? null : brandIdx
    },
    toggleBrandDropdown(brandIdx) {
      this.activeDropdown = this.activeDropdown === brandIdx ? null : brandIdx
    },
    handleSelectBrand(brandId) {
      this.selectedBrandID = brandId
      setSessionGenAiOnboarding('selectedBrandID', brandId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/GenAIBrandPreference.style.scss';
</style>
