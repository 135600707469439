
<div v-if="showMigration" class="status-container">
  <div class="backdrop"></div>
  <div class="status-box">
    <h3>Applying Data Migration</h3>
    <hr class="divider" />
    <div class="loading-indicator">
      <div class="spinner"></div>
      {{ status }}
    </div>
  </div>
</div>
