import * as bb from 'bluebird'
import {
  SearchPopupsArgs,
  UpdatePopupPropertiesArgs,
} from '../../common/types/api/v1/PopupSite'
import ApiClient from './ApiClient'

export class Auth extends ApiClient {
  currentUser() {
    const dt = new Date()
    const ts = dt.getTime()
    return this.get(`/auth/currentUser?ts=${ts}`)
  }
}

export class CustomDomains extends ApiClient {
  loadDomains({ siteId }: { siteId: string }) {
    return this.post(`/v1/sites/custom-domains/load-domains`, { siteId })
  }

  connectDomain({ siteId, domain }: { siteId: string; domain: string }) {
    return this.post(`/v1/sites/${siteId}/domains`, { siteId, domain })
  }

  disconnectDomain({ siteId, domain }: { siteId: string; domain: string }) {
    return this.delete(`/v1/sites/${siteId}/domains/${domain}`)
  }
}

export class Marketplace extends ApiClient {
  templates(params: { [key: string]: any }) {
    return this.get('/v1/marketplace/templates', params)
  }

  templateByID(id: string) {
    return this.get(`/v1/marketplace/templates/${id}`)
  }

  countTemplates(params: { [key: string]: any }) {
    return this.get('/v1/marketplace/templates/count', params)
  }
}

export class PopUpMarketplace extends ApiClient {
  popupTemplates(params: { [key: string]: any }) {
    return this.get('/v1/popup/marketplace/templates', params)
  }

  popupByID(id: string) {
    return this.get(`/v1/popup/marketplace/templates/${id}`)
  }

  clonePopupFromMarketplaceTemplate(popUpTemplateId: string, foreignData: any) {
    return this.post(`/v1/popup/marketplace/${popUpTemplateId}/clone`, {
      foreignData,
    })
  }

  countPopupTemplate(params: { [key: string]: any }) {
    return this.get('/v1/popup/marketplace/templates/count', params)
  }
}

export class Organization extends ApiClient {
  addOrganization(data: { [key: string]: any }) {
    return this.post(`/super/create/addOrganization`, data).then(
      ({ data }) => data,
    )
  }

  enterpriseOnboarding(data: { [key: string]: any }) {
    return this.post(`/v1/organization/admin/onboard`, data)
  }

  upsertFeatureConfig(data: { [key: string]: any }) {
    return this.post(`/v1/organization/admin/config`, data)
  }

  fetchConfig() {
    return this.get(`/config`)
  }

  fetchBrand(domain: string) {
    return this.get(`/v1/organization/admin/brandFetch?domain=${domain}`)
  }

  inviteITMember(data: { email: string; role: string }[]) {
    return this.post(`/v1/organization/admin/members`, data)
  }

  deleteITMember(userId: string) {
    return this.delete(`/v1/organization/admin/members`, userId)
  }

  resendITInvite(userId: string) {
    return this.post(`/v1/organization/admin/members/${userId}/resend-invite`)
  }

  fetchITMember() {
    return this.get(`/v1/organization/admin/members`)
  }

  fetchEnterprise() {
    return this.get(`/v1/organization/fetch-enterprise`)
  }

  loadCustomeHostNames() {
    return this.get(`/v1/organization/admin/custom-hostnames`)
  }

  fetchEnvJwtToken() {
    return this.get(`/v1/organization/admin/get-env-jwt`)
  }

  fetchDnsRecord() {
    return this.get(`/v1/organization/admin/customer-domains`)
  }

  createDnsRecord(data: { production: string; staging: string; cdn: string }) {
    return this.post(`/v1/organization/admin/customer-domains`, data)
  }

  verifyCustomHostnames() {
    return this.get(`/v1/organization/admin/customer-domains/verify`)
  }

  addOIDCConfig(data: { [key: string]: any }) {
    return this.post(`/v1/organization/admin/add-oidc-config`, data)
  }

  fetctOIDCConfig(data: { [key: string]: any }) {
    return this.get(`/v1/organization/admin/oidc-config`, data)
  }

  createIODCconfig(data: { [key: string]: any }) {
    return this.post(`/v1/organization/admin/create-oidc-config`, data)
  }

  updateChatWidgetConfiguration(payload: object) {
    return this.put(`/v1/projects/chat-widget-config`, payload)
  }

  sendMFACode(data: { to: string; channel: string; redirectURL: string }) {
    return this.post(`/auth/mfa/send`, data)
  }

  verifyMFACode(data: { to: string; code: string; xhr: boolean }) {
    return this.post(`/auth/mfa/verify`, data)
  }

  fetchDemoTemplates() {
    return this.get(`/v1/organization/demo-templates`)
  }
}

export class Super extends ApiClient {
  searchOrganizations({ orgID }: { orgID: string }) {
    return this.get('/super/search/organizations', { params: { orgID } })
  }

  searchProjects({ orgID, projectID }: { orgID: string; projectID: string }) {
    return this.get(`/super/search/organizations/${orgID}/projects`, {
      projectID,
    })
  }

  sudo({ orgID, projectID }: { orgID: string; projectID: string }) {
    return this.post('/super/sudo', { orgID, projectID })
  }
}

export class V1CRM extends ApiClient {
  formFields(params: { [key: string]: any }) {
    return this.get('/v1/crm/form/fields', params)
  }

  createFormField(field: { [key: string]: any }) {
    return this.post('/v1/crm/form/fields', field)
  }

  createList({ name }: { name: string }) {
    return this.post('/v1/crm/lists', { name })
  }

  getTags(query: string) {
    return this.get('/v1/crm/tags', { query })
  }

  createTag({ name, description }: { name: string; description: string }) {
    return this.post('/v1/crm/tags', { name, description })
  }

  searchFiles({
    name,
    viewable,
    type,
  }: {
    name: string
    viewable: boolean
    type: string
  }) {
    return this.get('/v1/crm/files', { name, viewable, type })
  }

  createNewGoogleSheet({ title }: { title: string }) {
    return this.post('/v1/integrations/googleSheet/create', { title })
  }

  createCrmList(field: { [key: string]: any }) {
    return this.post('/v1/crm/lists', field)
  }
}

export class V1ECommerce extends ApiClient {
  async listProducts(params: { [key: string]: any }) {
    const { data: products } = await this.get(
      '/v1/crm/e-commerce/products',
      params,
    )
    return products
  }

  async searchProducts(params: { [key: string]: any }) {
    const { data: products } = await this.get(
      '/v1/crm/e-commerce/products/search',
      params,
    )
    return products
  }

  async searchProductsWithSkus(params: { [key: string]: any }) {
    return (bb as any).map(
      await this.searchProducts(params),
      async (product: { id: string; skus: any[] }) => {
        product.skus = await this.listSkusByProductId(product.id, params)
        return product
      },
    )
  }

  async listSkusByProductId(productId: string, params: { [key: string]: any }) {
    const { data: skus } = await this.get(
      `/v1/crm/e-commerce/products/${productId}/skus`,
      params,
    )
    return skus
  }

  async listPlansByProductId(
    productId: string,
    params: { [key: string]: any },
  ) {
    const { data: plans } = await this.get(
      `/v1/crm/e-commerce/products/${productId}/plans`,
      params,
    )
    return plans
  }

  async deleteProductById(productId: string) {
    const { data: product } = await this.delete(
      `/v1/crm/e-commerce/products/${productId}`,
    )
    return product
  }

  async createProduct(options: { [key: string]: any }) {
    const { data: product } = await this.post(
      '/v1/crm/e-commerce/products',
      options,
    )
    return product
  }

  async createProductPlan(productId: string, options: { [key: string]: any }) {
    const { data: plan } = await this.post(
      `/v1/crm/e-commerce/products/${productId}/plans`,
      options,
    )
    return plan
  }
}

export class V1Sites extends ApiClient {
  all({
    market,
    archived,
    limit,
    offset,
    dateCreated,
    dateUpdated,
    isPublished,
    search,
    searchId,
  }: {
    market: string
    archived: boolean
    limit: number
    offset: number
    dateCreated: string
    dateUpdated: string
    isPublished: boolean
    search: string
    searchId: string
    [key: string]: any
  }) {
    return this.get('/v1/sites', {
      market,
      archived,
      limit,
      offset,
      dateCreated,
      dateUpdated,
      isPublished,
      search,
      searchId,
    })
  }

  byId(siteId: string) {
    return this.get(`/v1/sites/${siteId}`)
  }

  updateById(siteId: string, site: object) {
    return this.put(`/v1/sites/${siteId}`, site)
  }

  create(foreignData: object) {
    return this.post('/v1/sites', { foreignData })
  }

  publish(siteId: string) {
    return this.post(`/v1/sites/${siteId}/publish`)
  }

  unpublish(siteId: string) {
    return this.post(`/v1/sites/${siteId}/unpublish`)
  }

  fromTemplate(id: string, foreignData: object) {
    return this.post(`/v1/sites/fromTemplate/${id}`, { foreignData })
  }

  fromGenAiTemplate(
    siteId: string,
    data: {
      intent: string[]
      intentGoal: string[]
      businessDescription: string
      businessSummary: string[]
    },
  ) {
    return this.post(`/v1/sites/fromGenAiTemplate/${siteId}`, data).then(
      ({ data }) => data,
    )
  }

  generateSiteContent(body: {
    intent: string
    intentGoal: string
    pageRef: string
  }) {
    if (body.pageRef.endsWith('/')) { 
      body.pageRef = body.pageRef.slice(0, -1)
    }
    return this.post(`/v1/sites/generate-site-content`, body).then(
      ({ data }) => data,
    )
  }

  generateElementContent(params: {
    intent: string
    intentGoal: string
    pageRef: string
    elementPath: string
  }) {
    return this.post(`/v1/sites/generate-element-content`, params).then(
      ({ data }) => data,
    )
  }

  updateThumbnails(id: string) {
    return this.post(`/v1/sites/${id}/updateThumbnails`)
  }

  getAllCategories() {
    return this.get(`/v1/sites/categories`)
  }

  getCategories(id: string) {
    return this.get(`/v1/sites/super/${id}/categories`)
  }

  updateCategories(id: string, categories: object) {
    return this.put(`/v1/sites/super/${id}/categories`, categories)
  }

  updateSiteProperties(id: string, data: object) {
    return this.put(`/v1/sites/super/${id}/marketplace`, { ...data })
  }

  updateCampaignNode(id: string, campaignData: object | null) {
    if (campaignData === null) {
      console.debug('no campaign data to update a node with -- skipping')
      return Promise.resolve()
    }
    return this.post(`/v1/sites/${id}/updateCampaignNode`, campaignData)
  }

  analytics({ siteId, window }: { siteId: string; window: string }) {
    return this.get(`/v1/sites/${siteId}/analytics`, { window })
  }

  regenerateForms({ siteId }: { siteId: string }) {
    return this.post(`/v1/sites/${siteId}/regenerateForms`)
  }

  archive(siteId: string) {
    return this.delete(`/v1/sites/${siteId}`)
  }

  restore(siteId: string) {
    return this.get(`/v1/sites/${siteId}/restore`)
  }

  updatePage(page: { siteId: string; id: string; [key: string]: any }) {
    const { siteId, id } = page
    return this.put(`/v1/sites/${siteId}/pages/${id}`, page)
  }

  clonePage({ siteId, id }: { siteId: string; id: string }) {
    return this.post(`/v1/sites/${siteId}/pages/${id}/clone`)
  }

  insertPage({ siteId, pageProps }: { siteId: string; pageProps: object }) {
    return this.post(`/v1/sites/${siteId}/insertPage`, pageProps)
  }

  savePage({
    siteId,
    id,
    pageProps,
  }: {
    siteId: string
    id: string
    pageProps: object
  }) {
    return this.post(`/v1/sites/${siteId}/pages/${id}/save`, pageProps)
  }

  deletePage({ siteId, id }: { siteId: string; id: string }) {
    return this.delete(`/v1/sites/${siteId}/pages/${id}`)
  }

  replacePage({
    siteId,
    id,
    pageProps,
  }: {
    siteId: string
    id: string
    pageProps: object
  }) {
    return this.post(`/v1/sites/${siteId}/pages/${id}/replacePage`, pageProps)
  }

  createPage(page: { siteId: string; [key: string]: any }) {
    const { siteId } = page
    return this.post(`/v1/sites/${siteId}/pages`, page)
  }

  switchBuilderVersion(payload: object) {
    return this.post(`/v1/organization/project/gridVersion`, payload)
  }

  savePreference(data: { name: string }, siteId: string) {
    return this.post(`/v1/sites/${siteId}/preferences`, data)
  }

  updatePreference(data: { name: string }, siteId: string) {
    return this.put(`/v1/sites/${siteId}/preferences`, data)
  }

  fetchPreferences() {
    return this.get(`/v1/sites/preferences`)
  }

  getProductVersionNumber() {
    return this.get(`/v1/organization/productVersion`)
  }

  runDataStructureMigration(data:{modelRef:string,fromVersion:number,toVersion:number, siteId: string}) {
    return this.post(`/v1/sites/run-data-structure-migration`, {
      modelRef: data.modelRef,
      fromVersion: parseFloat(data.fromVersion.toString()),
      toVersion: parseFloat(data.toVersion.toString()),
      siteId: data.siteId
    })
  }
}

export class V1PopupSite extends ApiClient {
  all(searchPopupParams: SearchPopupsArgs) {
    return this.get('/v1/popup/search', searchPopupParams)
  }

  byId(popUpId: string) {
    return this.get(`/v1/popup/${popUpId}`)
  }

  updateById(popUpId: string, popup: any) {
    return this.put(`/v1/popup/${popUpId}`, popup)
  }

  create(foreignData: any) {
    return this.post('/v1/popup', { foreignData })
  }

  publish(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/publish`)
  }

  unpublish(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/unpublish`)
  }

  fromPopupSite(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/clone`)
  }

  updateThumbnails(popUpId: string) {
    return this.post(`/v1/popup/${popUpId}/updateThumbnails`)
  }

  archive(popUpId: string) {
    return this.delete(`/v1/popup/${popUpId}`)
  }

  restore(popUpId: string) {
    return this.get(`/v1/popup/${popUpId}/restore`)
  }

  enablePopupMarketplace(id: string, enabled: boolean) {
    return this.put(`/v1/popup/super/${id}/marketplace`, { enabled })
  }

  updateCategories(id: string, categories: object[]) {
    return this.put(`/v1/popup/super/${id}/categories`, categories)
  }

  connect_url(popupID: string, url: string) {
    return this.post(`/v1/popup/${popupID}/connect-url`, url)
  }

  updateProperties(
    popupID: string,
    updatePopupProperties: UpdatePopupPropertiesArgs,
  ) {
    return this.put(
      `/v1/popup/super/${popupID}/properties`,
      updatePopupProperties,
    )
  }
  runDataStructureMigration(data:{modelRef:string,fromVersion:number,toVersion:number, popupId}) {
    return this.post(`/v1/popup/run-data-structure-migration`,{
      modelRef: data.modelRef,
      fromVersion: parseFloat(data.fromVersion.toString()),
      toVersion: parseFloat(data.toVersion.toString()),
      popupId: data.popupId
    })
  }
}

export class V1Stripe extends ApiClient {
  connect({ code }: { code: string }) {
    return this.post('/v1/stripe/connect', { code }).then(({ data }) => data)
  }

  account() {
    return this.get('v1/stripe/api/account').then(({ data }) => data)
  }

  getAllProducts({
    limit,
    shippable = false,
  }: {
    limit: number
    shippable: boolean
  }) {
    return this.get('/v1/stripe/api/products', {
      params: { limit, shippable },
    }).then(({ data }) => data)
  }

  createProduct(product: { [key: string]: any }) {
    return this.post('/v1/stripe/api/products', product).then(
      ({ data }) => data,
    )
  }

  createProductSKU(productId: string, sku: string) {
    return this.post(`/v1/stripe/api/products/${productId}/skus`, sku).then(
      ({ data }) => data,
    )
  }

  updateProduct(productId: string, data: { [key: string]: any }) {
    return this.post(`/v1/stripe/api/products/${productId}`, data).then(
      ({ data }) => data,
    )
  }

  deleteProduct(productId: string) {
    return this.delete(`/v1/stripe/api/products/${productId}`).then(
      ({ data }) => data,
    )
  }
}

export class V1DynamicContent extends ApiClient {
  async preview(params: {
    excludeIds?: string[]
    previewId?: string
    filter: { intent: string; intentGoal: string }
  }) {
    const { data: content } = await this.post('/v1/preview', params, {
      timeout: 60000,
    })
    return content as {
      excludeIds: string[]
      previewId: string
      templateId: string
      previewUrl: string
    }
  }

  async selectTemplate(params: { previewId: string }) {
    const { data: content } = await this.post('/v1/preview/template/', params)
    return content
  }

  getPreviewURL(previewUrl: string, mobile: boolean = false) {
    let url = previewUrl
    if (mobile) {
      if (previewUrl.search('/?/')) {
        url += '&mobile=true'
      } else {
        url += '?mobile=true'
      }
    }
    return url
  }

  getPreviewURLAlt(previewUrl: string, mobile: boolean = false) {
    const url = new URL(previewUrl)
    mobile
      ? url.searchParams.set('mobile', 'true')
      : url.searchParams.delete('mobile')

    return url.toString()
  }
}

export class V1GenAI extends ApiClient {
  generateSectionContent(params: { sectionPath: string }) {
    return this.post('/v1/sites/generate-section-content', params)
  }

  genAiTemplates(params: { intent: Array<string>; intentGoal: Array<string> }) {
    return this.post('/v1/marketplace/gen-ai/templates', params).then(
      ({ data }) => data,
    )
  }

  saveGenAiTemplates(
    siteId: string,
    templateData: {
      intent: Array<string>
      intentGoal: Array<string>
      data?: { [key: string]: {} }
    },
  ) {
    return this.post(`/v1/marketplace/${siteId}/enable-genai`, templateData)
  }

  previewGenAiTemplates(
    siteId: string,
    pageId: string
  ) {
    return this.get(`/v1/marketplace/templates/${siteId}/${pageId}/preview`)
  }
}

export default new ApiClient()
