<template>
  <div class="container py-5" :style="titleStyle">
    <div class="row mb-2">
      <div class="col-md-12 text-center">
        <h2 class="setup-title" :style="titleStyle">
          {{
            sitePublished
              ? $t('launch.message.title')
              : $t('launch.message.title_alt')
          }}
        </h2>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-10 mx-auto">
        <p class="text-center publish-subtitle">
          {{
            sitePublished
              ? $t('launch.message.sub_title')
              : $t('launch.message.sub_title_alt')
          }}
        </p>
        <p v-if="config.WORDPRESS_PLUGIN_ENABLED" class="text-center">
          {{ $t('launch.message.wordpress.text') }}
          <i
            ><a
              target="_blank"
              href="https://wordpress.org/plugins/infusionsoft-landing-pages/"
              >{{ $t('launch.message.wordpress.link') }}</a
            ></i
          >
        </p>
      </div>
    </div>

    <cvt-popover placement="bottom" target="share" triggers="click">
      <template #popper>
        <social-share-buttons :url="site.siteUrl"></social-share-buttons>
      </template>
    </cvt-popover>

    <cvt-publish-card
      :loading="loading"
      :published="sitePublished"
      :disabled="setPublishButtonDisabled"
      :disabled-custom-message="disabledCustomMessage"
      :custom-disabled-message-enabled="customDisabledMessageEnabled"
      :thumbnail="
        realtimeThumbnail === 'loading' || realtimeThumbnail === ''
          ? ''
          : realtimeThumbnail
      "
      :special-style="publishBtnStyle"
      @publish="publishSite(site, $event)"
      @republish="publishSite(site, $event)"
    />

    <fade-in-out>
      <div class="row mb-5 mt-4 d-flex justify-content-center">
        <div
          v-if="showLandingPageUrl"
          :key="showLandingPageUrl"
          class="col-md-6 mt-2 text-left"
        >
          <div class="row">
            <div class="col">
              <div class="input-group">
                <cvt-input
                  id="site_url"
                  class="input-group__url"
                  type="text"
                  :label="$t('launch.message.url')"
                  :value="site.siteUrl"
                  :readonly="true"
                />
                <cvt-button
                  text="Copy"
                  class="input-group__copy"
                  color="light"
                  :btn-text-color="publishPageConfig.copyButtonColor || ''"
                  :outlined="true"
                  @click="copySiteUrl"
                />
              </div>
              <div
                v-if="!loading && sitePublished"
                class="justify-content-center input-group"
              >
                <a
                  :href="site.siteUrl"
                  class="landing__page--link"
                  target="_blank"
                >
                  <cvt-button color="light" text="Open your landing page" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fade-in-out>

    <div class="row mb-5 justify-content-center">
      <div class="col-6">
        <cvt-tabs
          :keep-inactive-border="
            publishPageConfig.keepInactiveBorder
              ? publishPageConfig.keepInactiveBorder
              : false
          "
          :full-horizontal-border="
            publishPageConfig.fullHorizontalBorder
              ? publishPageConfig.fullHorizontalBorder
              : false
          "
          :smaller-tab-border="
            publishPageConfig.smallerTabBorder
              ? publishPageConfig.smallerTabBorder
              : false
          "
          :in-active-border-color="
            publishPageConfig.inActiveBorderColor
              ? publishPageConfig.inActiveBorderColor
              : ''
          "
          :closeable="false"
          prefix-icon=""
        >
          <cvt-tab
            class="custom_domain--tab"
            :title="$t('launch.custom_domains.tab_title')"
            name="custom_domains"
          >
            <cvt-custom-domain-action
              :key="linkBtnLoading"
              :domain="domain"
              :show-domain-instruction="showDomainInstruction"
              :link-btn-text="linkBtnText"
              :link-btn-loading="linkBtnLoading"
              :domain-info="domainInfo"
              :linked-domains="linkedDomains"
              :site="site"
              :theme="connectBtnColor"
              :outlined="connectBtnOutlined"
              :unlink-btn-theme="unlinkBtnTheme"
              @refresh="loadDomains"
              @validateDomainInput="validateDomainInput"
              @domain-input="updateDomain"
              @dns-unlink="
                (siteId, domain) => openUnlinkDialog(siteId, domain.toString())
              "
              @link="linkDomain"
              @clear="clearDomain"
            />
            <cvt-alert
              v-if="linkSuccessMessage.length > 0"
              color="success"
              icon="check"
            >
              {{ linkSuccessMessage }}
            </cvt-alert>
            <cvt-alert
              v-if="linkErrorMessage.length > 0"
              color="danger"
              icon="exclamation-triangle"
            >
              {{ linkErrorMessage }}
            </cvt-alert>
          </cvt-tab>
          <cvt-tab :title="$t('launch.embed.tab_title')" name="embed">
            <h5 class="mt-2 mb-4">
              {{ $t('launch.message.embed_code') }} (Iframe)
            </h5>
            <cvt-alert color="info" icon="info">
              <p>{{ $t('launch.embed.info.message') }}</p>
            </cvt-alert>
            <textarea
              :class="getClassForTextArea"
              :value="site.embedCode"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              style="resize: none"
              @click="copyToClipboard"
            >
            </textarea>
          </cvt-tab>
          <cvt-tab v-if="isAdmin" title="Admin">
            <div class="row mt-5 d-flex justify-content-space-evenly">
              <div class="col-6 pb-4 d-flex justify-content-start">
                <categories></categories>
              </div>
              <div class="col-6">
                <enable-marketplace
                  :marketplace-enabled="siteMarketPlaceEnabled"
                  @switchToggle="toggleMarketplaceFlag"
                />
              </div>
              <div
                v-if="demoTemplateSwitchEnabled && !isStandalonePopupActive"
                class="col-6 pb-4 d-flex justify-content-start"
              >
                <enable-demo-template
                  :marketplace-enabled="siteDemoTemplateEnabled"
                  @switchToggle="toggleDemoTemplateFlag"
                />
              </div>
              <template
                v-if="!isStandalonePopupActive && isGenAIFeatureEnabled"
              >
                <div class="col-6 pb-4 d-flex justify-content-start">
                  <div data-identity="EnableGenAIToggle">
                    <h5 class="admin-label">
                      {{ $t('launch.tab.control.heading.enable_gen_ai') }}
                    </h5>
                    <cvt-switch
                      class="admin-switch"
                      size="xs"
                      color="active"
                      type="circular"
                      description-type="default"
                      :mode="mode"
                      :initial-state="isGenAiEnabled"
                      @switchToggle="(status) => toggleGenAiEnabled(status)"
                    />
                  </div>
                </div>

                <div class="col-12 pb-4 d-flex justify-content-start">
                  <cvt-select
                    :key="selectedIntents?.length"
                    class="w-100 admin-select"
                    mode="dropdown"
                    :label="
                      $t('launch.tab.control.heading.intent_of_landing_page')
                    "
                    :background-mode="mode"
                    :multiple="true"
                    :value="selectedIntents"
                    @input="(value) => setGenAISelectedIntents(value)"
                  >
                    <option
                      v-for="intentObj in allIntents"
                      :key="intentObj.value"
                      :value="intentObj.value"
                      v-text="intentObj.label"
                    ></option>
                  </cvt-select>
                </div>

                <div class="col-12 pb-4 d-flex justify-content-start">
                  <cvt-select
                    :key="selectedIntents?.length"
                    class="w-100 admin-select"
                    mode="dropdown"
                    :label="
                      $t('launch.tab.control.heading.specific_intent_goal')
                    "
                    :background-mode="mode"
                    :multiple="true"
                    :value="selectedIntentGoals"
                    @input="(value) => setGenAISelectedIntentGoals(value)"
                  >
                    <optgroup
                      v-for="intentObj of allIntents"
                      :key="intentObj.value"
                      :label="intentObj.label"
                    >
                      <option
                        v-for="intentGoal in intentObj.goals"
                        :key="intentGoal.value"
                        :value="intentGoal.value"
                        v-text="intentGoal.label"
                      ></option>
                    </optgroup>
                  </cvt-select>
                </div>
              </template>

              <template v-if="(templatePreviewAlert && !isStandalonePopupActive) || allowExplainerImageURL">
                <div class="col-6 pb-4 d-flex justify-content-start">
                  <add-image-url
                    :image-url="siteImageUrl"
                    :label="$t('launch.tab.control.heading.add_image_url')"
                    @siteImageUrlChange="siteImageUrlChange"
                  />
                </div>
                <div class="col-6 pb-4 d-flex justify-content-start">
                  <add-verified-seal
                    :verified-seal-enabled="siteVerifiedSealEnabled"
                    @switchToggle="toggleVerifiedSealFlag"
                  />
                </div>
                <div class="col-12 pb-4">
                  <add-video-url
                    :video-url="siteVideoUrl"
                    @siteVideoUrlChange="siteVideoUrlChange"
                  />
                </div>
                <div class="col-12 w-100 pb-4">
                  <add-description
                    :description="siteDescription"
                    @siteDescriptionChange="siteDescriptionChange"
                  />
                </div>
              </template>
            </div>
            <div class="row mt-1 d-flex justify-content-space-evenly">
              <div class="col-12 w-100 pb-1">
                <cvt-button
                  :mode="mode"
                  class="admin-button"
                  :text="'Submit'"
                  :color="''"
                  :loading="submittingAdminData"
                  :shadow="true"
                  :disabled="false"
                  @click="(event) => submitAdminTabSiteData(event)"
                />
              </div>
            </div>
          </cvt-tab>
        </cvt-tabs>
      </div>
    </div>

    <cvt-dialog
      :show="unlinkDialog"
      size="sm"
      :height-auto="true"
      :scroll-on-overflow="true"
      @close="unlinkDialog = false"
    >
      <template #title>
        <h5>
          {{ `${$t('custom_domain.ui.unlink_dialog.h5')} ${unlinkDomainName}` }}
        </h5>
      </template>
      <cvt-alert color="warning" icon="exclamation-triangle">
        <p v-if="unlinkExistingDomain">
          {{
            `${$t(
              'custom_domain.ui.unlink_dialog.p.unlink_existing_domain',
            )} ${unlinkDomainName} ${$t(
              'custom_domain.ui.unlink_dialog.p.part_two',
            )}`
          }}
        </p>
        <p v-else>
          {{
            `${$t(
              'custom_domain.ui.unlink_dialog.p.part_one',
            )} ${unlinkDomainName} ${$t(
              'custom_domain.ui.unlink_dialog.p.part_two',
            )}`
          }}
        </p>
      </cvt-alert>

      <template #modalFooter>
        <cvt-button
          color="light"
          :text="$t('custom_domain.ui.unlink_dialog.buttons.cancel')"
          @click="unlinkDialog = false"
        />
        <cvt-button
          :color="popUpBtnColor"
          :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
          @click="unlinkDomain(unlinkDomainName)"
        />
      </template>
    </cvt-dialog>

    <cvt-dialog
      :show="apexDialog"
      size="lg"
      :height-auto="true"
      :scroll-on-overflow="true"
      @close="apexDialog = false"
    >
      <template #title>
        <h5>{{ $t('custom_domain.ui.apex_popup.title') }}</h5>
      </template>
      <cvt-alert color="warning" icon="exclamation-triangle">
        <h2>{{ $t('custom_domain.ui.apex_popup.h2.first') }}</h2>
      </cvt-alert>

      <p>
        {{ $t('custom_domain.ui.apex_popup.p1.part_one')
        }}<code>example.com</code>
        {{ $t('custom_domain.ui.apex_popup.p1.part_two')
        }}<code>www.example.com</code>
        {{ $t('custom_domain.ui.apex_popup.p1.part_three')
        }}<code>example.com</code>
        {{ $t('custom_domain.ui.apex_popup.p1.part_four')
        }}<code>www.example.com</code>)
      </p>

      <h2 class="mt-5">{{ $t('custom_domain.ui.apex_popup.h2.second') }}</h2>

      <p>
        {{ $t('custom_domain.ui.apex_popup.p2.part_one') }}
        <a href="https://www.ietf.org/rfc/rfc1034.txt">RFC1034</a>
        {{ $t('custom_domain.ui.apex_popup.p2.part_two') }}
      </p>

      <p>
        {{ $t('custom_domain.ui.apex_popup.p3.part_one') }}<code>www</code
        >{{ $t('custom_domain.ui.apex_popup.p3.part_two') }} (<code
          >example.com</code
        >{{ $t('custom_domain.ui.apex_popup.p3.part_three')
        }}<code>www.example.com</code>).
      </p>
    </cvt-dialog>
  </div>
</template>

<script>
import { GenAIIntent } from '@/js/components/genAi/utils'
import { Notification } from 'element-ui'
import * as _ from 'lodash'
import moment from 'moment'
import { parseDomain, ParseResultType, ValidationErrorType } from 'parse-domain'
import v from 'validator'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import thumbPreview from '../../../../../static/img/preview-thumb.gif'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import { CookieHelper } from '../../../store/services/cookieHelper'
import { V1GenAI } from '../../../store/services/api'
import SocialShareButtons from '../../common/SocialShareButtons.vue'
import domainHelperMethods from './helpers/domainHelper'
import AddDescription from './super/add-description/AddDescription.vue'
import AddImageUrl from './super/add-image-url/AddImageUrl.vue'
import AddVerifiedSeal from './super/add-verified-seal/AddVerifiedSeal.vue'
import AddVideoUrl from './super/add-video-url/AddVideoUrl.vue'
import Categories from './super/Categories.vue'
import EnableDemoTemplate from './super/enable-demo-template/EnableDemoTemplate.vue'
import EnableMarketplace from './super/EnableMarketplace.vue'

const genAIApi = new V1GenAI()

// TODO: These appear unused. - Bice
// const SOCIAL_SHARE_SETTINGS_SAVED = 'Your social settings have been saved. Please republish your page for your pages to include them.'
// const SAVED_FAVICON_MSG = 'Favicon Url Saved.'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Launch',
  components: {
    Categories,
    EnableMarketplace,
    SocialShareButtons,
    AddVerifiedSeal,
    AddImageUrl,
    AddVideoUrl,
    AddDescription,
    EnableDemoTemplate,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      prevRoute: null,
      domain: '',
      activeTab: 'tracking',
      debugSharingActiveTab: 'debugFacebookSharing',
      defaultTextAreaRows: { minRows: 3, maxRows: 5 },
      thumbPreview,
      previewLoaded: false,
      anchorTargets: [
        { label: 'Current Window', value: '_self' },
        { label: 'New Tab', value: '_blank' },
      ],
      mode: 'LIGHT',
      prependChevron: false,
      loading: false,
      showDomainInstruction: false,
      linkBtnText: this.$t(
        'custom_domain.ui.domain_instruction.verify_button.text.verify',
      ),
      linkBtnLoading: false,
      linkErrorMessage: '',
      linkSuccessMessage: '',
      unlinkDialog: false,
      apexDialog: false,
      unlinkDomainName: '',
      unlinkExistingDomain: false,
      unlinkDomainSiteId: '',
      defaultSiteSaveMsg: this.$t('launch.message.site_save_msg'),
      savedEmbedsMsg: this.$t('launch.message.saved_embeds_msg'),
      showLandingPageUrl: 0,
      adminTabSiteData: {},
      firebaseChanges: {
        enabled: false,
        selectedIntents: false,
        selectedIntentGoals: false,
      },
      genAIData: {
        enabled: false,
        selectedIntents: [],
        selectedIntentGoals: [],
      },
      submittingAdminData: false,
    }
  },
  computed: {
    ...mapGetters({
      steps: getters.STEPS,
      site: getters.SITE_GET_DATA,
      step: getters.STEPS_CURRENT_STEP,
      linkedDomains: getters.SITE_DOMAINS,
      siteTracking: getters.SITE_TRACKING,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      config: getters.AUTH_GET_USER_CONFIG,
      user: getters.AUTH_GET_USER,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
      currentProductionVersion: getters.CURRENT_PRODUCT_VERSION_NUMBER,
    }),
    ...mapState('sitePreferences', {
      favicon: ({ preferences }) => _.get(preferences, 'favicon', ''),
      realtimeThumbnail: ({ preferences }) =>
        _.get(preferences, 'thumbnail', ''),
      termsOfServiceURL: ({ preferences }) =>
        _.get(preferences, 'termsOfServiceURL', ''),
      cookiePolicyURL: ({ preferences }) =>
        _.get(preferences, 'cookiePolicyURL', ''),
      privacyPolicyURL: ({ preferences }) =>
        _.get(preferences, 'privacyPolicyURL', ''),
      cookieBannerEnabled: ({ preferences }) =>
        _.get(preferences, 'cookieBannerEnabled', false),
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) =>
        globalTheme.OrgName ?? LEFT_SIDEBAR_STANDARD,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      publishPageConfig: ({ globalTheme }) =>
        globalTheme.publishPage ? globalTheme.publishPage : {},
      templatePreviewAlert: ({ globalTheme }) =>
        globalTheme.templatePreviewAlert,
    }),
    ...mapGetters('sitePreferences', {
      fbSitePreferences: getters.GET_FB_SITE_PREFERENCES,
    }),
    allIntents() {
      return [...GenAIIntent]
    },
    allowExplainerImageURL() {
      return this.isAdmin && this.currentProductionVersion >= 4.0
    },
    isGenAiEnabled: {
      get() {
        return this.fbSitePreferences.genAI?.enabled || false
      },
      set(value) {
        return this.updateSitePreferences({
          key: 'genAI/enabled',
          value: value,
        })
      },
    },
    selectedIntents: {
      get() {
        return this.fbSitePreferences.genAI?.selectedIntents || []
      },
      set(value) {
        return this.updateSitePreferences({
          key: 'genAI/selectedIntents',
          value: value,
        })
      },
    },
    selectedIntentGoals: {
      get() {
        return this.fbSitePreferences.genAI?.selectedIntentGoals || []
      },
      set(value) {
        return this.updateSitePreferences({
          key: 'genAI/selectedIntentGoals',
          value: value,
        })
      },
    },
    embeds: {
      get() {
        return this.site.embeds
      },
      set: _.debounce(function (embeds) {
        this.site.embeds = embeds
        this.updateSite(this.site, this.savedEmbedsMsg)
      }, 800),
    },
    cookieBannerToggle: {
      get() {
        return this.cookieBannerEnabled
      },
      set(value) {
        return this.updateSitePreferences({
          key: 'cookieBannerEnabled',
          value,
        })
      },
    },
    realtimeThumbnailLoading() {
      return (
        this.realtimeThumbnail === 'loading' || this.realtimeThumbnail === ''
      )
    },
    finished: {
      get() {
        return this.step.state === 'complete'
      },
      set(value) {
        return value
      },
    },
    publishBtnMsg() {
      return this.sitePublished
        ? this.$t('launch.publish.republish')
        : this.$t('launch.publish.go_live')
    },
    uploadOpenGraphBtnTxt() {
      return `launch.sharing.opengraph.${
        this.opengraph.image ? 'replace_image' : 'add_image'
      }`
    },
    tracking() {
      return this.siteTracking
    },
    verificationKey() {
      return `${this.user.orgID}-site-verification=${this.user.projectID}`
    },
    domainInfo() {
      return {
        verificationKey: this.verificationKey,
        domain: this.domain.trim().toLowerCase(),
        cname: this.config?.CUSTOM_HOSTNAME_CONFIG?.cdn_cname,
      }
    },
    titleStyle() {
      return { 'font-family': this.FontFamily.DEFAULT }
    },
    publishBtnStyle() {
      return this.orgName === FRESHWORKS ? 'dark' : ''
    },
    connectBtnColor() {
      return this.orgName === FRESHWORKS
        ? 'light'
        : this.publishPageConfig.domainConnectTheme
        ? this.publishPageConfig.domainConnectTheme
        : 'success'
    },
    connectBtnOutlined() {
      return this.orgName === FRESHWORKS
    },
    unlinkBtnTheme() {
      return this.orgName === FRESHWORKS ? 'light' : 'danger'
    },
    popUpBtnColor() {
      return this.orgName === FRESHWORKS ? 'light' : 'warning'
    },
    getClassForTextArea() {
      return this.orgName === FRESHWORKS
        ? ' form-control dark-textarea'
        : 'form-control'
    },
    getCookie() {
      return CookieHelper('_pages_session=')
    },
    setPublishButtonDisabled() {
      /**
       * Function to grant/permit a user ability to publish a page
       * Config key: USER_PUBLISH_PERMISSION_CHECK: boolean
       * @returns boolean
       * If the configuration permission USER_PUBLISH_PERMISSION_CHECK is turned on for an organization.
       * Then only we should obey the value of user_activated set in the cookie.
       */
      //Config is not set or on
      if (!this.config.USER_PUBLISH_PERMISSION_CHECK) {
        return false
      }

      //Get the cookie value
      const get_user_active_status = this.getCookie?.['user_activated']

      if (!get_user_active_status) {
        return true
      }

      return false
    },
    disabledCustomMessage() {
      return {
        message:
          'launch.publish.launch.user_not_activated_publish_permission_message.custom.message',
        action:
          'launch.publish.launch.user_not_activated_publish_permission_message.custom.cta',
      }
    },
    customDisabledMessageEnabled() {
      try {
        return this.config.CUSTOM_PUBLISHED_DISABLED_MESSAGE
      } catch {
        return null
      }
    },
    sitePublished: {
      get() {
        return this.site.state == 'published'
      },
      set(value) {
        this.site.state = value
        this.updateSiteState(this.site)
      },
    },
    siteMarketPlaceEnabled() {
      return this.site?.marketplace || false
    },
    siteDemoTemplateEnabled() {
      return this.site?.demoTemplate || false
    },
    siteVerifiedSealEnabled() {
      return this.site?.verifiedSeal || false
    },
    siteImageUrl() {
      return this.site?.imageUrl || ''
    },
    siteVideoUrl() {
      return this.site?.videoUrl || ''
    },
    siteDescription() {
      return this.site?.description || ''
    },
    demoTemplateSwitchEnabled() {
      return this.config.DEMO_TEMPLATE || false
    },
    isGenAIFeatureEnabled() {
      return this.config?.IS_GEN_AI_ENABLED || false
    },
  },
  created() {
    if (this.step.state === undefined) {
      return this.markStep('inprogress').then((_) => this.hideLoader())
    }
    this.hideLoader()
    this.loading = false
    // Then check for siteId before request
    this.validateSiteIdOrDomain(true).then(() => this.loadDomains())
    this.displayLandingPageUrl()
  },
  mounted() {
    if (this.prevRoute?.name === 'site-preview') {
      this.updateSitePreviewRouteVisited(true)
    } else {
      this.updateSitePreviewRouteVisited(false)
    }
  },
  methods: {
    ...mapActions({
      markStep: actions.STEPS_MARK,
      goToCampaignBuilder: actions.SITE_CAMPAIGN_REDIRECT,
      updateSiteAction: actions.SITE_UPDATE,
      intercomTrack: actions.INTERCOM_TRACK,
      initAssetManager: actions.ASSET_MANAGER_INIT,
      toggleAssetManager: actions.ASSET_MANAGER_TOGGLE,
      mixpanelTrack: actions.MIXPANEL_TRACK,
      publish: actions.SITE_PUBLISH,
      loadDomains: actions.CUSTOM_DOMAIN_LOAD_DOMAINS,
      disconnectDomain: actions.CUSTOM_DOMAIN_DISCONNECT,
      connectDomain: actions.CUSTOM_DOMAIN_CONNECT,
      updateSiteProperties: actions.SITE_UPDATE_PROPERTIES,
      saveGenAISite: actions.SAVE_GEN_AI_SITE,
      deleteGenAISite: actions.DELETE_GEN_AI_SITE,
    }),
    ...mapActions('loader', {
      hideLoader: 'stop',
    }),
    ...mapActions('sitePreferences', {
      updateSitePreferences: 'save',
    }),
    ...mapActions('revisions', {
      saveSnapshot: 'saveSnapshot',
    }),
    ...mapMutations({
      updateSiteState: mutations.SITE_SET_DATA,
      updateSitePreviewRouteVisited:
        mutations.UPDATE_SITE_PREVIEW_ROUTE_VISITED,
    }),
    copySiteUrl() {
      this.copyToClipboard({ target: this.$el.querySelector('#site_url') })
    },
    copyToClipboard({ target }) {
      target.select()
      document.execCommand('copy')
      Notification.success({
        message: 'Copied to your clipboard',
        position: 'bottom-right',
      })
    },
    published(err) {
      if (this.sitePublished) return
      this.markStep('complete')
      this.displayLandingPageUrl()
      this.intercomTrack({
        event: 'published-site',
        context: {
          id: this.site.id,
          url: this.site.siteUrl,
        },
      })
      this.mixpanelTrack({
        event: 'Site Published',
        category: 'templates',
        data: {
          page_id: this.site.id,
          page_url: this.site.siteUrl,
        },
      })
      this.saveSnapshot({
        key: 'published',
        description: 'published the site',
      })
    },
    updateSite(site, message = this.defaultSiteSaveMsg) {
      this.updateSiteAction(site).then((_) => {
        this.$message({ message })
      })
    },
    onPreviewLoad() {
      this.previewLoaded = true
    },
    onPreviewLoadError(err) {
      this.previewLoaded = false
      console.warn('PREVIEW LOADING ERROR', err)
    },
    fmtDate(timestamp) {
      return moment(timestamp).format('MM-DD-YYYY')
    },
    handleSiteUrl() {
      this.$message({
        type: 'warning',
        message: 'This is not where you update the Domain.',
      })
    },
    onPublishErrors(errors) {
      const legalErrors = errors.some(({ code = '' }) =>
        code.includes('MISSING_LEGAL'),
      )
      if (legalErrors) {
        this.activeTab = 'Legal'
      }
    },
    async publishSite(site, event) {
      try {
        this.loading = true
        const { errors } = await this.publish(site, event)

        if (errors && errors.length) {
          this.onPublishErrors(errors)
        } else {
          this.published()
          this.sitePublished = 'published'
        }
      } catch (err) {
        console.error('failed to publish', err)
      }

      this.finished = this.step.state === 'complete'
      this.loading = false
    },
    async validateDomainInput() {
      // Given input parts will be converted toUnicode if needed and converted to lowercase.
      const parseResult = parseDomain(this.domain.toLowerCase())
      const { subDomains, domain, topLevelDomains } = parseResult
      let showError = false
      // Error message.
      let customDomainErrorMessage
      // TODO: Check if page is published, else showError for please publish page
      // Given input is validated against RFC 1034.
      if (parseResult.type === ParseResultType.Listed) {
        // When parseResult.type === LISTED.
        // Then perform APEX check.
        if (!subDomains.length) {
          // When the domain is APEX.
          // Then display APEX domain warning.
          this.openApexDialog()
        } else if (v.isFQDN(this.domain) === false) {
          // When the hostname is not a FQDN.
          // Then perform FQDN check (Final redundancy check before Moving on to API).
          // Then set the showError flag to true.
          showError = true
          // Then set the message.
          customDomainErrorMessage =
            'custom_domain.error.domain_validation.listed.not_fqdn'
        } else {
          // When all checks have passed.
          // Then display DNS instructions.
          this.showDomainInstruction = true
        }
      } else if (parseResult.type === ParseResultType.NotListed) {
        // When the parseResult.type is NOT_LISTED.
        // Then set the showError flag to true.
        showError = true
        // When hostname is valid, but not listed in the downloaded public suffix list.
        // Then set the message.
        customDomainErrorMessage =
          'custom_domain.error.domain_validation.not_listed'
      } else if (parseResult.type === ParseResultType.Invalid) {
        // When the parseResult.type is INVALID.
        // Then set the showError flag to true.
        showError = true
        // When input does NOT validate against RFC 1034.
        // Returns an object that with an errors(key): array, containing multiple validation error objects.
        // The structure of the errors array item object will be as follows:
        //{
        // column: number
        // message: string // example: 'Label \"https://user@www\" contains invalid character \":\" at column 6.'
        // type: string // example: 'LABEL_INVALID_CHARACTER'
        //}
        // Here we will notify the end user of the errors one by one until none remain.
        if (parseResult.errors.length > 0) {
          // Then the parseResult object contains a key "errors".
          // When that errors key has a length greater than 0.
          // Then notify the user with the information in errors[0].
          // When given input exceeds 255 characters.
          if (
            parseResult.errors[0].type === ValidationErrorType.DomainMaxLength
          ) {
            // Then set the message.
            customDomainErrorMessage =
              'custom_domain.error.domain_validation.invalid.domain_maxlength'
          }
          // When a domain label < 1 character.
          if (
            parseResult.errors[0].type === ValidationErrorType.LabelMinLength
          ) {
            // Then set the message.
            customDomainErrorMessage =
              'custom_domain.error.domain_validation.invalid.labelminlength'
          }
          // When a domain label exceeds 63 characters.
          if (
            parseResult.errors[0].type === ValidationErrorType.LabelMaxLength
          ) {
            // Then set the message.
            customDomainErrorMessage =
              'custom_domain.error.domain_validation.invalid.labelmaxlength'
          }
          // When ValidationErrorType is an invalid character.
          if (
            parseResult.errors[0].type ===
            ValidationErrorType.LabelInvalidCharacter
          ) {
            // The try catch avoids a TypeError thrown by new URL
            try {
              // Then create a new URL to easily extract the protocol.
              const parseUrl = new URL(this.domain)
              // When the protocol exists
              if (parseUrl.protocol) {
                // Then set the message.
                customDomainErrorMessage =
                  'custom_domain.error.domain_validation.invalid.invalid_character.protocol'
              }
            } catch {
              // When a Non protocol related invalid character exists.
              // Then set the message.
              customDomainErrorMessage =
                'custom_domain.error.domain_validation.invalid.invalid_character.character'
            }
          }
        }
      } else if (parseResult.type === ParseResultType.Ip) {
        // When the parseResult.type is IP.
        // Then set the showError flag to true.
        showError = true
        // When input is an IP address and therefore invalid.
        // Then set the message.
        customDomainErrorMessage =
          'custom_domain.error.domain_validation.invalid.ip'
      } else if (parseResult.type === ParseResultType.Reserved) {
        // When the parseResult.type is RESERVED.
        // Then set the showError flag to true.
        showError = true
        const reservedTLDS = [
          'localhost',
          'local',
          'example',
          'invalid',
          'test',
        ]
        if (
          reservedTLDS.indexOf(parseResult.hostname) !== -1 &&
          !parseResult.hostname
        ) {
          customDomainErrorMessage = `${parseResult.hostname} custom_domain.error.domain_validation.reserved.tld`
        }
        // NOTE: Empty custom domain input results in an empty hostname, which types as RESERVED.
        // When the custom domain input / hostname is empty.
        if (!parseResult.hostname) {
          customDomainErrorMessage =
            'custom_domain.error.domain_validation.reserved.empty_field'
        } else {
          customDomainErrorMessage =
            'custom_domain.error.domain_validation.reserved.unknown'
        }
      }
      if (showError) {
        Notification.error({
          message: this.$t(customDomainErrorMessage),
          position: 'bottom-right',
        })
        customDomainErrorMessage = ''
      }
    },
    async validateSiteIdOrDomain(onCreate) {
      // When no siteId or domain exist

      // Then check for siteId and domain before request

      // Not a user notification
      if (!this.site.id) {
        console.debug('Issue validating prior to request - Missing siteId')
        return
      }

      // When the page is initialied
      // Then do not show a message to the user
      // as this.domain will have no value
      if (!this.domain && !onCreate) {
        // Then display a message to the user
        Notification.error({
          message: this.$t(
            'custom_domain.error.domain_validation.reserved.empty_field',
          ),
          position: 'bottom-right',
        })
        return
      }
    },
    ...domainHelperMethods,
    displayLandingPageUrl() {
      // When the page is published then update the key and force re render to display
      // This approach was needed because normal reactivity is impaired
      this.showLandingPageUrl += 1
    },
    toggleMarketplaceFlag(payload) {
      this.adminTabSiteData.marketplace = payload.value
    },
    toggleDemoTemplateFlag(payload) {
      this.adminTabSiteData.demoTemplate = payload.value
    },
    toggleVerifiedSealFlag(payload) {
      this.adminTabSiteData.verifiedSeal = payload.value
    },
    siteVideoUrlChange(event) {
      let value = event.target.value?.trim()

      if (value) {
        this.adminTabSiteData.videoUrl = value
      }
    },
    siteImageUrlChange(event) {
      let value = event.target.value?.trim()

      if (value) {
        this.adminTabSiteData.imageUrl = value
      }
    },
    siteDescriptionChange(payload) {
      let value = payload.value?.trim()

      if (value) {
        this.adminTabSiteData.description = value
      }
    },
    async submitAdminTabSiteData(event) {
      this.adminTabSiteData.marketplace =
        this.adminTabSiteData.marketplace ?? this.siteMarketPlaceEnabled
      this.adminTabSiteData.verifiedSeal =
        this.adminTabSiteData.verifiedSeal ?? this.siteVerifiedSealEnabled

      this.updateSiteProperties({
        id: this.site?.id,
        sitePropsToUpdate: this.adminTabSiteData,
      })

      // GenAI related properties
      if (!this.isStandalonePopupActive && this.isGenAIFeatureEnabled) {
        this.submittingAdminData = true
        // this will update the data in current site in firebase
        this.isGenAiEnabled = this.firebaseChanges.enabled
          ? this.genAIData.enabled
          : this.isGenAiEnabled
        this.selectedIntents = this.firebaseChanges.selectedIntents
          ? this.genAIData.selectedIntents
          : this.selectedIntents
        this.selectedIntentGoals = this.firebaseChanges.selectedIntentGoals
          ? this.genAIData.selectedIntentGoals
          : this.selectedIntentGoals

        // updates the local state of the elements
        this.genAIData = {
          enabled: this.isGenAiEnabled,
          selectedIntents: this.selectedIntents,
          selectedIntentGoals: this.selectedIntentGoals,
        }

        // this block will save/delete the current site as GenAI site under 'gen-ai-templates' node in firebase
        if (this.isGenAiEnabled) {
          // this saves site as gen ai to server
          await this.saveAsGenAITemplate(this.site.id, {
            intent: this.selectedIntents,
            intentGoal: this.selectedIntentGoals,
          })
          this.saveGenAISite(this.genAIData)
          this.submittingAdminData = false
          Notification.success({
            message: 'Template saved successfully',
            position: 'bottom-right',
          })
        } else {
          this.deleteGenAISite()
          this.submittingAdminData = false
        }
      }
    },
    toggleGenAiEnabled(status) {
      this.genAIData.enabled = status === 'Active'
      this.firebaseChanges.enabled = true // this field has been changed
    },
    setGenAISelectedIntents(valueArray) {
      this.genAIData.selectedIntents = valueArray
      this.firebaseChanges.selectedIntents = true // this field has been changed
    },
    setGenAISelectedIntentGoals(valueArray) {
      this.genAIData.selectedIntentGoals = valueArray
      this.firebaseChanges.selectedIntentGoals = true // this field has been changed
    },
    async saveAsGenAITemplate(siteId, templateData) {
      try {
        await genAIApi.saveGenAiTemplates(siteId, templateData)
      } catch (err) {
        this.$message.error('failed to save template')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';
.publish-subtitle {
  width: 481px;
  margin-left: auto;
  margin-right: auto;
}
.input-group {
  flex-direction: row;
  margin-top: 12px;
  &__url {
    flex: 1;
  }
  &__copy {
    margin-bottom: auto;
    margin-left: 16px;
  }
}
.step-finish {
  padding-top: 50px;
  padding-bottom: 50px;
}
.finish-check {
  background: $inf-green;
  color: $white;
  border-radius: 45px;
  padding: 0.5rem;
}
.horizontal-divider {
  border-right: 1px solid $off-gray;
}
.site-url {
  border-radius: 5px;
  border: 1px solid $inf-light-blue;
}
.landing__page--link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.dark-textarea {
  height: 14em !important;
  background-color: #ebeff3;
  border: 1px solid #cfd7df;
  border-radius: 4px;
  padding: 12px;
}
.input-group__copy {
  align-self: flex-end;
  margin-bottom: 2px;
}
.admin-select > div > div {
  border-radius: 4px;
}
.admin-button {
  border-radius: 6px !important;
}
.admin-switch div > span {
  width: 30px;
}
.admin-label {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 300;
}
</style>
