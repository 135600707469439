
<cvt-collapse
  :accordion="true"
  :theme="mode === 'LIGHT' ? 'light' : 'dark'"
  :prepend-chevron="prependChevron"
>
  <cvt-collapse-item
    v-if="genAiEnabled"
    title="Generative AI"
    :special-style="isSpecialStyle"
  >
    <GenAIOptions :mode="mode" />
  </cvt-collapse-item>
  <cvt-collapse-item
    :title="$t('elements.properties.label')"
    name="properties"
    :initial-active="true"
    :special-style="isSpecialStyle"
  >
    <div class="row no-gutters">
      <div class="col-md-12">
        <cvt-input
          v-model="cta"
          type="text"
          :label="$t('sections.filter.cta')"
          :clearable="false"
        />
      </div>

      <div v-if="isNotFormButton" class="col-md-12 mt-3">
        <cvt-input
          v-model="subtext"
          type="text"
          :label="$t('editor.element.button_subtext')"
          :clearable="false"
        />
      </div>
      <div class="col-md-12 mt-3">
        <cvt-input
          v-model="helper_text"
          type="text"
          :label="$t('editor.element.button_helper_text')"
          :clearable="false"
        />
      </div>
    </div>

    <div
      v-if="isNotFormButton"
      :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
    />

    <div
      v-if="isNotFormButton"
      class="row no-gutters"
      data-identity="normalBtnActionsDropdownWrap"
    >
      <div class="col-md-12">
        <cvt-select
          mode="dropdown"
          :value="action"
          :label="$t(`element.property.button.alt.action`)"
          :theme="mode"
          :background-mode="mode"
          @input="(value) => selectAction(value, 'action')"
        >
          <option
            v-for="optionAction in actions"
            :key="optionAction.value"
            :value="optionAction.value"
            v-text="optionAction.label"
          ></option>
        </cvt-select>
      </div>
    </div>

    <div
      v-if="isNotFormButton && linkActive"
      :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
    />

    <div :class="{ 'subdiv-border': SubDivMargin }">
      <div :class="{ 'subdiv-spacing-left': SubDivMargin }">
        <div
          v-if="linkActive && isNotFormButton"
          class="row no-gutters"
          data-identity="normalBtnAnchorTargetsDropdownWrap"
        >
          <div class="col-md-12">
            <cvt-select
              mode="dropdown"
              :value="anchorTarget"
              :label="$t('element.property.button.alt.target')"
              :background-mode="mode"
              @input="(value) => selectAction(value, 'anchorTarget')"
            >
              <option
                v-for="target in anchorTargets"
                :key="target.value"
                :value="target.value"
                v-text="target.label"
              ></option>
            </cvt-select>
          </div>
        </div>

        <div
          v-if="isNotFormButton && linkActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div v-if="linkActive && isNotFormButton" class="row no-gutters">
          <div class="col-md-12">
            <url-input
              v-model="href"
              placeholder="https://example.com"
              :label="$t('element.property.button.alt.url')"
            />
          </div>
        </div>

        <div
          v-if="isNotFormButton && pageActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div
          v-if="pageActive && isNotFormButton"
          class="row no-gutters"
          data-identity="normalBtnPagesDropdownWrap"
        >
          <div class="col-md-12">
            <page-list
              :selected-page="selectedPage"
              @change="updateLinkedPage"
            />
          </div>
        </div>

        <div
          v-if="isNotFormButton && anchorActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div v-if="anchorActive && isNotFormButton" class="row no-gutters">
          <div class="col">
            <h6 :class="textColorClass">Anchor To</h6>
          </div>
          <div class="col-md-12">
            <section-list v-model="anchor" :value="anchor" />
          </div>
        </div>

        <div
          v-if="isNotFormButton && popupActive"
          :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
        />

        <div
          v-if="popupActive && isNotFormButton"
          class="row no-gutters"
          data-identity="normalBtnPopupsDropdownWrap"
        >
          <div class="col-md-12">
            <popup-list-alt
              :key="selected.vnodeId"
              :value="popup"
              @configure-popup="configurePopup"
              @delete-popup="removePopup"
              @change="updatePopup"
            />
          </div>

          <div class="col-md-12 justify-content-center">
            <cvt-button
              class="m-1"
              :outlined="true"
              :text="$t('e.popup.create.a.popup')"
              :full-width="true"
              @click="createNewPopup"
            />
          </div>
        </div>
      </div>
    </div>
  </cvt-collapse-item>
  <cvt-collapse-item
    v-if="isNotFormButton && config.ECOMMERCE"
    :title="$t('elements.property.product.message')"
    name="products"
    :special-style="isSpecialStyle"
    data-identity="normalBtnProductsListParentWrap"
  >
    <products-list />
  </cvt-collapse-item>

  <cvt-collapse-item
    title="Style"
    name="style"
    :special-style="isSpecialStyle"
  >
    <div class="row">
      <div class="col-auto">
        <h6 :class="textColorClass">
          {{ $t('elements.properties.color.label') }}
        </h6>
      </div>
      <div>
        <colors :mode="mode" />
      </div>
    </div>
    <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />

    <button-outline-options
      v-if="isGridBuilder"
      :mode="mode"
      data-identity="buttonOutlineOptionsParentWrap"
    ></button-outline-options>

    <div
      v-if="isGridBuilder"
      :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
    />

    <div v-if="!changeToggleToCheckBox && !isGridBuilder">
      <div class="row d-flex justify-content-between">
        <div class="col-auto">
          <h6 :class="textColorClass">
            {{ $t('element.property.button.full_width') }}
          </h6>
        </div>
        <div class="col-auto">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="isBlock"
            :mode="mode"
            @switchToggle="onSwitchToggle"
          ></cvt-switch>
        </div>
      </div>
      <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
    </div>

    <div class="row">
      <div class="col-auto">
        <h6 :class="textColorClass">
          {{ $t('element.property.button.fill.label') }}
        </h6>
      </div>
      <div class="col-12">
        <cvt-radio-group orientation="horizontal" :initial-value="fill">
          <cvt-radio-button
            size="md"
            value="btn-primary"
            :shape="RadioButtonShape"
            @radioClick="updateBtnFill"
          >
            {{ $t('element.property.button.fill.full') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn-outline-primary"
            :shape="RadioButtonShape"
            @radioClick="updateBtnFill"
          >
            {{ $t('element.property.button.fill.hollow') }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>

    <div
      v-if="!isGridBuilder"
      :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
    />

    <div v-if="!isGridBuilder" class="row">
      <div class="col-auto">
        <h6 :class="textColorClass">
          {{ $t('element.property.button.size.label') }}
        </h6>
      </div>
      <div class="col-12">
        <cvt-radio-group orientation="horizontal" :initial-value="size">
          <cvt-radio-button
            size="md"
            value="btn-sm"
            :shape="RadioButtonShape"
            @radioClick="updateBtnSize"
          >
            SM
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn"
            :shape="RadioButtonShape"
            @radioClick="updateBtnSize"
          >
            MD
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn-lg"
            :shape="RadioButtonShape"
            @radioClick="updateBtnSize"
          >
            LG
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn-xl"
            :shape="RadioButtonShape"
            @radioClick="updateBtnSize"
          >
            XL
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
      <div v-if="changeToggleToCheckBox" class="col-12">
        <div class="row">
          <div class="col-1">
            <input
              id="fullWidthBox"
              type="checkbox"
              :checked="isBlock"
              @change="onSwitchToggle"
            />
            &nbsp;&nbsp;
          </div>
          <small class="col-10" for="fullWidthBox">{{
            $t('element.property.button.full_width')
          }}</small>
        </div>
      </div>
    </div>

    <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />

    <div class="row">
      <div class="col-auto">
        <h6 :class="textColorClass">
          {{ $t('element.property.button.shape.label') }}
        </h6>
      </div>
      <div class="col-12">
        <cvt-radio-group orientation="horizontal" :initial-value="shape">
          <cvt-radio-button
            size="md"
            value="btn-squared"
            :shape="RadioButtonShape"
            @radioClick="updateBtnShape"
          >
            {{ $t('element.property.button.shape.square') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn-rounded"
            :shape="RadioButtonShape"
            @radioClick="updateBtnShape"
          >
            {{ $t('element.property.button.shape.round') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn-pill"
            :shape="RadioButtonShape"
            @radioClick="updateBtnShape"
          >
            {{ $t('element.property.button.shape.pill') }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>

    <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />

    <div class="row">
      <div class="col-auto">
        <h6 :class="textColorClass">
          {{ $t('elements.properties.shadow.normal') }}
        </h6>
      </div>
      <div class="col-12">
        <cvt-radio-group orientation="horizontal" :initial-value="shadow">
          <cvt-radio-button
            size="md"
            value="btn-no-shadow"
            :shape="RadioButtonShape"
            @radioClick="updateBtnShadow"
          >
            {{ $t('elements.properties.option.none') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn-shadow"
            :shape="RadioButtonShape"
            @radioClick="updateBtnShadow"
          >
            {{ $t('element.property.button.shadow.light') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="btn-shadow-deep"
            :shape="RadioButtonShape"
            @radioClick="updateBtnShadow"
          >
            {{ $t('element.property.button.shadow.dark') }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>
    <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
    <div class="row mt-2">
      <div class="col">
        <h6 :class="textColorClass">
          {{ $t('element.property.button.icon.toggle') }}
        </h6>
      </div>
      <div class="col-3">
        <cvt-switch
          key="button-icon-toggle"
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="iconEnabled"
          :mode="mode"
          @switchToggle="onButtonIconToggle"
        />
      </div>
    </div>

    <div
      v-if="iconEnabled"
      :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
    />
    <div v-if="iconEnabled" class="row">
      <div class="col-auto">
        <h6 :class="textColorClass">
          {{ $t('element.property.button.icon.position') }}
        </h6>
      </div>
      <div class="col-12">
        <cvt-radio-group
          orientation="horizontal"
          :initial-value="iconPosition"
        >
          <cvt-radio-button
            size="md"
            value="left"
            :shape="RadioButtonShape"
            @radioClick="updateIconPosition"
          >
            {{ $t('element.property.button.icon.position_left') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="right"
            :shape="RadioButtonShape"
            @radioClick="updateIconPosition"
          >
            {{ $t('element.property.button.icon.position_right') }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>
    <div
      v-if="iconEnabled"
      :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
    />
    <div v-if="iconEnabled" class="element-image-manager">
      <div
        class="element-image-manager__preview checkered"
        @mouseover.stop.prevent="showOverlay"
        @mouseleave.stop.prevent="hideOverlay"
        @click.stop.prevent="showIconPicker"
      >
        <div
          v-show="overlayVisible"
          class="element-image-manager__preview__overlay"
        >
          <small
            ><i>{{ assetMgrBtnText }} Icon</i></small
          >
        </div>
        <icon-template
          :set="selected.set"
          :selector="selected.selector"
          class="icon-preview"
        />
      </div>

      <div v-if="isSpecialStyle" class="divider mt-3" />
      <div v-else class="mt-3" />

      <div class="row">
        <div class="col">
          <cvt-button
            :full-width="fullWidth"
            shape="rounded"
            :size="size"
            :color="btnColor"
            :text="replaceBtnText"
            :mode="mode"
            @click.stop.prevent="showIconPicker"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isNotFormButton && !isGridBuilder"
      :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
    />
    <flex-align v-if="isNotFormButton && !isGridBuilder" :mode="mode" />
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.spacing.label')"
    name="spacing"
    :special-style="isSpecialStyle"
  >
    <spacing-ctrl />
  </cvt-collapse-item>

  <cvt-collapse-item
    v-if="isCardButton"
    :title="$t('elements.properties.visibility.label')"
    name="visibility"
    :special-style="isSpecialStyle"
  >
    <visibility-ctrl :mode="mode" />
  </cvt-collapse-item>
</cvt-collapse>
