import firebase from 'firebase/app'
import { ThemeManagerV1 } from '../v1/theme'
import {
  updateFrolaLinkTypographyCSSX,
  updateThemeColorsCSSX,
  addFontClassesToSheetCSSX,
  addFontSizesToSheetCSSX,
  updateTypographyCSSX,
} from '../utils'
import {
  DEFAULT_TYPOGRAPHY,
  DEFAULT_BUTTON_TYPOGRAPHY,
  DEFAULT_FROALA_LINK_TYPOGRAPHY,
} from './fonts'
import {
  ButtonFormat,
  FONT_SIZES,
  FroalaFormat,
  FroalaLinkFormat,
  GOOGLE_FONTS,
} from '../../../../lib/font'
import { DEFAULT_COLOR } from '../../../../lib/color-utils'
import _ from 'lodash'
import color, { Color } from 'color'
import { generateGlobalStyleColors } from './color-generator'
import { ThemeData, ThemeState } from 'types'
import { IThemeManager } from '../types'

type ThemeStateV2 = ThemeState & {
  globalStyles: {
    colors: { [key: string]: { hex: string; displayName: string } }
  }
}

export class ThemeManagerV2 extends ThemeManagerV1 implements IThemeManager {
  globalStylesRef: firebase.database.Reference

  constructor(fbSitePath: string) {
    super(fbSitePath)
    this.globalStylesRef = this.db.ref(fbSitePath).child('globalStyles')

    this.defaultState = {
      colors: {},
      blockColors: {},
      typography: DEFAULT_TYPOGRAPHY,
      buttonTypography: DEFAULT_BUTTON_TYPOGRAPHY,
      froalaLinkTypography: DEFAULT_FROALA_LINK_TYPOGRAPHY,
      availableFonts: [...GOOGLE_FONTS],
      defaultColor: DEFAULT_COLOR,
      globalStyles: {
        colors: {},
      },
    }
    console.debug('THEME_V2: Initializing Theme Manager V2')
  }

  get version() {
    return 2
  }

  public canAddNewColors() {
    return false
  }

  public canEditColors() {
    return true
  }

  public toColor(colorId: string, colors: { [key: string]: Color }) {
    if (colors[colorId]) {
      return colors[colorId].hex()
    }
    return colorId
  }

  public async loadDefaults() {
    await new Promise((resolve) => {
      this.globalStylesRef.once('value', (snapshot) => {
        let globalStyles = {
          colors: generateGlobalStyleColors(),
        }

        if (!snapshot.exists() || !snapshot.val().colors) {
          // Create new globalStyles if it doesn't exist
          globalStyles = {
            colors: generateGlobalStyleColors(),
          }
          this.globalStylesRef.set(globalStyles)
          console.log('THEME_V2: Creating new globalStyles')
        } else if (snapshot.exists() && snapshot.val().colors) {
          // Use existing globalStyles from Firebase
          globalStyles = snapshot.val()
          console.debug('THEME_V2: Using existing globalStyles')
        }

        // Update local state in both cases
        this.defaultState.globalStyles = globalStyles
        resolve(void 0)
      })
    })
  }

  public updateThemeData(state: ThemeStateV2, theme: ThemeData) {
    console.debug('THEME_V2: Updating theme data')
    let _state = this.cloneState(state)
    _state.colors = state.globalStyles?.colors
      ? _.mapValues(state.globalStyles?.colors, (c) => {
          return color(c.hex)
        })
      : {}

    _state.blockColors = theme.blockColors
      ? _.mapValues(theme.blockColors, (c: any) => {
          const globalColor = state.globalStyles.colors[c.colorId]
          if (globalColor) {
            return color(globalColor.hex)
          }
          return color(c.colorId) // Fallback for any existing color data
        })
      : {}

    _state.typography =
      theme.typography &&
      theme.typography.title &&
      theme.typography.title.defaultTextColor &&
      theme.typography.title.defaultAltTextColor
        ? _.mapValues(theme.typography, (v) => {
            if (v instanceof FroalaFormat) {
              return v
            }
            return new FroalaFormat(v)
          })
        : DEFAULT_TYPOGRAPHY

    _state.buttonTypography = theme.buttonTypography
      ? _.mapValues(theme.buttonTypography, (v) => {
          if (v instanceof ButtonFormat) {
            return v
          }
          return new ButtonFormat(v)
        })
      : DEFAULT_BUTTON_TYPOGRAPHY

    _state.froalaLinkTypography =
      theme.froalaLinkTypography &&
      theme.froalaLinkTypography.link &&
      theme.froalaLinkTypography.link.defaultTextColor
        ? _.mapValues(
            theme.froalaLinkTypography,
            (v) => new FroalaLinkFormat(v),
          )
        : DEFAULT_FROALA_LINK_TYPOGRAPHY

    _state.brandLogo = theme.brandLogo

    updateTypographyCSSX(_state.typography)
    updateFrolaLinkTypographyCSSX(_state.froalaLinkTypography)
    updateThemeColorsCSSX(_state.colors)
    updateThemeColorsCSSX(_state.blockColors)
    addFontClassesToSheetCSSX(GOOGLE_FONTS)
    addFontSizesToSheetCSSX(FONT_SIZES)

    return _state
  }
  /**
   * Not supported in v2
   * @param {*} colorId
   * @param {*} state
   * @returns
   */
  public async removeColor(state: ThemeStateV2, colorId: string) {
    console.debug('THEME_V2: Remove color operation not supported')
  }

  // @ts-expect-error
  public async updateColor(
    state: ThemeStateV2,
    { color, key }: { color: Color; key: string },
  ) {
    console.debug('THEME_V2: Updating color', { key, color: color.hex() })
    const colorRef = this.globalStylesRef.child('colors').child(key)
    await colorRef.update({
      hex: color.hex().toLowerCase(),
    })

    const newColors = { ...state.colors, ...{ [key]: color } }
    state.globalStyles.colors[key].hex = color.hex().toLowerCase()

    updateThemeColorsCSSX(newColors as { [key: string]: Color })
    return [
      colorRef.key,
      { colors: newColors, globalStyles: state.globalStyles },
    ]
  }

  // @ts-expect-error
  public async addNewColor(state: ThemeStateV2, color: Color) {
    console.debug('THEME_V2: Add new color operation not supported')
    return [null, null]
  }
}
