<template>
  <div class="mx-4 mt-4">
    <template v-if="brandManagerViewMode === BRAND_MODE_LIST">
      <branding-list />
    </template>

    <template v-if="brandManagerViewMode === BRAND_MODE_CREATE">
      <create-brand />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import BrandingList from './BrandingList.vue'
import CreateBrand from './CreateBrand.vue'
import {
  BRAND_MODE_LIST,
  BRAND_MODE_CREATE,
} from '@/js/common/constants/constants'

export default {
  components: {
    BrandingList,
    CreateBrand,
  },
  props: {
    onBrandPreferenceView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'LIGHT',
      BRAND_MODE_LIST,
      BRAND_MODE_CREATE,
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      user: getters.AUTH_GET_USER,
      fontStyles: getters.THEME_FONT_STYLES,
      brandManagerViewMode: getters.MP_GET_MANAGER_MODE,
    }),
  },
  mounted() {
    if (!this.onBrandPreferenceView) {
      this.initBrandManager()
    }
  },
  methods: {
    ...mapActions({
      initBrandManager: actions.MP_INIT_BRAND_MANAGER,
    }),
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.marketplace-branding-search {
  flex-basis: 75%;

  div {
    border-radius: 0;
  }
}
.brand-block {
  background: #ffffff;
  border-bottom: 1px solid #f0f2f6;

  .brand-block-name {
    font-weight: 300;
    font-family: 'Nunito';
  }
}

.branding-upload-dropzone-style {
  .upload-dropzone {
    height: 340px;
  }
}

.branding-right-section {
  flex-grow: 1;
}

.branding_url_input {
  label {
    font-weight: 700;
    color: #494c53;
  }

  small {
    font-size: 12px;
    color: #8a8e97;
  }
}
</style>
