<template>
  <div>
    <cvt-dialog
      :show="deleteDialogVisible"
      :height-auto="true"
      :border-radius="'8px'"
      size="sm"
      bg-color="#fff"
      :show-close="false"
    >
      <template #title>
        <h5
          class="del-color-warn-title"
          style="font-family: 'Nunito'; font-weight: 700"
        >
          {{ $t('marketplace.branding.list.delete.title') }}
        </h5>
      </template>

      <template #default>
        <h5
          class="del-color-warn-subtitle"
          style="font-family: 'Nunito'; font-size: 14px"
        >
          {{ $t('marketplace.branding.list.delete.subtitle') }}
        </h5>
        <span style="font-family: 'Nunito'">{{ brandingName }}</span>
      </template>

      <template #modalFooter>
        <div class="d-flex">
          <cvt-button
            class="mr-2"
            color="light"
            :text="$t('marketplace.dialog.archive.cta.cancel')"
            @click.stop="$emit('cancel')"
          />
          <cvt-button
            color="dark"
            :text="$t('marketplace.dialog.archive.cta.submit')"
            class="mr-2"
            @click.stop="$emit('delete')"
          />
        </div>
      </template>
    </cvt-dialog>
  </div>
</template>

<script>
export default {
  name: 'DeleteBrandDialog',
  props: {
    deleteDialogVisible: {
      type: Boolean,
      default: false,
    },
    brandingName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mode: 'LIGHT',
    }
  },
  computed: {},
  methods: {},
}
</script>

<style scoped></style>
